import React from "react";

import PropTypes from "prop-types";

export const DebtStartFinishDates = ({ data }) => {
  const { start_date, finish_date } = data;
  return (
    <div className="debt-custom-card debt-start-finish-card">
      <div className="debt-custom-card__block">
        <div className="debt-custom-card__column">
          <h5>Start Date</h5>
          <p>{start_date}</p>
        </div>
        <div className="debt-custom-card__column">
          <h5>Finish Date</h5>
          <p>{finish_date}</p>
        </div>
      </div>
    </div>
  );
};

DebtStartFinishDates.propTypes = {
  data: PropTypes.object,
};

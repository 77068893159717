import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { ProfileBody } from "../../components/ProfileBody";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { fetchAdminProfile } from "../../redux/actions/profile";

import "./style.scss";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.profile.loading);
  const profileData = useSelector((state) => state.profile.profileData);

  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );

  useEffect(() => {
    dispatch(fetchAdminProfile());
  }, [dispatch]);

  return (
    <div className="profile-page-container my-profile">
      <Helmet>
        <title>Profile - {multitenancyCompanyShortName}</title>
      </Helmet>
      <div className="title">Profile</div>
      {loading ? (
        <LoadingSpinner middleFixed={true} />
      ) : (
        <div className="tab-container">
          <div className="tab-container__column">
            <div className="avatar-wrapper">
              <h2 className="heading heading-2">
                {profileData && profileData.full_name}
              </h2>
              <h6 className="heading heading-caption-bold">
                From Feb 28, 2020
              </h6>
            </div>
          </div>
          <ProfileBody profileData={profileData} profileType="admin" />
        </div>
      )}
    </div>
  );
};

// PropTypes validation
ProfilePage.propTypes = {
  loading: PropTypes.bool,
  profileData: PropTypes.object,
};

// Export the functional component
export default ProfilePage;

import React, { useState, useEffect, Fragment } from "react";

import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";

import {
  checkPermanentUploadStatus,
  checkTemporaryUploadStatus,
  getUploadedFileSummary,
  saveUploadedFile,
} from "../../lib/api/admin";
import sendFileRequest from "../../lib/api/sendFileRequest";
import { InventoryForm } from "./InventoryForm";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { convertedCsvData } from "./helpers";
import "./style.scss";
import { CustomButton } from "../../components/ui-kit/CustomButtom";
import { useSelector } from "react-redux";
export const InventoryPage = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [uploadType, setUploadType] = useState("add");
  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const fetchUploadedFileSummary = async () => {
    setLoading(true);
    const summary = await getUploadedFileSummary();

    if (summary.success !== "True") {
      alert.error(`Something went wrong. ${summary.message}`);
      setLoading(false);
      return;
    }

    fetchTemporaryUploadStatus();
    fetchPermanentUploadStatus();

    alert.success(summary.message);
    setSummaryData(summary.rows);
    setSelectedFile(null);
    setLoading(false);
  };

  const fetchTemporaryUploadStatus = async () => {
    const uploadStatus = await checkTemporaryUploadStatus();
    if (uploadStatus.message === "Uploading in progress...") {
      await new Promise((r) => setTimeout(r, 2000));
      fetchUploadedFileSummary();
    }
  };

  const fetchPermanentUploadStatus = async () => {
    const uploadStatus = await checkPermanentUploadStatus();
    if (uploadStatus.message === "Uploading in progress...") {
      await new Promise((r) => setTimeout(r, 2000));
      fetchUploadedFileSummary();
    }
  };

  useEffect(() => {
    fetchUploadedFileSummary();
    fetchTemporaryUploadStatus();
    fetchPermanentUploadStatus();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSave = async () => {
    setLoading(true);
    const savedUploadFileData = await saveUploadedFile({
      upload_mode: uploadType,
    });

    if (savedUploadFileData.success !== "True") {
      alert.error(`Something went wrong. ${savedUploadFileData.message}`);
      setLoading(false);
      return;
    }

    setSummaryData(null);

    await fetchUploadedFileSummary();
  };

  const handleCheck = async () => {
    setLoading(true);

    const data = new FormData();
    data.append("file", selectedFile);
    data.append("upload_mode", uploadType);
    const fileUploadData = await sendFileRequest(
      "/uploads/file-upload",
      "POST",
      data
    );

    if (fileUploadData.success !== "True") {
      alert.error(`Something went wrong. ${fileUploadData.message}`);
      return;
    }

    fetchUploadedFileSummary();
  };

  const handleFileChange = (e) => {
    if (e.target.files[0].type !== "text/csv" && e.target.files[0].type !== "application/vnd.ms-excel") {
      alert.error("File should be in CSV format.");
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleSetUploadType = (e) => {
    setUploadType(e.target.value);
  };

  return (
    <div className="default-container inventory-page">
      <Helmet>
        <title>File Upload – {multitenancyCompanyShortName}</title>
      </Helmet>
      <h2 className="heading heading-2">File Upload</h2>
      {loading ? (
        <LoadingSpinner middleFixed={true}/>
      ) : (
        <Fragment>
          <InventoryForm
            handleSubmit={handleSubmit}
            handleFileChange={handleFileChange}
            uploadType={uploadType}
            handleSetUploadType={handleSetUploadType}
          />
          <div className="buttons-wrapper">
            <CustomButton
              text="Check"
              onClickHandler={handleCheck}
              disabled={!selectedFile}
            />
            {summaryData ? (
              <CSVLink
                data={convertedCsvData(summaryData)}
                filename="downloaded_rows.csv"
                className="button button-primary button-primary--medium download"
              >
                Download csv
              </CSVLink>
            ) : null}
            <CustomButton
              text="Save"
              onClickHandler={handleSave}
              disabled={!summaryData}
              optionalClass="save"
            />
          </div>
          {summaryData ? (
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    {Object.keys(summaryData[0]).map((key, i) => {
                      if (key === "original_row") return null;
                      return <th key={i}>{key}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {summaryData.map((summaryRowItem, i) => {
                    return (
                      <tr key={i}>
                        {Object.keys(summaryRowItem).map((key, i) => {
                          switch (key) {
                            case "errors":
                              return (
                                <td key={i} className="errors">
                                  {summaryRowItem[key]}
                                </td>
                              );
                            case "original_row":
                              return null;
                            default:
                              return <td key={i}>{summaryRowItem[key]}</td>;
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </Fragment>
      )}
    </div>
  );
};

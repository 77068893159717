import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

export const PageTitle = ({ title }) => {
  return <h2 className="heading heading-1">{title}</h2>;
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

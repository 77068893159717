import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export const Notification = ({ notification }) => {
  return (
    <div className="notification-info-container">
      <div className="text-label">
        <div>{notification}</div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.string.isRequired,
};

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useAlert } from "react-alert";

import { LoadingSpinner } from "components/LoadingSpinner";
import { getDisputes } from "lib/api/common";
import { TableContainer } from "components/TableContainer";

import "./style.scss";

export const DisputesAdminPage = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [allDisputes, setAllDisputes] = useState([]);

  const tableHeaders = ["Data Id", "Dispute Type", "Created At", "Name", "Explanation", "Expected Outcome", "Reasons"];

  useEffect(() => {
    loadDisputes();
  }, []);

  const loadDisputes = async() => {
    setLoading(true);
    const data = await getDisputes();

    if (data.success === "True") {
      setAllDisputes(data.data);
      setLoading(false);
    } else {
      alert.error(`Error: ${data.message}`);
      setLoading(false);
    }
  }

  return (
    <div className="disputes-page-container">
      <Helmet>
        <title>Disputes</title>
      </Helmet>
      <div className="main-container">
      <div className="title">Disputes</div>
        {loading ? (
          <LoadingSpinner middleFixed={true}/>
        ) : (
          <TableContainer 
            tableHeaders={tableHeaders}
            data={allDisputes}
            type="disputes"
          />
        )}
      </div>
    </div>
  )
}
import { HIDE_LOADER, SHOW_LOADER, UPDATE_PASSWORD_FORM } from "../types";

const initialState = {
  loading: true,
  changePasswordForm: {
    password: "",
    new_password: "",
    confirm_password: "",
    save_btn: true
  },
};

export const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case UPDATE_PASSWORD_FORM:
      return { ...state, changePasswordForm: action.payload };
    default:
      return state;
  }
};

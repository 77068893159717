import React from "react";

import moment from "moment";
import PropTypes from "prop-types";

export const ACHPreview = ({
  finantial_institution,
  name_on_check,
  routing_number,
  account_number,
}) => {
  const sum = localStorage.getItem("paymentSum");
  return (
    <div className="ach-preview">
      <div className="ach-preview__content">
        <div className="content__header">
          <h4 className="header__financial-institution">
            {finantial_institution}
          </h4>
          <p className="header__date">
            DATE:{" "}
            <span className="underlined">{moment(Date()).format("L")}</span>
          </p>
        </div>
        <div className="content__main">
          <p className="main__name-on-check">{name_on_check}</p>
          <p className="main__sum">
            <span className="bold">$</span>
            <span className="border">{sum}</span>
          </p>
        </div>
        <div className="content__footer">
          <p className="footer__routing-number">{routing_number}</p>
          <p className="footer_account-number">{account_number}</p>
        </div>
      </div>
    </div>
  );
};

ACHPreview.propTypes = {
  finantial_institution: PropTypes.string,
  name_on_check: PropTypes.string,
  routing_number: PropTypes.string,
  account_number: PropTypes.string,
};

export const employerFormPrimaryItems = [
  { title: "Employer Name", name: "employer_full_name", disabled: false },
  { title: "Type", name: "employ_type", disabled: false },
  { title: "Work Phone", name: "work_phone", disabled: false },
  { title: "Occupation", name: "occupation", disabled: false },
];

export const employerFormAddressItems = [
  { title: "State", name: "employer_state", disabled: false },
  { title: "City", name: "employer_city", disabled: false },
  { title: "Street", name: "employer_street", disabled: false },
  { title: "Zip", name: "employer_zip", disabled: false },
];

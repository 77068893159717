import React from "react";

import PropTypes from "prop-types";

export const CheckVerificationCode = ({
  verificationCode,
  handleVerificationCodeChange,
}) => {
  return (
    <div className="form-group">
      <label
        htmlFor="verification-code"
        className="heading heading-caption-bold"
      >
        Code
      </label>
      <input
        className="input input-large"
        type="text"
        name="verification-code"
        value={verificationCode}
        onChange={handleVerificationCodeChange}
      />
    </div>
  );
};

CheckVerificationCode.propTypes = {
  verificationCode: PropTypes.string,
  handleVerificationCodeChange: PropTypes.func,
};

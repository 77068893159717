import React from "react";

import PropTypes from "prop-types";

export const SendVerificationCode = ({ email, handleEmailChange }) => {
  return (
    <div className="form-group">
      <label htmlFor="email" className="heading heading-caption-bold">
        Email
      </label>
      <input
        className="input input-large"
        type="text"
        name="email"
        onChange={handleEmailChange}
        value={email}
      />
    </div>
  );
};

SendVerificationCode.propTypes = {
  email: PropTypes.string,
  handleEmailChange: PropTypes.func,
};

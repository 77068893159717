import _ from "lodash";

export const hasErrors = (data) => {
  if (!data || !_.isArray(data)) return true;
  let errors = "";
  data.map((item) => {
    if (item.errors) {
      errors = item.errors;
    }
    return null;
  });

  return _.isEmpty(errors);
};

export const convertedCsvData = (summaryData) => {
  const headersData = _.omit(summaryData[0], ["id", "errors", "original_row"]);
  const headers = Object.keys(headersData);

  const data = [];
  summaryData.map((summaryItem) => {
    const temp = [];
    Object.keys(summaryItem).map((item) => {
      // Push only those fields that we can use to upload in file later
      // "id", "errors", "original_row" are added on back-end
      if (item !== "id" && item !== "errors" && item !== "original_row") {
        temp.push(summaryItem[item]);
      }
      return null;
    });
    data.push(temp);

    return null;
  });
  const csvData = [headers, ...data].map((item) => item.join(",")).join("\n");
  return csvData;
};

export const showPageRange = (currentPage, amount, showPerPage) => {
  if (!currentPage || !amount || !showPerPage) {
    return `0-0 of 0`;
  }

  if (currentPage === 1 && +amount < +showPerPage) {
    return `1-${amount} of ${amount}`;
  } else {
    if (currentPage * +showPerPage > +amount) {
      return `${(currentPage - 1) * +showPerPage + 1}-${amount} of ${amount}`;
    }

    return `${(currentPage - 1) * +showPerPage + 1}-${
      +currentPage * +showPerPage
    } of ${amount}`;
  }
};

import {
  FETCH_ACTIVE_DEBTS,
  FETCH_BALANCE,
  FETCH_ESTABLISHED_DEBTS,
  FETCH_PAIDOFF_DEBTS,
  FETCH_ONHOLD_DEBTS,
  FETCH_PAYMENT_DEBTS,
  FETCH_RECALLED_DEBTS,
  FETCH_DELINQUENT_DEBTS,
  HIDE_LOADER,
  SHOW_LOADER,
} from "../types";

const initialState = {
  loading: true,
  balanceData: [],
  activeDebts: [],
  paymentDebts: [],
  establishedDebts: [],
  paidoffDebts: [],
  onholdDebts: [],
  recalledDebts: [],
  delinquentDebts: [],
};

export const consumerDebtsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case FETCH_ACTIVE_DEBTS:
      return { ...state, activeDebts: action.payload };
    case FETCH_PAYMENT_DEBTS:
      return { ...state, paymentDebts: action.payload };
    case FETCH_ESTABLISHED_DEBTS:
      return { ...state, establishedDebts: action.payload };
    case FETCH_DELINQUENT_DEBTS:
      return { ...state, delinquentDebts: action.payload };
    case FETCH_PAIDOFF_DEBTS:
      return { ...state, paidoffDebts: action.payload };
    case FETCH_ONHOLD_DEBTS:
      return { ...state, onholdDebts: action.payload };
    case FETCH_RECALLED_DEBTS:
      return { ...state, recalledDebts: action.payload };
    case FETCH_BALANCE:
      return { ...state, balanceData: action.payload };
    default:
      return state;
  }
};

import { FETCH_PLAN_INFO, HIDE_LOADER, SHOW_LOADER } from "../types";

const initialState = {
  loading: false,
  planInfo: null,
};

export const planInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case FETCH_PLAN_INFO:
      return { ...state, planInfo: action.payload };
    default:
      return state;
  }
}
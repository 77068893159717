import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import alertIcon from "scss/images/alert.png";
import { FailedPaymentDataLayer } from "lib/helpers/dataLayers";
import { useTranslation } from "react-i18next";
import { asaStatuses } from "lib/helpers/constants";

import "./style.scss";

export const FailedPaymentInfo = ({
  errorMessage,
  alert_notification,
  asa_status,
  javitchError
}) => {
  const { t } = useTranslation();

  const phone = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number
  );
  const telUrl = useSelector((state) => state.app.tenantSettings?.tel_url);
  const tenantFullName = useSelector((state) => state.app.tenantSettings.tenant.full_name);

  useEffect(() => {
    FailedPaymentDataLayer();
  }, []);

  const renderAsaStatusMessage = (status) => {
    const statusMessages = {
      [asaStatuses.DECLINED_BY_ATTORNEY]: t(
        "Your agreement was not approved by the attorney. For assistance, please contact us at "
      ),
      [asaStatuses.DECLINED_BY_CLIENT]: t(
        "You have declined the agreement. Please select a different payment plan and complete your agreement using the payment options available on your account. If you need assistance, contact us at "
      ),
      [asaStatuses.EXPIRED]: t(
        "Your stipulation agreement has expired and your payment will not be processed. Please select a payment arrangement and complete your stipulation agreement to proceed. For further assistance, contact us at "
      ),
      [asaStatuses.ATTEMPTS_LIMIT_REACHED]: t(
        "We are no longer able to generate a stipulation agreement for your account. To resolve this matter, contact us at "
      ),
    };

    return statusMessages[status] ? (
      <div>
        {statusMessages[status]} <a href={telUrl}>{phone}</a>.
      </div>
    ) : null;
  };

  const renderJavitchError = () => {
    return (
      <div>
        Please Contact {tenantFullName} at <a href={telUrl}>{phone}</a>.
      </div>
    )
  }

  const renderErrorMessage = () =>
    errorMessage && (
      <div className="inner-text">
        <div>
          {t(
            "Last payment was unsuccessful. Please update your payment method or contact us at "
          )}
          <a href={telUrl}>{phone}</a>.
        </div>
        <div className="space">{errorMessage}</div>
      </div>
    );

  return (
    <div className="failed-payment-info-container">
      <div className="text-label">
        <img src={alertIcon} alt="alert" />
        {renderAsaStatusMessage(asa_status)}
        {renderErrorMessage()}
        {javitchError && renderJavitchError()}
        {alert_notification && <div>{alert_notification}</div>}
      </div>
    </div>
  );
};

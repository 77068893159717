export const filterItems = [
  {
    title: "All",
    value: " ",
  },
  {
    title: "Active",
    value: "Active",
  },
  {
    title: "Established",
    value: "Established",
  },
  {
    title: "Delinquent",
    value: "Delinquent",
  },
  {
    title: "Recalled",
    value: "Recalled",
  },
  {
    title: "Paid Off",
    value: "Paid Off",
  },
  {
    title: "On Hold",
    value: "On Hold",
  }
];

import {
  DISABLE_BUTTON,
  ENABLE_BUTTON,
  SET_STEP,
  SET_ERROR,
  SHOW_LOADER,
  HIDE_LOADER,
} from "../types";

const initialState = {
  isButtonDisabled: false,
  currentStep: "1",
  error: "",
  loading: false,
};

export const adminLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISABLE_BUTTON:
      return { ...state, isButtonDisabled: true };
    case ENABLE_BUTTON:
      return { ...state, isButtonDisabled: false };
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case SET_STEP:
      return { ...state, currentStep: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

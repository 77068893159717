import React from "react";

import "../style.scss";

export const DetailsBlock = ({externalDebtInfo}) => {

  return (
    <>
      <div className="customer-service-container">
        <div className="header-container">
          <div className="header">Details</div>
        </div>
        <div className="body">
          <div className="body-container">
            <div className="block-container">
              <span className="label">Creditor</span>
              <span className="value">{externalDebtInfo?.creditor_address_full ? externalDebtInfo.creditor_address_full : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Originator</span>
              <span className="value">{externalDebtInfo?.originator ? externalDebtInfo.originator : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Branch Name</span>
              <span className="value">{externalDebtInfo?.branch_name ? externalDebtInfo.branch_name : "-"}</span>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
import React, { useEffect } from "react";
import { Error404 } from "lib/helpers/dataLayers";

export const Page404 = () => {

  useEffect(() => {
    Error404();
  }, []);

  return (
    <div className="no-page">404</div>
  )
}
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import moment from "moment";

import { PageTitle } from "../PageTitle";
import { KeyboardArrowLeft } from "@material-ui/icons";
import "./style.scss";
import { getConsumerDebtHistory } from "../../lib/api/common";
import { InfoTooltip } from "../InfoTooltip";
import { wtError } from "lib/helpers/dataLayers";

export const PageHeader = ({
  title,
  backURL,
  backTitle,
  consumersNavigation,
  consumerInfo,
  addAgentButton,
}) => {
  const alert = useAlert();
  const { id } = useParams();
  const [error, setError] = useState(null);
  const profileData = useSelector((state) => state.profile.profileData);

  // Using this effect to show error for 1 day after payment was unsuccessful
  useEffect(() => {
    const fetchConsumerHistory = async () => {
      const history = await getConsumerDebtHistory();

      if (history.success !== "True") {
        wtError(history);
        alert.error("Something went wrong.");
        return;
      }

      const unsuccessfulOTPPayments = history.otp_payment_history
        ? history.otp_payment_history[0].payment_history.filter(
            (historyItem) => historyItem.success === false
          )
        : [];
      const unsuccessfulPayments = history.debts_payment_history
        ? history.debts_payment_history[0].payment_history.filter(
            (historyItem) => historyItem.success === false
          )
        : [];

      const combinedUnsuccessfulHistory = [
        ...unsuccessfulOTPPayments,
        ...unsuccessfulPayments,
      ];

      const currentDate = moment();
      const threeDaysBeforeToday = currentDate.subtract(24, "hours");
      const lastUnsuccessfullDate =
        combinedUnsuccessfulHistory[combinedUnsuccessfulHistory.length - 1]
          ?.payment_date;

      if (
        combinedUnsuccessfulHistory.length > 0 &&
        moment(lastUnsuccessfullDate).isAfter(threeDaysBeforeToday)
      )
        setError(true);
    };
    if (!id && window.location.pathname === "/cabinet") {
      fetchConsumerHistory();
    }
    // eslint-disable-next-line
  }, []);

  const mainClass = () => {
    if (backURL && !consumersNavigation) {
      return "page-header page-header--link page-header--medium";
    } else if (backURL && consumersNavigation) {
      return "page-header page-header--link page-header--large";
    } else if (consumersNavigation && !error) {
      return "page-header page-header--semi";
    } else if (consumersNavigation && error) {
      return "page-header page-header--semi page-header--with-error";
    } else if (addAgentButton) {
      return "page-header page-header--row";
    } else {
      return "page-header";
    }
  };

  return (
    <div className={mainClass()}>
      <div className="page-header__body">
        <section>
          {backURL && (
            <div className="back-btn">
              <KeyboardArrowLeft />
              <a href={backURL}>{backTitle}</a>
            </div>
          )}
          <PageTitle
            title={
              title || (profileData.profile && profileData.profile.full_name)
            }
          />
          {backURL === "/admin/consumers" && (
            <div className="agent-block">
              <>
                <span className="label">Agent:</span>
                <span className="value">{profileData.profile?.agent ? profileData.profile?.agent : "Unassigned"}</span>
              </>
              <>
                <span className="label segment">Segment:</span>
                <span className="value">{profileData.profile?.segment ? profileData.profile?.segment : "Unassigned"}</span>
              </>
            </div>
          )}
          {consumersNavigation}
          {addAgentButton}
        </section>
        {consumerInfo}
      </div>
      {error ? (
        <div className="page-header__error error">
          <div className="error__container">
            <InfoTooltip title="There was an error with your payment." />
            System Error: Your payment was not able to be processed. Please
            contact support at (888) 852-0054.
          </div>
        </div>
      ) : null}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  backURL: PropTypes.string,
  backTitle: PropTypes.string,
  consumersNavigation: PropTypes.object,
  consumerInfo: PropTypes.object,
  addAgentButton: PropTypes.object,
};

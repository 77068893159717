import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

import "./style.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const PaymentsPagination = ({
  currentPage,
  setCurrentPage,
  pagesCount,
}) => {
  const classes = useStyles();

  const handleChange = (e, value) => {
    if (value !== currentPage) {
      setCurrentPage(value);
    }
  };

  return (
    <div className="payments-pagination">
      <div className={classes.root}>
        <Pagination
          hidePrevButton 
          hideNextButton
          count={pagesCount}
          page={currentPage}
          defaultPage={1}
          shape="rounded"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default PaymentsPagination;

PaymentsPagination.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pagesCount: PropTypes.any,
};

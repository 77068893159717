import moment from "moment";

export const convertReceiptData = (data, logoUrl, phone) => {
  const splitData = data.split("'template__images'>");
  const joinedData = `${splitData[0]}'template__images'><img src='${logoUrl}' /><p>${phone}</p>${splitData[1]}`;
  return joinedData;
};

export const NACHAButtonName = (item) => {
  if (
    item.payment_frequency === "One Time" ||
    item.payment_frequency === "Extra" ||
    (item.payment_frequency === "Regular" && item.number_of_payments === 1)
  )
    return "S";
  if (item.payment_frequency === "Regular" && item.number_of_payments > 1)
    return "M";
};

export const paymentProcessingDate = (date, paymentType) => {
  if (paymentType === "ach")
    return moment.utc(date).add(5, "days").format("ll");
  if (paymentType === "other") return moment.utc(date).format("ll");
  else return moment.utc(date).add(2, "days").format("ll");
};

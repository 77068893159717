import {
  FETCH_PAYMENTS_DATA,
  HIDE_PAYMENTS_LOADER,
  SHOW_PAYMENTS_LOADER,
  SET_NACHA_DATA,
  SET_NACHA_MODAL_DATA,
  SET_PAYMENTS_ESTABLISHED_DEBTS,
} from "../types";

import {
  getConsumerDebts,
  getSavedBankAccounts,
  getSavedCards,
  getNACHA,
  getPlanInfo,
  getConsumerDebtSchedule
} from "../../lib/api/common";

export const showPaymentsLoader = () => {
  return { type: SHOW_PAYMENTS_LOADER };
};

export const hidePaymentsLoader = () => {
  return { type: HIDE_PAYMENTS_LOADER };
};

export function setNACHAModalData(data) {
  return { type: SET_NACHA_MODAL_DATA, payload: data };
}

export const fetchSavedPaymentMethod = (consumerId) => {
  return async (dispatch) => {
    dispatch(showPaymentsLoader());
    const paymentsData = { savedAccounts: [], savedCards: [] };
    const savedAccounts = await getSavedBankAccounts({ client_id: consumerId });
    paymentsData.savedAccounts = savedAccounts.data;
    const savedCards = await getSavedCards({ client_id: consumerId });
    paymentsData.savedCards = savedCards.data;

    dispatch({ type: FETCH_PAYMENTS_DATA, payload: paymentsData });
    dispatch(hidePaymentsLoader());
  }
}

export const fetchConsumerPaymentsData = (consumerId) => {
  return async (dispatch) => {
    dispatch(showPaymentsLoader());
    const paymentsData = { savedAccounts: [], savedCards: [] };
    const savedAccounts = await getSavedBankAccounts({ client_id: consumerId });
    let debtsEstablished;
    if (savedAccounts.success === "True") {
      paymentsData.savedAccounts = savedAccounts.data;
      debtsEstablished = await getConsumerDebts("established", {
        client_id: consumerId,
      });

      dispatch({
        type: SET_PAYMENTS_ESTABLISHED_DEBTS,
        payload: debtsEstablished.data,
      });

      if (debtsEstablished.success !== "True") {
        // eslint-disable-next-line
        console.log("Something went wrong.");
        return;
      }

      // THIS SHOULD BE REMOVED
      const nachaTest = await getNACHA({
        client_id: consumerId,
        payment_date: new Date(),
        payment_frequency: "Regular",
      });

      if (
        debtsEstablished.data.length > 0 &&
        savedAccounts.data.length > 0 &&
        nachaTest.success === "True"
      ) {
        const nachaData = await getNACHA({
          client_id: consumerId,
          payment_date: new Date(),
          payment_frequency: "Regular",
        });

        if (nachaData.success !== "True") {
          // eslint-disable-next-line
          console.log("Something went wrong.");
          return;
        }

        const planData = await getPlanInfo({
          client_id: consumerId,
          plan_id: debtsEstablished.data[0].plan_id,
        });

        if (planData.success !== "True") {
          // eslint-disable-next-line
          console.log("Something went wrong.");
          return;
        }

        const paymentSchedule = await getConsumerDebtSchedule({
          client_id: consumerId,
          debt_id: debtsEstablished.data.debt_id,
        });

        if (paymentSchedule.success !== "True") {
          // eslint-disable-next-line
          console.log("Something went wrong.");
          return;
        }

        dispatch(
          setNACHAModalData({
            number_of_payments:
              paymentSchedule.debts_payment_history[0].number_of_payments,
            payment_amount: planData.data.per_month,
            payment_frequency: planData.data.payment_frequency,
            name_on_check: nachaData.nacha_details.name_on_check || "",
            email: nachaData.nacha_details.email,
          })
        );

        dispatch({ type: SET_NACHA_DATA, payload: nachaData.nacha_details });
      }
    } else {
      paymentsData.savedAccounts = savedAccounts.data;
      // eslint-disable-next-line
      console.log("Error: ", savedAccounts);
    }

    const savedCards = await getSavedCards({ client_id: consumerId });

    if (savedCards.success === "True") {
      paymentsData.savedCards = savedCards.data;
    } else {
      paymentsData.savedCards = savedCards.data;
      // eslint-disable-next-line
      console.log("Error: ", savedCards);
    }

    dispatch({ type: FETCH_PAYMENTS_DATA, payload: paymentsData });

    dispatch(hidePaymentsLoader());
  };
};
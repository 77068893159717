import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { Close } from "@material-ui/icons";
import { useParams } from "react-router-dom";

import { paymentAmounsList } from "./helpers/paymentAmounsListData";
import { CustomButton } from "../../../../components/ui-kit/CustomButtom";
import { paymentAmountSelectedOTP } from "lib/helpers/dataLayers";
import { SelectPaymentMethodModal } from "../SelectPaymentMethodModal";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const ExtraPaymentModal = ({modal, toggle, debt}) => {

  const { id } = useParams();

  const { t } = useTranslation();

  const [paymentAmount, setPaymentAmount] = useState("");
  const [isPaymentSelected, setIsPaymentSelected] = useState(false);

  const [selectPaymentMethodModal, setSelectPaymentMethod] = useState(false);
  const toggleSelectPaymentMethod = () => setSelectPaymentMethod(!selectPaymentMethodModal);

  const handleAmountChange = (e) => {
    const maxValue = debt.is_open_ended ? debt.currentBalance : debt.plan_balance;
    if (
      +e.target.value > maxValue ||
      +e.target.value < 0 ||
      isNaN(+e.target.value)
    )
      return;

    const value = e.target.value;
    if (value.split(".")[1] && value.split(".")[1].length > 2) {
      const begin = value.split(".")[0];
      const end = value.split(".")[1].slice(0, 2);
      setPaymentAmount(`${begin}.${end}`);
    } else {
      setPaymentAmount(value);
    }

    if (
      e.target.value !== "" &&
      !isNaN(+e.target.value) &&
      e.target.value >= 0.05
    ) {
      setIsPaymentSelected(true);
    } else {
      setIsPaymentSelected(false);
    }
  };

  const handleSelectAmount = (amount) => {
    if (amount === "Full") {
      if (debt.is_open_ended && id) {
        setPaymentAmount(debt.currentBalance);
      } else {
        setPaymentAmount(debt.plan_balance);
      }
      setIsPaymentSelected(true);
      return;
    }
    const formattedAmount = amount.split(" ")[1];
    setPaymentAmount(formattedAmount);
    setIsPaymentSelected(true);
  };

  const handleSetupPayment = () => {
    paymentAmountSelectedOTP();
    toggleSelectPaymentMethod();
  };

  const isAmountButtonDisabled = (amount) => {
    if (debt.is_open_ended) {
      return Number(amount.split(" ")[1]) > +debt.currentBalance;
    } else {
      return Number(amount.split(" ")[1]) > +debt.plan_balance;
    }
  };

  const closeAndReboot = () => {
    window.location.reload(false);
  }

  return (
    <>
      <Modal
        isOpen={modal}
        // toggle={toggle}
        className="one-time-payment-modal"
        centered
      >
        <ModalBody>
          <div className="mod-header">
            <span>{t("How much do you want to pay?")}</span>
            <button className="close-modal-btn" onClick={() => closeAndReboot()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            <span>
              {t("Enter any amount that fits your current financial situation")}
            </span>
            <div className="otp-input">
              <p>
                {t("Amount")}
              </p>
              <input
                type="text"
                // placeholder="Amount"
                // disabled={preview}
                value={paymentAmount}
                onChange={handleAmountChange}
              />
              <span>$</span>
            </div>
            <div className="otp-possible-payment-container">
              <ul className="otp-possible-payment-btn-list list-unstyled">
                {paymentAmounsList.map((amount, i) => (
                  <li key={i}>
                    <CustomButton
                      type="secondary"
                      size="small"
                      optionalClass="otp-possible-payment-btn"
                      text={t(amount)}
                      onClickHandler={() => handleSelectAmount(amount)}
                      disabled={isAmountButtonDisabled(amount)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mod-footer">
            <CustomButton
              text={t("Cancel")}
              onClickHandler={() => closeAndReboot()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text={t("Select Amount")}
              onClickHandler={() => handleSetupPayment()}
              disabled={!isPaymentSelected}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
      </Modal>
      <SelectPaymentMethodModal 
        toggle={toggleSelectPaymentMethod}
        modal={selectPaymentMethodModal}
        parentModal={modal}
        parentToggle={toggle}
        paymentAmount={paymentAmount}
        isChangePaymentMethod={false}
        debt={debt}
      />
    </>
  )

}

ExtraPaymentModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  debt: PropTypes.object,
}
import React from "react";
import { useParams } from "react-router-dom";

import { getConsumerDebts } from "../../../../lib/api/common";

import "./style.scss";

export const Success = () => {
  const { id } = useParams();

  const handleGoToDebts = async () => {
    if (id) {
      window.location.replace(`/admin/consumers/consumer/${id}`);
    } else {
      const data = await getConsumerDebts("active");
      if (data.data.length) {
        window.location.replace(`/`);
      } else {
        window.location.replace(`/cabinet#established`);
      }
    }
  };

  return (
    <div className="success-screen">
      <div>
        <h2 className="heading heading-2">Success</h2>
        <p>You have successfully changed your payment method.</p>
      </div>
      <button
        className="button button-primary button-primary--medium"
        onClick={handleGoToDebts}
      >
        Go to Accounts
      </button>
    </div>
  );
};

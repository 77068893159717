import { useState } from "react";

export function useCookies() {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    !!document.cookie.match("cookies_accepted=true")
  );

  const [welcomeScreenAccepted, setWelcomeScreenAccepted] = useState(
    !!document.cookie.match("welcomeScreen_accepted=true")
  );

  function handleAcceptCookies() {
    setCookiesAccepted(true);
    document.cookie = "cookies_accepted=true";
  }

  function handleWelcomeAccepted() {
    setWelcomeScreenAccepted(true);
    document.cookie = "welcomeScreen_accepted=true";
  }

  return { cookiesAccepted, welcomeScreenAccepted,  handleAcceptCookies, handleWelcomeAccepted };
}

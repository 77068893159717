import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import { SystemLockIcon16 } from "../../../../components/icons/SystemIcons";
import "./style.scss";
import { setRequest } from "../../../../redux/actions/setupPlan";
import { fetchConsumerProfile } from "../../../../redux/actions/profile";
import { AuthAgreementWebOnePay } from "./AuthAgreementWebOnePay";
import { AuthAgreementWebMultiPay } from "./AuthAgreementWebMultiPay";
import { AuthAgreementPhoneSinglePay } from "./AuthAgreementPhoneSinglePay";
import { AuthAgreementPhoneMultiPay } from "./AuthAgreementPhoneMultiPay";
import { ReceiptModal } from "../../../../components/ReceiptModal";
import {
  NACHAPhoneMultiModalData,
  NACHAPhoneSingleModalData,
  NACHAWebMultiModalData,
  NACHAWebSingleModalData,
} from "../../../../lib/common/NACHAModalData";
import { accountNumberLastFour } from "../../../../lib/helpers/other";
import { getConsumerDebtSchedule } from "../../../../lib/api/common";
import { ACHPreview } from "../ACHPreview";

export const BankAccountPayment = ({
  accountPaymentType,
  handleSetAccountPaymentRequest,
  planInfo,
  selectedPaymentAccount,
  isChangingPaymentMethod,
}) => {
  const alert = useAlert();
  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );

  const paymentDates = useSelector((state) => state.setupPlan.paymentDates);

  const { id, debtId } = useParams();
  const dispatch = useDispatch();
  const [type, setType] = useState("1");
  const [authMethod, setAuthMethod] = useState("web");
  const [scheduleData, setScheduleData] = useState([]);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const consumersData = useSelector((state) => state.profile.profileData);
  const phone = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number || ""
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours || "");
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name || ""
  );
  useEffect(() => {
    _.isEmpty(consumersData) && dispatch(fetchConsumerProfile(id));
    dispatch(
      setRequest({
        ...accountPaymentRequest,
        name_on_check: consumersData.profile?.full_name,
        email: consumersData.profile?.email,
      })
    );
    // eslint-disable-next-line
  }, [consumersData]);

  useEffect(() => {
    // Get Schedule for payment amount (for NACHA) - only for Change Payment method
    const fetchPaymentSchedule = async () => {
      const data = await getConsumerDebtSchedule({
        client_id: id,
        debt_id: debtId,
      });

      if (data.success === "True") {
        setScheduleData(
          data.debts_payment_history[0].payment_schedule[0].amount
        );
      } else {
        alert.error(data.message);
      }
    };

    if (isChangingPaymentMethod) {
      fetchPaymentSchedule();
    }
    // eslint-disable-next-line
  }, []);

  const handleTypeChange = (e) => {
    setType(e.target.value);
    handleSetAccountPaymentRequest(e);
  };

  const setAuthMethodFunc = (e) => {
    setAuthMethod(e.target.value);
    handleSetAccountPaymentRequest(e);
  };

  const disabledFormField =
    selectedPaymentAccount !== "new" &&
    selectedPaymentAccount !== "account" &&
    selectedPaymentAccount !== "";

  const renderNACHABlock = () => {
    if (accountPaymentType === "single-web") {
      return (
        <AuthAgreementWebOnePay
          accountType={type}
          authorizationSignature={consumersData.profile?.full_name}
          accountNumber={accountNumberLastFour(
            accountPaymentRequest.account_number
          )}
          selectedPaymentAccount={selectedPaymentAccount}
          setIsShowingModal={setIsShowingModal}
        />
      );
    }

    if (accountPaymentType === "multi-web") {
      return (
        <AuthAgreementWebMultiPay
          accountType={type}
          accountPaymentRequest={accountPaymentRequest}
          authorizationSignature={consumersData.profile?.full_name}
          paymentFrequency={planInfo.payment_frequency}
          paymentAmount={
            planInfo.per_month || (scheduleData?.length > 0 && scheduleData)
          }
          selectedPaymentAccount={selectedPaymentAccount}
          setIsShowingModal={setIsShowingModal}
          isChangingPaymentMethod={isChangingPaymentMethod}
        />
      );
    }

    if (accountPaymentType === "single-call") {
      return (
        <AuthAgreementPhoneSinglePay
          accountType={type === "1" ? "Checking" : "Savings"}
          accountPaymentRequest={accountPaymentRequest}
          accountNumber={accountNumberLastFour(
            selectedPaymentAccount?.AccountNumber ||
              accountPaymentRequest.account_number
          )}
          authorizationSignature={consumersData.profile?.full_name}
          selectedPaymentAccount={selectedPaymentAccount}
          setIsShowingModal={setIsShowingModal}
        />
      );
    }

    if (accountPaymentType === "multi-call") {
      return (
        <AuthAgreementPhoneMultiPay
          accountType={type === "1" ? "Checking" : "Savings"}
          accountNumber={accountNumberLastFour(
            selectedPaymentAccount?.AccountNumber ||
              accountPaymentRequest.account_number
          )}
          consumerName={
            accountPaymentRequest.name_on_check ||
            consumersData.profile?.full_name
          }
          amountPerPayment={planInfo.per_month || scheduleData}
          selectedPaymentAccount={selectedPaymentAccount}
          setIsShowingModal={setIsShowingModal}
          isChangingPaymentMethod={isChangingPaymentMethod}
        />
      );
    }
  };

  return (
    <div className="bank-account-payment">
      <h2>Payment Method</h2>
      <ACHPreview
        finantial_institution={
          selectedPaymentAccount?.BankName || accountPaymentRequest.bank_name
        }
        account_number={
          selectedPaymentAccount?.AccountNumber ||
          accountPaymentRequest.account_number
        }
        routing_number={
          selectedPaymentAccount?.RoutingNumber ||
          accountPaymentRequest.routing_number
        }
        name_on_check={
          accountPaymentRequest.name_on_check ||
          consumersData.profile?.full_name
        }
      />
      <form>
        <div className="form-gorup">
          <label
            className="heading heading-caption-bold"
            htmlFor="account_type"
          >
            Authorization Method
          </label>
          <Select
            labelId="authorization"
            id="authorization"
            name="authorization"
            onChange={setAuthMethodFunc}
            disabled={disabledFormField}
            input={
              <InputBase value={authMethod} className="input input-medium" />
            }
          >
            <MenuItem value={"web"}>Web</MenuItem>
            <MenuItem value={"verbal"}>Verbal</MenuItem>
            <MenuItem value={"written"}>Written</MenuItem>
          </Select>
        </div>
        <div className="form-gorup">
          <label
            className="heading heading-caption-bold"
            htmlFor="account_type"
          >
            Account Type
          </label>
          <Select
            labelId="type"
            id="account_type"
            name="account_type"
            onChange={handleTypeChange}
            disabled={disabledFormField}
            input={<InputBase value={type} className="input input-medium" />}
          >
            <MenuItem value={"1"}>Checking</MenuItem>
            <MenuItem value={"2"}>Savings</MenuItem>
          </Select>
        </div>
        <div className="form-gorup">
          <label
            className="heading heading-caption-bold"
            htmlFor="name_on_check"
          >
            Name On Check
          </label>
          <input
            type="text"
            className="input input-medium"
            name="name_on_check"
            id="name_on_check"
            onChange={handleSetAccountPaymentRequest}
            defaultValue={accountPaymentRequest.name_on_check}
            disabled={disabledFormField}
          />
        </div>
        <div className="form-gorup">
          <label
            className="heading heading-caption-bold"
            htmlFor="routing_number"
          >
            Routing Number
          </label>
          <input
            type="number"
            className="input input-medium"
            id="routing_number"
            name="routing_number"
            onChange={handleSetAccountPaymentRequest}
            value={
              selectedPaymentAccount?.RoutingNumber ||
              accountPaymentRequest.routing_number
            }
            disabled={disabledFormField}
          />
        </div>
        <div className="form-gorup">
          <label
            className="heading heading-caption-bold"
            htmlFor="account_number"
          >
            Account Number
          </label>
          <input
            type="number"
            className="input input-medium"
            name="account_number"
            id="account_number"
            onChange={handleSetAccountPaymentRequest}
            value={
              selectedPaymentAccount?.AccountNumber ||
              accountPaymentRequest.account_number
            }
            disabled={disabledFormField}
          />
        </div>
        <div className="form-gorup">
          <label className="heading heading-caption-bold" htmlFor="bank_name">
            Bank Name
          </label>
          <input
            type="text"
            className="input input-medium"
            id="bank_name"
            name="bank_name"
            onChange={handleSetAccountPaymentRequest}
            value={
              selectedPaymentAccount?.BankName ||
              accountPaymentRequest.bank_name
            }
            disabled={disabledFormField}
          />
        </div>
        <div className="form-gorup">
          <label className="heading heading-caption-bold" htmlFor="bank_name">
            Email
          </label>
          <input
            type="text"
            className="input input-medium"
            id="email"
            name="email"
            defaultValue={accountPaymentRequest.email}
            onChange={handleSetAccountPaymentRequest}
          />
        </div>
        <div className="bank-account-payment__security-info">
          <SystemLockIcon16 />
          Secure SSL Encryption
        </div>
      </form>
      {renderNACHABlock()}
      {isShowingModal
        ? (accountPaymentType === "multi-web" && (
            <ReceiptModal
              data={NACHAWebMultiModalData(
                companyName,
                phone,
                hours,
                planInfo.payment_frequency,
                planInfo.per_month || scheduleData,
                paymentDates?.start_date,
                type,
                selectedPaymentAccount?.BankName ||
                  accountPaymentRequest.bank_name,
                accountPaymentRequest.name_on_check,
                selectedPaymentAccount?.RoutingNumber ||
                  accountPaymentRequest.routing_number,
                accountNumberLastFour(
                  selectedPaymentAccount?.AccountNumber ||
                    accountPaymentRequest.account_number
                ),
                isChangingPaymentMethod
              )}
              setShowModal={setIsShowingModal}
            />
          )) ||
          (accountPaymentType === "single-web" && (
            <ReceiptModal
              data={NACHAWebSingleModalData(
                companyName,
                phone,
                hours,
                type,
                selectedPaymentAccount?.BankName ||
                  accountPaymentRequest.bank_name,
                accountPaymentRequest.name_on_check,
                selectedPaymentAccount?.RoutingNumber ||
                  accountPaymentRequest.routing_number,
                accountNumberLastFour(
                  selectedPaymentAccount?.AccountNumber ||
                    accountPaymentRequest.account_number
                )
              )}
              setShowModal={setIsShowingModal}
            />
          )) ||
          (accountPaymentType === "multi-call" && (
            <ReceiptModal
              data={NACHAPhoneMultiModalData(
                companyName,
                phone,
                hours,
                paymentDates?.start_date,
                planInfo.per_month || scheduleData,
                accountPaymentRequest.name_on_check,
                type,
                accountNumberLastFour(
                  selectedPaymentAccount?.AccountNumber ||
                    accountPaymentRequest.account_number
                ),
                isChangingPaymentMethod
              )}
              setShowModal={setIsShowingModal}
            />
          )) ||
          (accountPaymentType === "single-call" && (
            <ReceiptModal
              data={NACHAPhoneSingleModalData(
                companyName,
                phone,
                hours,
                accountPaymentRequest.name_on_check,
                type,
                accountNumberLastFour(
                  selectedPaymentAccount?.AccountNumber ||
                    accountPaymentRequest.account_number
                )
              )}
              setShowModal={setIsShowingModal}
            />
          ))
        : null}
    </div>
  );
};

BankAccountPayment.propTypes = {
  accountPaymentRequest: PropTypes.object,
  handleSetAccountPaymentRequest: PropTypes.func,
  accountPaymentType: PropTypes.string,
  planInfo: PropTypes.object,
  selectedPaymentAccount: PropTypes.any,
  isChangingPaymentMethod: PropTypes.bool,
};

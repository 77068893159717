import { FETCH_HISTORY_DATA } from "../types";

import { showLoader, hideLoader } from "./app";

import { getConsumerHistory } from "../../lib/api/admin";

// HISTORY
export function fetchConsumerHistory(request) {
  return async (dispatch) => {
    dispatch(showLoader());
    let data;

    try {
      data = await getConsumerHistory({ ...request });

      if (data.success === "True") {
        dispatch({ type: FETCH_HISTORY_DATA, payload: data.data });
      } else {
        // eslint-disable-next-line
        console.log("Error: ", data);
      }
      dispatch(hideLoader());
    } catch (e) {
      // eslint-disable-next-line
      console.log("Error: ", e);
    }
  };
}
import React from "react";
import PropTypes from "prop-types";

export const ButtonLink = ({ text = "button", onClickHandler, buttonId }) => {
  return (
    <button
      className="button button-link"
      onClick={onClickHandler}
      id={buttonId}
    >
      {text}
    </button>
  );
};

ButtonLink.propTypes = {
  text: PropTypes.string,
  onClickHandler: PropTypes.func,
  buttonId: PropTypes.string,
};

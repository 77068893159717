import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

import { CustomButton } from "components/ui-kit/CustomButtom";

import "./style.scss";

export const SuccessHelpModal = ({modal, type}) => {

  const { t } = useTranslation();

  const closeModal = () => {
    window.location.replace("/");
  }

  const renderTitle = () => {
    if (type === "hardships") {
      return (
        <div className="title">{t("Your hardship was successfully sent.")}</div>
      )
    }
    if (type === "disputes") {
      return (
        <div className="title">{t("Your disputes was successfully sent.")}</div>
      )
    }
    if (type === "complaints") {
      return (
        <div className="title">{t("Your complaint was successfully sent.")}</div>
      )
    }
  }

  const renderSubtitle = () => {
    if (type === "hardships") {
      return (
        <div className="subtitle">{t("Our team will be in touch shortly regarding your inquiry.")}</div>
      )
    }
    if (type === "disputes") {
      return (
        <div className="subtitle">{t("Our team will be in touch shortly regarding your inquiry.")}</div>
      )
    }
    if (type === "complaints") {
      return (
        <div className="subtitle">{t("Our team will be in touch shortly regarding your inquiry.")}</div>
      )
    }
  }

  return (
    <Modal
      centered
      isOpen={modal}
      className="help-center-success"
    >
      <ModalBody>
        <div className="mod-body">
          {renderTitle()}
          {renderSubtitle()}
        </div>
        <div className="mod-footer">
          <CustomButton
            text={t("Close")}
            onClickHandler={() => closeModal()}
            optionalClass="close-btn"
          />
        </div>
      </ModalBody>
    </Modal>
  )

}
import React from "react";
import {
  NavigationConsumersIcon,
  NavigationProfileIcon,
} from "../icons/NavigationIcons";

export const authenticatedAgentNavItems = () => {
  const navItems = [
    {
      title: "Customers",
      link: "/admin/consumers",
      iconURL: "#",
      icon: <NavigationConsumersIcon />,
    },
    // {
    //   title: "Disputes",
    //   link: "/admin/disputes",
    //   iconURL: "#",
    //   icon: <NavigationConsumersIcon />,
    // },
    {
      title: "My Profile",
      link: "/admin/profile",
      iconURL: "#",
      icon: <NavigationProfileIcon />,
    },
  ];

  return navItems;
};

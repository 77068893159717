import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { useAlert } from "react-alert";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "components/LoadingSpinner";

import { AuthAgreementPhoneMultiPay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementPhoneMultiPay";
import { AuthAgreementPhoneSinglePay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementPhoneSinglePay";
import { ReceiptModal } from "components/ReceiptModal";
import { NACHAPhoneMultiModalData } from "lib/common/NACHAModalData";
import { NACHAPhoneSingleModalData } from "lib/common/NACHAModalData";

import {
  formPaymentIframe,
  addPaymentCard,
  submitPayment,
  submitOneTimePayment,
  generateNACHA,
} from "lib/api/common";
import { setRequest } from "redux/actions/setupPlan";

import { FinalDetailsModal } from "../FinalDetailsModal";
import { ChangePaymentSuccess } from "ConsumerPage/PaymentsHistoryContainer/ExtraPaymentFlow/SelectPaymentMethodModal/ChangePaymentSuccess";

import "./style.scss";

let count = 0;

export const NewDebitCardModal = ({
  modal,
  toggle,
  parentModal,
  parentToggle,
  debt,
  dateValue,
  selectedPlan,
  possiblePaymentSchedule,
  type,
  paymentAmount,
  isChangePaymentMethod,
}) => {
  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [repayURL, setRepayURL] = useState(null);

  const [authMethod, setAuthMethod] = useState("web");
  // Need ref to set last useState for event listener
  const authMethodStateRef = useRef(authMethod);

  const [modalOverlay, setModalOverlay] = useState(true);

  const [isFinalDetailsModalOpen, setIsFinalDetailsModalOpen] = useState(false);
  const toggleFinalDetailsModal = () =>
    setIsFinalDetailsModalOpen(!isFinalDetailsModalOpen);

  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const consumersData = useSelector((state) => state.profile.profileData);
  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});
  const [isShowingModal, setIsShowingModal] = useState(false);
  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const [changePaymentSuccessModal, setChangePaymentSuccessModal] =
    useState(false);
  const toggleChangePaymentSuccessModal = () =>
    setChangePaymentSuccessModal(!changePaymentSuccessModal);
  const phone = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number || ""
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours || "");
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name || ""
  );
  useEffect(() => {
    const fetchAddCard = async () => {
      setLoading(true);
      const data = await formPaymentIframe({
        client_id: id,
        debt_id: debt.debt_id,
        data_id: debt.referenceNumber,
      });
      setRepayURL(data.url);
      setLoading(false);

      dispatch(
        setRequest({
          ...accountPaymentRequest,
          name_on_check: consumersData.profile?.full_name,
          email: consumersData.profile?.email,
        })
      );
    };

    if (debt && modal) {
      fetchAddCard();
    }

    window.addEventListener("message", receiveMessageNewDebitCard, false);
  }, [modal]);
  // catch iframe authorize click

  async function receiveMessageNewDebitCard(event) {
    if (count > 0) return;

    if (event.data === "PAYMENT_SUCCESS") {
      count++;
      addPaymentCardToConsumer();
    }
  }

  const addPaymentCardToConsumer = async () => {
    setLoading(true);
    await new Promise((r) => setTimeout(r, 4000));
    const cardData = await addPaymentCard({
      client_id: id,
      authorization: authMethodStateRef.current,
      data_id: debt.referenceNumber,
    });

    if (cardData.success === "True") {
      setSelectedPaymentObject(cardData.card_details);
      if (isChangePaymentMethod) {
        await generateNACHA({
          agreement_timestamp: new Date(),
          email: profileInfo.email,
          client_id: id,
          payment_frequency: "Regular",
          data_id: debt.referenceNumber,
        });
        toggleChangePaymentSuccessModal();
        return;
      }
      if (type === "OTP") {
        makeOTPPayment(cardData);
      } else if (type === "PLAN") {
        makeSubmitPayment(cardData);
      }
    } else {
      setLoading(false);
      alert.error(cardData.message);
    }
  };

  const makeOTPPayment = async (cardData) => {
    await new Promise((r) => setTimeout(r, 4000));
    let data;

    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      card_id: cardData?.card_id,
      debt_id: debt.debt_id,
      payment_method: "card",
    };

    setLoading(true);
    data = await submitOneTimePayment(request);

    if (data.success === "True") {
      setLoading(false);
      // for new TKL
      if (cardData.card_details === null) {
        setSelectedPaymentObject(data.card_details);
      }
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const makeSubmitPayment = async (cardData) => {
    await new Promise((r) => setTimeout(r, 4000));
    let data;
    data = await submitPayment({
      client_id: id,
      payment_method: "card",
      card_id: cardData?.cardDetails?.card_id,
      debt_id: debt.debt_id,
      amount: debt.plan_balance,
      plan_id: selectedPlan.id,
      start_day: dateValue,
      start_date: possiblePaymentSchedule?.possible_payment_info.start_date,
      end_date: possiblePaymentSchedule?.possible_payment_info.end_date,
      agreement_timestamp: new Date(),
    });

    if (data.success === "True") {
      setLoading(false);
      // for new TKL
      if (cardData.card_details === null) {
        setSelectedPaymentObject(data.card_details);
      }
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const setAuthMethodFunc = (e) => {
    setAuthMethod(e.target.value);

    authMethodStateRef.current = e.target.value;
  };

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  };

  const toggleModal = () => {
    toggle();
    parentToggle();
  };

  const renderNACHABlock = () => {
    if (
      (!paymentAmount &&
        possiblePaymentSchedule?.possible_payment_info?.payments_number > 1) ||
      isChangePaymentMethod
    ) {
      return (
        <div className="nacha-con">
          <AuthAgreementPhoneMultiPay
            accountType={null}
            consumerName={
              accountPaymentRequest.name_on_check ||
              consumersData.profile?.full_name
            }
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            isChangingPaymentMethod={isChangePaymentMethod}
            startDate={
              possiblePaymentSchedule?.possible_payment_info.start_date
            }
            endDate={possiblePaymentSchedule?.possible_payment_info.end_date}
            consumerState={consumersData.profile?.state}
            numberOfPayments={
              possiblePaymentSchedule?.possible_payment_info?.payments_number
            }
            payment_frequency={selectedPlan?.payment_frequency}
            start_amount={
              possiblePaymentSchedule?.possible_payment_info.start_amount
            }
            end_amount={
              possiblePaymentSchedule?.possible_payment_info.end_amount
            }
          />
        </div>
      );
    } else {
      return (
        <div className="nacha-con">
          <AuthAgreementPhoneSinglePay
            accountType={null}
            accountPaymentRequest={accountPaymentRequest}
            authorizationSignature={consumersData.profile?.full_name}
            paymentAmount={
              paymentAmount
                ? paymentAmount
                : possiblePaymentSchedule?.possible_payment_info.per_month
            }
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            consumerState={consumersData.profile?.state}
            startDate={
              possiblePaymentSchedule?.possible_payment_info.start_date
            }
          />
        </div>
      );
    }
  };

  const renderReceiptModal = () => {
    if (
      (!paymentAmount &&
        possiblePaymentSchedule?.possible_payment_info?.payments_number > 1) ||
      isChangePaymentMethod
    ) {
      return (
        <ReceiptModal
          data={NACHAPhoneMultiModalData(
            companyName,
            phone,
            hours,
            possiblePaymentSchedule?.possible_payment_info.start_date,
            possiblePaymentSchedule?.possible_payment_info.end_date,
            accountPaymentRequest.name_on_check,
            null,
            null,
            isChangePaymentMethod,
            consumersData.profile?.state,
            possiblePaymentSchedule?.possible_payment_info.payments_number,
            selectedPlan?.payment_frequency,
            possiblePaymentSchedule?.possible_payment_info.start_amount,
            possiblePaymentSchedule?.possible_payment_info.end_amount
          )}
          setShowModal={setIsShowingModal}
        />
      );
    } else {
      return (
        <ReceiptModal
          data={NACHAPhoneSingleModalData(
            companyName,
            phone,
            hours,
            accountPaymentRequest.name_on_check,
            null,
            null,
            paymentAmount
              ? paymentAmount
              : possiblePaymentSchedule?.possible_payment_info.per_month,
            consumersData.profile?.state,
            possiblePaymentSchedule?.possible_payment_info.start_date,
            null
          )}
          setShowModal={setIsShowingModal}
        />
      );
    }
  };

  const displayNoneToModal = () => {
    document.getElementsByClassName("new-payment-modal")[0].style.display =
      "none";
    setModalOverlay(false);
  };

  return (
    <>
      <Modal
        isOpen={modal}
        // toggle={toggle}
        onOpened={closeParentModal}
        scrollable={true}
        className="new-payment-modal"
        backdrop={modalOverlay}
      >
        <ModalBody>
          <div className="mod-header">
            <span>Payment Details</span>
            <button className="close-modal-btn" onClick={() => toggleModal()}>
              <Close />
            </button>
          </div>
          {!loading ? (
            <div className="authorization-selector">
              <label className="heading-caption-bold" htmlFor="account_type">
                Authorization Method
              </label>
              <Select
                labelId="authorization"
                id="authorization"
                name="authorization"
                onChange={setAuthMethodFunc}
                input={
                  <InputBase
                    value={authMethod}
                    className="input input-medium"
                  />
                }
              >
                <MenuItem value={"web"}>Web</MenuItem>
                <MenuItem value={"verbal"}>Verbal</MenuItem>
                <MenuItem value={"written"}>Written</MenuItem>
              </Select>
            </div>
          ) : null}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <iframe
              id="iframe-modal"
              src={repayURL}
              title="Add Card"
              frameBorder="0"
            />
          )}
          {loading ? null : renderNACHABlock()}
        </ModalBody>
      </Modal>
      {isChangePaymentMethod && (
        <ChangePaymentSuccess
          modal={changePaymentSuccessModal}
          parentModal={modal}
          parentToggle={toggle}
        />
      )}
      {isShowingModal && renderReceiptModal()}
      {isFinalDetailsModalOpen && (
        <FinalDetailsModal
          isModalOpen={isFinalDetailsModalOpen}
          parentModal={modal}
          parentToggle={displayNoneToModal}
          startDate={possiblePaymentSchedule?.possible_payment_info.start_date}
          endDate={possiblePaymentSchedule?.possible_payment_info.end_date}
          selectedPlan={selectedPlan}
          selectedPaymentObject={selectedPaymentObject}
          paymentAmount={paymentAmount}
          debt={debt}
        />
      )}
    </>
  );
};

import { FETCH_SINGLE_BALANCE, HIDE_LOADER, SHOW_LOADER } from "../types";

const initialState = {
  loading: false,
  balanceData: null,
};

export const singleBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case FETCH_SINGLE_BALANCE:
      return { ...state, balanceData: action.payload };
    default:
      return state;
  }
};

import {
  FETCH_BALANCE,
} from "../types";

import { showLoader, hideLoader } from "./app";
import { 
  fetchActiveDebts, 
  fetchEstablishedDebts,
  fetchPaidoffDebts,
  fetchOnHoldDebts,
  fetchRecalledDebts,
  fetchDelinquentDebts
} from "./consumerDebts";

import {
  getBalance,
} from "../../lib/api/admin";

export function fetchBalance(consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getBalance({ client_id: consumerId });
    if (response.success === "True") {
      dispatch({ type: FETCH_BALANCE, payload: response });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(fetchActiveDebts(response.debts_info, consumerId));
    dispatch(fetchEstablishedDebts(response.debts_info, consumerId));
    dispatch(fetchDelinquentDebts(consumerId));
    dispatch(fetchRecalledDebts(consumerId));
    dispatch(fetchPaidoffDebts(consumerId));
    dispatch(fetchOnHoldDebts(consumerId));
    dispatch(hideLoader());
  };
}
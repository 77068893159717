import {
  SET_REQUEST,
  SET_IS_NEXT_BUTTON_DISABLED,
  SET_DATES,
  SET_PLAN_ID,
} from "../types";

const initialState = {
  accountPaymentRequest: {
    name_on_check: "",
    account_number: "",
    routing_number: "",
    bank_name: "",
    email: "",
  },
  isNextButtonDisabled: true,
  paymentDates: null,
  planId: null,
};

export const setupPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REQUEST:
      return { ...state, accountPaymentRequest: action.payload };
    case SET_IS_NEXT_BUTTON_DISABLED:
      return { ...state, isNextButtonDisabled: action.payload };
    case SET_DATES:
      return { ...state, paymentDates: action.payload };
    case SET_PLAN_ID:
      return { ...state, planId: action.payload };
    default:
      return state;
  }
};

export const timePeriods = [
  {
    label: "8:00 AM",
    value: "8:00 AM"
  },
  {
    label: "9:00 AM",
    value: "9:00 AM"
  },
  {
    label: "9:30 AM",
    value: "9:30 AM"
  },
  {
    label: "10:00 AM",
    value: "10:00 AM"
  },
  {
    label: "10:30 AM",
    value: "10:30 AM"
  },
  {
    label: "11:00 AM",
    value: "11:00 AM"
  },
  {
    label: "11:30 AM",
    value: "11:30 AM"
  },
  {
    label: "12:00 PM",
    value: "12:00 PM"
  },
  {
    label: "12:30 PM",
    value: "12:30 PM"
  },
  {
    label: "1:00 PM",
    value: "1:00 PM"
  },
  {
    label: "1:30 PM",
    value: "1:30 PM"
  },
  {
    label: "2:00 PM",
    value: "2:00 PM"
  },
  {
    label: "2:30 PM",
    value: "2:30 PM"
  },
  {
    label: "3:00 PM",
    value: "3:00 PM"
  },
  {
    label: "3:30 PM",
    value: "3:30 PM"
  },
  {
    label: "4:00 PM",
    value: "4:00 PM"
  },
  {
    label: "4:30 PM",
    value: "4:30 PM"
  },
  {
    label: "5:00 PM",
    value: "5:00 PM"
  },
  {
    label: "5:30 PM",
    value: "5:30 PM"
  },
  {
    label: "6:30 PM",
    value: "6:30 PM"
  },
  {
    label: "7:00 PM",
    value: "7:00 PM"
  },
  {
    label: "7:30 PM",
    value: "7:30 PM"
  },
  {
    label: "8:00 PM",
    value: "8:00 PM"
  },
  {
    label: "8:30 PM",
    value: "8:30 PM"
  },
  {
    label: "9:00 PM",
    value: "9:00 PM"
  },
]

export const timezonePeriods = [
  {
    label: "Atlantic Time (AST)",
    value: "AST"
  },
  {
    label: "Eastern Time (EST)",
    value: "EST"
  },
  {
    label: "Central Time (CST)",
    value: "CST"
  },
  {
    label: "Mountain Time (MST)",
    value: "MST"
  },
  {
    label: "Pacific Time (PST)",
    value: "PST"
  },
  {
    label: "Alaskan Time (AKST)",
    value: "AKST"
  },
  {
    label: "Hawaii-Aleutian Time (HST)",
    value: "HST"
  },
]
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment";

import "./style.scss";
import { SystemInfoIcon16 } from "../../../../components/icons/SystemIcons";
import { DebtStartFinishDates } from "../../../../components/DebtCustomCard/DebtStartFinishDates";
import {
  setIsNextButtonDisabled,
  setPaymentDates,
} from "../../../../redux/actions/setupPlan";
import { days } from "./daysData";

export const PaymentDate = ({
  startDay,
  setStartDay,
  paymentsCount,
  setIsDateInvalid,
  paymentType,
}) => {
  const dispatch = useDispatch();

  const todaysDate = new Date();
  const tklServerDay = moment().utcOffset(-300).format("DD");
  const localMachineDay = moment(todaysDate).format("DD");

  const [dates, setDates] = useState({
    start_date: calculateInitialStartDate(),
    finish_date: calculateInitialFinishDate(),
  });

  const [isLastDay, setIsLastDay] = useState(false);

  useEffect(() => {
    dispatch(setIsNextButtonDisabled(false));
    setIsDateInvalid(false);
    !startDay && setIsDateInvalid(true);
    dispatch(
      setPaymentDates({
        start_date: calculateInitialStartDate(),
        finish_date: calculateInitialFinishDate(),
      })
    );

    // eslint-disable-next-line
  }, []);

  // Calculates start date depending on payment type (paymentType)
  function calculateInitialStartDate() {
    // Monthly and One Time (best discount) payments
    if (paymentType === "monthly" || paymentType === "one time") {
      if (moment().date() >= 28) {
        return moment().date(1).add(1, "months").format("ll");
      } else {
        if (tklServerDay !== localMachineDay) {
          return moment().add(2, "days").format("ll");
        } else {
          return moment().add(1, "days").format("ll");
        }
      }
      // Weekly and Biweekly payments
    } else if (paymentType === "weekly" || paymentType === "bi-weekly") {
      return moment().add(1, "days").format("ll");
    }
  }

  // Calculates finish date depending on payment type (paymentType)
  function calculateInitialFinishDate() {
    if (paymentType === "monthly" || paymentType === "one time") {
      if (moment().date() >= 28) {
        if (paymentsCount === 1) {
          // return paymentType === "one time"
          //   ? moment().add(1, "days").format("ll")
          //   : moment()
          //       .add(paymentsCount - 1, "months")
          //       .format("ll");
          return moment().date(1).add(1, "months").format("ll");
        } else {
          return moment().date(1).add(paymentsCount, "months").format("ll");
        }
      } else {
        if (tklServerDay !== localMachineDay) {
          return (
            (paymentsCount > 1 &&
              moment()
                .add(2, "days")
                .add(paymentsCount - 1, "months")
                .format("ll")) ||
            (paymentsCount === 1 && moment().add(2, "days").format("ll"))
          );
        } else {
          return (
            (paymentsCount > 1 &&
              moment()
                .add(1, "days")
                .add(paymentsCount - 1, "months")
                .format("ll")) ||
            (paymentsCount === 1 && moment().add(1, "days").format("ll"))
          );
        }
      }
    } else if (paymentType === "weekly") {
      return moment()
        .add((paymentsCount - 1) * 7 + 1, "days")
        .format("ll");
    } else if (paymentType === "bi-weekly") {
      return moment()
        .add(1, "days")
        .add((paymentsCount - 1) * 14, "days")
        .format("ll");
    }
  }

  const handleChangeStartDay = (e) => {
    let selectedStartDay;
    if (e.target.value === "" || e.target.value === "0") {
      selectedStartDay = 1;
    } else {
      selectedStartDay = +e.target.value;
    }
    const dayOfTheWeek = moment().isoWeekday();
    const dayOfTheMonth = moment().date();

    if (paymentType === "weekly") {
      setIsDateInvalid(false);
      if (+e.target.value - moment().isoWeekday() < 0) {
        setStartDay(7 + +e.target.value - moment().isoWeekday());
      } else if (+e.target.value - moment().isoWeekday() === 0) {
        setStartDay(7);
      } else {
        setStartDay(+e.target.value - moment().isoWeekday());
      }
      if (selectedStartDay === dayOfTheWeek) {
        updatePaymentDates(dayOfTheMonth + 7);
      } else if (selectedStartDay > dayOfTheWeek) {
        updatePaymentDates(dayOfTheMonth + selectedStartDay - dayOfTheWeek);
      } else {
        updatePaymentDates(dayOfTheMonth + selectedStartDay + 7 - dayOfTheWeek);
      }
      return;
    } else if (paymentType === "bi-weekly") {
      setIsDateInvalid(false);
      if (+e.target.value - moment().isoWeekday() < 0) {
        setStartDay(7 + +e.target.value - moment().isoWeekday());
      } else if (+e.target.value - moment().isoWeekday() === 0) {
        setStartDay(7);
      } else {
        setStartDay(+e.target.value - moment().isoWeekday());
      }
      if (selectedStartDay === dayOfTheWeek) {
        updatePaymentDates(dayOfTheMonth + 7);
      } else if (selectedStartDay > dayOfTheWeek) {
        updatePaymentDates(dayOfTheMonth + selectedStartDay - dayOfTheWeek);
      } else {
        updatePaymentDates(dayOfTheMonth + selectedStartDay + 7 - dayOfTheWeek);
      }
      return;
    } else if (paymentType === "monthly" || paymentType === "one time") {
      if (e.target.value > 28) {
        setIsDateInvalid(false);
        setStartDay(28);
        updatePaymentDates(28);
        return;
      } else if (e.target.value === "0") {
        setIsDateInvalid(false);
        setStartDay(28);
        updatePaymentDates(28);
        return;
        // Only for February:
      } else if (isNaN(e.target.value)) {
        return;
      } else if (e.target.value === "") {
        setIsDateInvalid(true);
        setStartDay(e.target.value.trim());
      } else if (e.target.value === " ") {
        return;
      } else {
        setIsDateInvalid(false);
        setStartDay(e.target.value.trim());
      }
      updatePaymentDates(e.target.value.trim());
    }
  };

  const updatePaymentDates = (date) => {
    const day = moment().date();
    if (paymentType === "monthly" || paymentType === "one time") {
      if (date > +day) {
        if (Number(date) === day + 1 && tklServerDay !== localMachineDay) {
          // START add 2 days if tkl server date not equal local date.
          setIsLastDay(true);
          dispatch(setIsNextButtonDisabled(true));

          setDates({
            ...dates,
            start_date: moment().date(date).add(1, "d").format("ll"),
            finish_date: moment()
              .date(date)
              .add(1, "d")
              .add(paymentsCount - 1, "months")
              .format("ll"),
          });
          dispatch(
            setPaymentDates({
              start_date: moment().date(date).add(1, "d").format("ll"),
              finish_date: moment()
                .date(date)
                .add(1, "d")
                .add(paymentsCount - 1, "months")
                .format("ll"),
            })
          );
          // FINISH add 2 days if tkl server date not equal local date.
        } else {

          setIsLastDay(false);
          dispatch(setIsNextButtonDisabled(false));
          setDates({
            ...dates,
            start_date: moment().date(date).format("ll"),
            finish_date:
              paymentsCount === 1
                ? moment().date(date).format("ll")
                : moment()
                    .date(date)
                    .add(paymentsCount - 1, "months")
                    .format("ll"),
          });
          dispatch(
            setPaymentDates({
              start_date: moment().date(date).format("ll"),
              finish_date:
                paymentsCount === 1
                  ? moment().date(date).format("ll")
                  : moment()
                      .date(date)
                      .add(paymentsCount - 1, "months")
                      .format("ll"),
            })
          );

        }
      } else {

        if (Number(date) === todaysDate.getDate() && Number(date) < 28) {

          // const tklServerDay = moment().utcOffset(-300).format("DD");
          // const localMachineDay = moment(todaysDate).format("DD");

          // START add 2 days if tkl server date not equal local date.
          if (tklServerDay !== localMachineDay) {
            
            setIsLastDay(true);
            dispatch(setIsNextButtonDisabled(true));
  
            setDates({
              ...dates,
              start_date: moment().date(date).add(2, "d").format("ll"),
              finish_date: moment()
                .date(date)
                .add(2, "d")
                .add(paymentsCount - 1, "months")
                .format("ll"),
            });
            dispatch(
              setPaymentDates({
                start_date: moment().date(date).add(2, "d").format("ll"),
                finish_date: moment()
                  .date(date)
                  .add(2, "d")
                  .add(paymentsCount - 1, "months")
                  .format("ll"),
              })
            );
            // FINISH add 2 days if tkl server date not equal local date.
          } else {

            setIsLastDay(true);
            dispatch(setIsNextButtonDisabled(true));
  
            setDates({
              ...dates,
              start_date: moment().date(date).add(1, "d").format("ll"),
              finish_date: moment()
                .date(date)
                .add(1, "d")
                .add(paymentsCount - 1, "months")
                .format("ll"),
            });
            dispatch(
              setPaymentDates({
                start_date: moment().date(date).add(1, "d").format("ll"),
                finish_date: moment()
                  .date(date)
                  .add(1, "d")
                  .add(paymentsCount - 1, "months")
                  .format("ll"),
              })
            );
          }

        } else if (Number(date) === todaysDate.getDate() && Number(date) === 28) {

          setIsLastDay(true);
          dispatch(setIsNextButtonDisabled(true));

          setDates({
            ...dates,
            start_date: moment().date(1).add(1, "months").format("ll"),
            finish_date: moment().date(1).add(paymentsCount, "months").format("ll"),
          });
          dispatch(
            setPaymentDates({
              start_date: moment().date(1).add(1, "months").format("ll"),
              finish_date: moment().date(1).add(paymentsCount, "months").format("ll"),
            })
          );
        } else {
          setIsLastDay(false);
          dispatch(setIsNextButtonDisabled(false));

          setDates({
            ...dates,
            start_date: moment().date(date).add(1, "months").format("ll"),
            finish_date: moment()
              .date(date)
              .add(paymentsCount, "months")
              .format("ll"),
          });
          dispatch(
            setPaymentDates({
              start_date: moment().date(date).add(1, "months").format("ll"),
              finish_date: moment()
                .date(date)
                .add(paymentsCount, "months")
                .format("ll"),
            })
          );
        }
      }
    } else if (paymentType === "weekly") {
      const todaysDate = new Date();
      if (date === todaysDate.getDate() + 7) {
        setIsLastDay(true);
        dispatch(setIsNextButtonDisabled(true));
      } else {
        setIsLastDay(false);
        dispatch(setIsNextButtonDisabled(false));
      }
      const startDate = moment().date(date).format("ll");
      const finishDate =
        paymentsCount === 1
          ? moment().date(date).format("ll")
          : moment()
              .date(date)
              .add((paymentsCount - 1) * 7, "days")
              .format("ll");
      setDates({
        ...dates,
        start_date: startDate,
        finish_date: finishDate,
      });
      dispatch(
        setPaymentDates({
          start_date: startDate,
          finish_date: finishDate,
        })
      );
    } else if (paymentType === "bi-weekly") {
      const todaysDate = new Date();
      if (date === todaysDate.getDate() + 7) {
        setIsLastDay(true);
        dispatch(setIsNextButtonDisabled(true));
      } else {
        setIsLastDay(false);
        dispatch(setIsNextButtonDisabled(false));
      }
      const startDate = moment().date(date).format("ll");
      const finishDate =
        paymentsCount === 1
          ? moment().date(date).format("ll")
          : moment()
              .date(date)
              .add((paymentsCount - 1) * 14, "days")
              .format("ll");
      setDates({
        ...dates,
        start_date: startDate,
        finish_date: finishDate,
      });
      dispatch(
        setPaymentDates({
          start_date: startDate,
          finish_date: finishDate,
        })
      );
    }
  };

  const handleAgreeChange = (e) => {
    if (e.target.checked) {
      dispatch(setIsNextButtonDisabled(false));
    } else {
      dispatch(setIsNextButtonDisabled(true));
    }
  };

  const paymentHeader = () => {
    return (
      (paymentType === "weekly" && (
        <div>
          <p className="heading heading-body-medium">
            Let’s select a day to start a payment plan:
          </p>
        </div>
      )) ||
      ((paymentType === "monthly" || paymentType === "one time") && (
        <p className="heading heading-body-medium">
          On the{" "}
          <input
            type="text"
            value={startDay}
            onChange={handleChangeStartDay}
            className="input input-medium"
          />{" "}
          day of the month
        </p>
      ))
    );
  };

  return (
    <div className="payment-date">
      <h2 className="heading heading-2">Payment Date</h2>
      {paymentHeader()}
      {(paymentType === "weekly" || paymentType === "bi-weekly") && (
        <select
          name="day-select"
          id="day-select"
          className="form-control mb-3"
          onChange={handleChangeStartDay}
          defaultValue={moment().add(1, "days").isoWeekday()}
        >
          {days.map((item) => (
            <option value={item.value} key={item.value}>
              {item.day}
            </option>
          ))}
        </select>
      )}
      {(paymentType === "monthly" || paymentType === "one time") && (
        <div className="payment-date__message">
          <SystemInfoIcon16 />{" "}
          <span className="heading heading-caption-bold">
            Please select a payment day between 1 and 28
          </span>
        </div>
      )}
      <DebtStartFinishDates data={dates} />
      {isLastDay ? (
        <label className="warning-text">
          <input type="checkbox" onChange={handleAgreeChange} />{" "}
          <span>
            I understand that my first payment will not be 
            processed until the next day, {dates.start_date}.
          </span>
        </label>
      ) : null}
    </div>
  );
};

PaymentDate.propTypes = {
  startDay: PropTypes.number,
  setStartDay: PropTypes.func,
  paymentsCount: PropTypes.number,
  setIsDateInvalid: PropTypes.func,
  paymentType: PropTypes.string,
};

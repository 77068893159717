import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Close } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";

import { fetchSavedDebitCards, fetchSavedBankAccounts } from "redux/actions/consumerSavedPaymentMethod";
import { fetchDefaultPaymentMethod } from "redux/actions/getDefaultPaymentMethod";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";

import { formPaymentIframe, addPaymentCard } from "../../../../lib/api/common";

import { useTranslation } from 'react-i18next';

import "./style.scss";
import { wtError } from "lib/helpers/dataLayers";

let count = 0;

export const AddDebitCardModal = ({modal, toggle, selectedDataId}) => {

  const dispatch = useDispatch();

  const { t } = useTranslation();
  
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [repayURL, setRepayURL] = useState(null);

  const singleBalance = useSelector((state) => state.singleBalance.balanceData);
  const [authMethod, setAuthMethod] = useState("web");

  useEffect(() => {
    const fetchAddCard = async () => {

      let data;

      if (id) {
        data = await formPaymentIframe({
          client_id: id,
          debt_id: singleBalance?.debts_info[0].id,
          data_id: selectedDataId
        });
      } else {
        data = await formPaymentIframe({ 
          debt_id: singleBalance?.debts_info[0].id,
          data_id: selectedDataId
        });
      }

      // const data = await formPaymentIframe({ 
      //   debt_id: singleBalance?.debts_info[0].id,
      // });
      setRepayURL(data.url);
      setLoading(false);
    };
    
    if (modal) {
      fetchAddCard();

      window.addEventListener("message", AddNewDebitReceiveMessage, false);
    }
  }, [modal]);
  // catch iframe authorize click

  async function AddNewDebitReceiveMessage(event) {
    if (count > 0) return;

    if (event.data === "PAYMENT_SUCCESS") { 
      count++;
      addPaymentCardToConsumer();
    }
  }

  const closeModal = () => {
    dispatch(fetchSavedBankAccounts(id, selectedDataId));
    dispatch(fetchSavedDebitCards(id, selectedDataId));
    dispatch(fetchDefaultPaymentMethod(id, selectedDataId));

    toggle();
  }

  const addPaymentCardToConsumer = async () => {
    setLoading(true);
    // const cardData = await addPaymentCard({authorization: "web"});

    let cardData;
    if (id) {
      await new Promise((r) => setTimeout(r, 4000));
      cardData = await addPaymentCard({ client_id: id, authorization: authMethod, data_id: selectedDataId });
    } else {
      await new Promise((r) => setTimeout(r, 4000));
      cardData = await addPaymentCard({ authorization: authMethod, data_id: selectedDataId });
    }
    
    if (cardData.success === "True") {
      closeModal();
    } else {
      setLoading(false);
      wtError(cardData);
      alert.error(cardData.message)
    }
  };

  const setAuthMethodFunc = (e) => {
    setAuthMethod(e.target.value);
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-payment-modal"
      >
        <ModalBody>
          <div className="mod-header">
            <span>{t("Add Card")}</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          {!loading && id ? (
          <div className="authorization-selector">
            <label
              className="heading-caption-bold"
              htmlFor="account_type"
            >
              Authorization Method
            </label>
            <Select
              labelId="authorization"
              id="authorization"
              name="authorization"
              onChange={setAuthMethodFunc}
              input={<InputBase value={authMethod} className="input input-medium" />}
            >
              <MenuItem value={"web"}>Web</MenuItem>
              <MenuItem value={"verbal"}>Verbal</MenuItem>
              <MenuItem value={"written"}>Written</MenuItem>
            </Select>
          </div>
        ) : null}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <iframe id="iframe-modal" src={repayURL} title="Add Card" frameBorder="0"/>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

AddDebitCardModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
}
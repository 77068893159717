import React from "react";

import PropTypes from "prop-types";

export const TableShowRows = ({ showPerPage, handleShowPerPage }) => {
  const handlePerPageChange = (e) => {
    handleShowPerPage(e.target.value);
  };
  return (
    <div className="table-show-rows">
      <label htmlFor="">Show</label>
      <select
        onChange={handlePerPageChange}
        defaultValue={showPerPage || 10}
        name="show-rows"
        id="show-rows"
        className="form-control"
      >
        <option value="10">10 rows</option>
        <option value="25">25 rows</option>
        <option value="50">50 rows</option>
      </select>
    </div>
  );
};

TableShowRows.propTypes = {
  showPerPage: PropTypes.number,
  handleShowPerPage: PropTypes.func,
};

import { FETCH_DEFAULT_PAYMENT_METHOD,  HIDE_LOADER, SHOW_LOADER } from "../types";

const initialState = {
  loading: false,
  data: null,
}

export const getDefaultPaymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case FETCH_DEFAULT_PAYMENT_METHOD:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert";

import { JudgmentBlock } from "NewAdmin/ConsumerDebtsContainer/components/ExternalDebtContainer/JudgmentBlock";
import { SummaryBlock } from "NewAdmin/ConsumerDebtsContainer/components/ExternalDebtContainer/SummaryBlock";
import { DetailsBlock } from "NewAdmin/ConsumerDebtsContainer/components/ExternalDebtContainer/DetailsBlock";

import { getExternalDebtInformation } from "lib/api/admin";

export const ExternalDebtContainer = ({debt}) => {

  const [externalDebtInfo, setExternalDebtInfo] = useState();

  const alert = useAlert();

  useEffect(() => {
    fetchExternalDebtInformation();
  }, []);

  const fetchExternalDebtInformation = async() => {
    const data = await getExternalDebtInformation(debt.referenceNumber);

    if (data.success === "True") {
      setExternalDebtInfo(data.data);
    } else {
      alert.error(`Error: ${data.message}`);
    }
  }

  return (
    <>
      <JudgmentBlock externalDebtInfo={externalDebtInfo}/>
      <SummaryBlock externalDebtInfo={externalDebtInfo}/>
      <DetailsBlock externalDebtInfo={externalDebtInfo}/>
    </>
  )
}
import React from "react";
import { Modal, ModalBody } from "reactstrap";

import "./style.scss";

import successIcon from "../../../../../scss/images/success-icon.png";

import { useTranslation } from 'react-i18next';

export const ChangePaymentSuccess = ({modal, parentModal, parentToggle,}) => {

  const { t } = useTranslation();

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
    setTimeout(redirectToHome, 3000)
  }

  const redirectToHome = () => {
    window.location.reload(false);
  } 

  return (
    <Modal
      isOpen={modal}
      onOpened={closeParentModal}
      className="change-payment-success"
    >
      <ModalBody>
        <div className="success-paym-container">
          <img className="success-icon" src={successIcon} alt="success"/>
          <div className="text">
            {t("Payment method has been changed")}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import { TablePagination } from "./components/TablePagination";
import { Table } from "./components/Table";
import { LoadingSpinner } from "../LoadingSpinner";

export const TableContainer = ({
  tableControls,
  tableHeaders,
  data,
  currentPage,
  setCurrentPage,
  deleteNote,
  unlockProfile,
  pagesCount,
  tableType,
  type
}) => {
  // TODO: update, when back-end pagination is completed
  const isShowingPagintaion =
    data && (data.length >= 10 || pagesCount > 1 ? true : false);
  if (data)
    return (
      <div className="table-container">
        {tableControls}
        <Table 
          tableType={tableType} 
          headers={tableHeaders}
          deleteNote={deleteNote}
          unlockProfile={unlockProfile}
          data={data} 
          type={type}
        />
        {isShowingPagintaion && (
          <TablePagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagesCount={pagesCount}
          />
        )}
      </div>
    );

  return <LoadingSpinner middleFixed={true}/>;
};

TableContainer.propTypes = {
  tableControls: PropTypes.object,
  tableHeaders: PropTypes.array,
  data: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  deleteNote: PropTypes.func,
  unlockProfile: PropTypes.func,
  pagesCount: PropTypes.any,
  tableType: PropTypes.string,
};

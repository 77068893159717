import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { useAlert } from "react-alert";

import "./style.scss";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { getDemandLetter } from "../../lib/api/admin";
import { useSelector } from "react-redux";

export const DemandLetters = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState(null);
  const [dataId, setDataId] = useState("");
  const [error, setError] = useState(null);
  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const handleSubmit = async (e) => {
    setError(null);
    e.preventDefault();
    setLoading(true);
    const demandLetterData = await getDemandLetter({ data_id: dataId });

    if (demandLetterData.success !== "True") {
      alert.error(demandLetterData.message);
      setError(demandLetterData.message);
      setLoading(false);
      return;
    }

    setHtml(demandLetterData.content);

    setLoading(false);
  };

  return (
    <div className="default-container demand-letters">
      <Helmet>
        <title>Demand Letters - {multitenancyCompanyShortName}</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner middleFixed={true}/>
      ) : (
        <div>
          <div className="demand-letters__form-wrapper">
            <h2>Demand Letters</h2>
            <form onSubmit={handleSubmit}>
              <input
                className="form-control"
                type="text"
                name="data_id"
                id="data_id"
                value={dataId}
                onChange={(e) => setDataId(e.target.value)}
                placeholder="Enter data_id, and press enter"
              />
            </form>
            {error ? <h4>{error}</h4> : null}
          </div>

          {html ? (
            <div
              className="demand-letters__body"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          ) : null}
        </div>
      )}
    </div>
  );
};

import React from "react";
import PropTypes from "prop-types";

import { SystemArrowLeftIcon16 } from "../../components/icons/SystemIcons";
import { CustomButton } from "../../components/ui-kit/CustomButtom";

export const StepTwo = ({
  handleGoBack,
  error,
  verificationCode,
  handleVerificationCodeChange,
  handleVerification,
  isButtonDisabled,
}) => {
  return (
    <div>
      <button
        className="button button-transparent button-back"
        onClick={() => handleGoBack()}
      >
        <SystemArrowLeftIcon16 />
        Back
      </button>
      <form>
        <div className="form-group">
          <label htmlFor="code" className="heading heading-caption-bold">
            Code
          </label>
          <input
            className={
              error ? "input input-large input-has-error" : "input input-large"
            }
            type="text"
            name="code"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
          />
        </div>
        <div className={"error heading heading-caption-bold error--bottom"}>
          {error}
        </div>
        <CustomButton
          text="Verify"
          size="large"
          onClickHandler={(e) => handleVerification(e)}
          disabled={isButtonDisabled}
        />
      </form>
    </div>
  );
};

StepTwo.propTypes = {
  handleGoBack: PropTypes.func,
  error: PropTypes.string,
  verificationCode: PropTypes.string,
  handleVerificationCodeChange: PropTypes.func,
  handleVerification: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
};

import {
  FETCH_SUBSCRIPTION_STATUSES,
  HIDE_SUBSCRIPTION_LOADING,
  SHOW_SUBSCRIPTION_LOADING,
} from "../types";

const initialState = {
  loading: true,
  subscriptionStatuses: { phone_subscription: true, email_subscription: true },
};

export const consumerSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_STATUSES:
      return { ...state, subscriptionStatuses: action.payload };
    case SHOW_SUBSCRIPTION_LOADING:
      return { ...state, loading: true };
    case HIDE_SUBSCRIPTION_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};

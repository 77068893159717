import {
  SET_USERNAME,
  SET_PROFILE_COMPLETE,
  SET_PROFILE_INCOMPLETE,
} from "../types";

const initialState = {
  userName: "",
  isProfileComplete: true,
};

export const topNavReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERNAME:
      return { ...state, userName: action.payload };
    case SET_PROFILE_COMPLETE:
      return { ...state, isProfileComplete: true };
    case SET_PROFILE_INCOMPLETE:
      return { ...state, isProfileComplete: false };
    default:
      return state;
  }
};

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useAlert } from "react-alert";

import { ConsumerFooter } from "../ConsumerFooter";
import { CustomButton } from "components/ui-kit/CustomButtom";

import { verifyEsingLetter } from "lib/api/common";

import "./style.scss";
import { wtError } from "lib/helpers/dataLayers";

// Get query for setting consumer data in form
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const EsignPage = ({page}) => {
  const alert = useAlert();
  const { t } = useTranslation();

  let query = useQuery();
  const client_id = query.get("client_id");

  useEffect(() => {
    const sendEsingAuth = async () => {
      const response = await verifyEsingLetter({client_id: client_id});

      if (response.success === "False") { 
        wtError(response);
        alert.error(`Error: ${response.message}`);
        setTimeout(() => {
          window.location.replace("/");
        }, 5000);
      }
    }

    if (client_id) {
      sendEsingAuth();
    }

  },[]);

  const linkTo = () => {
    if (page) {
      window.location.replace("/")
    } else {
      window.location.replace("/cabinet-active")
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("Electronic Signature Authorized")}</title>
      </Helmet>
      <div className="esign-body">
        <div className="title">{t("Electronic Signature Authorized")}</div>
        <div className="subtitle">{t("You have successfully authorized electronic communication.")}</div>
        <CustomButton
          text={t("Visit Your Account")}
          onClickHandler={() => linkTo()}
          optionalClass="action-btn"
        />
      </div>
      <ConsumerFooter page={page}/>
    </>
  )
}
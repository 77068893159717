import React from "react";

import PropTypes from "prop-types";

import { showPageRange } from "./helpers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

export const TableShowPages = ({
  currentPage,
  itemsAmount,
  showPerPage,
  setCurrentTablePage,
  pagination,
}) => {
  const handleNextPage = () => {
    setCurrentTablePage(+currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentTablePage(+currentPage - 1);
  };

  const isPrevPageButtonDisabled = () => {
    if (currentPage === 1) {
      return true;
    }

    return false;
  };

  const isNextPageButtonDisabled = () => {
    if (currentPage === pagination.page_range) {
      return true;
    }

    return false;
  };

  return (
    <div className="table-show-pages">
      <label>{showPageRange(currentPage, itemsAmount, showPerPage)}</label>
      <div>
        <button
          className="btn btn-outline-secondary"
          disabled={isPrevPageButtonDisabled()}
          onClick={() => handlePreviousPage()}
        >
          <KeyboardArrowLeft />
        </button>
        <button
          className="btn btn-outline-secondary show"
          onClick={() => handleNextPage()}
          disabled={isNextPageButtonDisabled()}
        >
          <KeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

TableShowPages.propTypes = {
  currentPage: PropTypes.number,
  itemsAmount: PropTypes.any,
  showPerPage: PropTypes.number,
  setCurrentTablePage: PropTypes.func,
  pagination: PropTypes.object,
};

import React from "react";
import {
  NavigationDebtsIcon,
  NavigationPaymentIcon,
  NavigationProfileIcon,
} from "../icons/NavigationIcons";

export const authenticatedUserNavItems = [
  {
    title: "Accounts",
    link: "/cabinet",
    icon: <NavigationDebtsIcon />,
  },
  {
    title: "Payment",
    link: "/payment",
    icon: <NavigationPaymentIcon />,
  },
  {
    title: "Profile",
    link: "/profile",
    icon: <NavigationProfileIcon />,
  },
];

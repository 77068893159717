import React from "react";

import "../style.scss";

export const SummaryBlock = ({externalDebtInfo}) => {

  return (
    <>
      <div className="customer-service-container">
        <div className="header-container">
          <div className="header">Summary</div>
        </div>
        <div className="body">
          <div className="header">Account Information</div>
          <div className="body-container">
            <div className="block-container">
              <span className="label">Data ID</span>
              <span className="value">{externalDebtInfo?.data_id ? externalDebtInfo.data_id : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Account Number</span>
              <span className="value">{externalDebtInfo?.pri_acctno ? externalDebtInfo.pri_acctno : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Source Account Number</span>
              <span className="value">{externalDebtInfo?.source_acctno ? externalDebtInfo.source_acctno : "-"}</span>
            </div>
          </div>

          <div className="header">Portfolio Summary</div>
          <div className="body-container">
            <div className="block-container">
              <span className="label">Portfolio | Code</span>
              <span className="value">{externalDebtInfo?.portfolio_code ? externalDebtInfo.portfolio_code : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Customer Line</span>
              <span className="value">{externalDebtInfo?.customer_line ? externalDebtInfo.customer_line : "-"}</span>
            </div>
            <div className="block-container"></div>
          </div>

          <div className="header">Status Summary</div>
          <div className="body-container">
            <div className="block-container">
              <span className="label">Description</span>
              <span className="value">{externalDebtInfo?.description1 ? externalDebtInfo.description1 : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Description</span>
              <span className="value">{externalDebtInfo?.description2 ? externalDebtInfo.description2 : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Company</span>
              <span className="value">{externalDebtInfo?.servicer_name ? externalDebtInfo.servicer_name : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Inbound Line</span>
              <span className="value">{externalDebtInfo?.inbound_line ? externalDebtInfo.inbound_line : "-"}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
import React from "react";
import ReactDOM from "react-dom";

import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Provider } from "react-redux";

import { store } from "./redux/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "./fonts/inter.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./i18n";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
  containerStyle: {
    fontSize: 12,
    zIndex: 100500
  },
};

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Rerun Deploy
serviceWorker.unregister();

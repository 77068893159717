import { isAdmin } from "../../../lib/api/admin";
import { getSavedBankAccounts, getSavedCards } from "../../../lib/api/common";

export function getSteps(sum) {
  if (sum) {
    return ["Review Plan", "Payment Method"];
  }
  return ["Review Plan", "Payment Date", "Payment Method"];
}

export const isStepSkipped = (step, skipped) => {
  return skipped.has(step);
};

export const fetchSavedCards = async (id, setSavedCards) => {
  let data;
  if (id) {
    data = await getSavedCards({ client_id: id });
  } else {
    data = await getSavedCards();
  }
  setSavedCards(data.data);
};

export const fetchSavedBankAccounts = async (id, setSavedBankAccounts) => {
  let data;
  if (isAdmin() === "admin" || isAdmin() === "agent") {
    data = await getSavedBankAccounts({ client_id: id });
  } else {
    data = await getSavedBankAccounts();
  }
  setSavedBankAccounts(data.data);
};

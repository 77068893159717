import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { adminLoginReducer } from "./adminLoginReducer";
import { forgotPasswordReducer } from "./forgotPasswordReducer";
import { setupPlanReducer } from "./setupPlanReducer";
import { consumerDebtsReducer } from "./consumerDebtsReducer";
import { balanceReducer } from "./balanceReducer";
import { consumerPaymentsReducer } from "./consumerPaymentsReducer";
import { consumerHistoryReducer } from "./consumerHistoryReducer";
import { consumerNotesReducer } from "./consumerNotesReducer";
import { topNavReducer } from "./topNavReducer";
import { profileReducer } from "./profileReducer";
import { changePasswordReducer } from "./changePasswordReducer";
import { consumerSubscriptionReducer } from "./consumerSubscriptionReducer";

import { singleBalanceReducer } from "./singleBalanceReducer";
import { newConsumerDebtsReducer } from "./newConsumerDebtsReducer";
// import { consumerPaymentHistoryReducer } from "./consumerPaymentHistoryReducer";
import { consumerSavedPaymentMethodReducer } from "./consumerSavedPaymentMethodReducer";
import { planInfoReducer } from "./planInfoReducer";
import { getDefaultPaymentMethodReducer } from "./getDefaultPaymentMethodReducer";

export const rootReducer = combineReducers({
  app: appReducer,
  adminLogin: adminLoginReducer,
  forgotPassword: forgotPasswordReducer,
  setupPlan: setupPlanReducer,
  consumerDebts: consumerDebtsReducer,
  balance: balanceReducer,
  consumerPayments: consumerPaymentsReducer,
  consumerHistory: consumerHistoryReducer,
  consumerNotes: consumerNotesReducer,
  topNav: topNavReducer,
  profile: profileReducer,
  changePassword: changePasswordReducer,
  consumerSubscription: consumerSubscriptionReducer,

  singleBalance: singleBalanceReducer,
  newConsumerDebts: newConsumerDebtsReducer,
  // consumerPaymentHistory: consumerPaymentHistoryReducer,
  consumerSavedPaymentMethod: consumerSavedPaymentMethodReducer,
  planInfo: planInfoReducer,
  getDefaultPaymentMethod: getDefaultPaymentMethodReducer
});

import React from "react";

import PropTypes from "prop-types";

export const InventoryForm = ({
  handleSubmit,
  handleFileChange,
  uploadType,
  handleSetUploadType,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="inventory-page__form-block">
        <label className="heading heading-caption-bold" htmlFor="type">
          File Type
        </label>
        <select name="file-type" id="file-type" className="form-control">
          <option value="">CSV</option>
        </select>
      </div>
      <div className="inventory-page__form-block">
        <label className="heading heading-caption-bold" htmlFor="type">
          Select a File
        </label>
        <input type="file" onChange={handleFileChange} />
      </div>
      <div className="inventory-page__form-block">
        <label className="heading heading-caption-bold" htmlFor="upload-type">
          Upload Type
        </label>
        <select
          name="upload-type"
          id="upload-type"
          onChange={handleSetUploadType}
          value={uploadType}
          className="form-control"
        >
          <option value="add">Add</option>
          <option value="update">Update</option>
        </select>
      </div>
    </form>
  );
};

InventoryForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleFileChange: PropTypes.func,
  uploadType: PropTypes.string,
  handleSetUploadType: PropTypes.func,
};

export const countPayments = (request) => {
  const countedPayments = [];
  for (let i = 0; i < 260; i++) {
    // Only for Bi-Weekly plans we remove first element of the array
    if (request.plan_type === "Bi-Weekly" && i === 0) {
      continue;
    }
    countedPayments.push({
      count: i + 1,
      amount: (request.tot_amt_to_pay / (i + 1)).toFixed(2),
    });
  }

  return countedPayments;
};

export const countOpenPlan = (request) => {
  const countedPayments = [{count: 1, amount: (request.tot_amt_to_pay / 1).toFixed(2)}];
  for (let i = 5; i < 24; i++) {
    // Only for Bi-Weekly plans we remove first element of the array
    if (request.plan_type === "Bi-Weekly" && i === 0) {
      continue;
    }
    countedPayments.push({
      count: i + 1,
      amount: (request.tot_amt_to_pay / (i + 1)).toFixed(2),
    });
  }

  return countedPayments;
}

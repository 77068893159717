export const asaStatuses = {
  INITIAL: "",
  UNSIGNED: "unsigned",
  COMPLETED_BY_CLIENT: "completed_by_client",
  DECLINED_BY_CLIENT: "declined_by_client",
  COMPLETED_BY_ATTORNEY: "completed_by_attorney",
  DECLINED_BY_ATTORNEY: "declined_by_attorney",
  ATTEMPTS_LIMIT_REACHED: "attempts_limit_reached",
  EXPIRED: "expired"
}


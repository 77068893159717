import {
  FETCH_PAYMENTS_DATA,
  HIDE_PAYMENTS_LOADER,
  SHOW_PAYMENTS_LOADER,
  SET_NACHA_DATA,
  SET_NACHA_MODAL_DATA,
  SET_PAYMENTS_ESTABLISHED_DEBTS,
} from "../types";
import moment from "moment";
moment.suppressDeprecationWarnings = true;

const initialState = {
  loading: true,
  paymentsData: { savedAccounts: [], savedCards: [] },
  nachaData: {},
  modalData: {
    name_on_check: "",
    type: "1",
    account_number: "",
    bank_name: "",
    payment_amount: "",
    payment_date: moment(Date()).format("ll"),
  },
  establishedDebts: [],
};

export const consumerPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PAYMENTS_LOADER:
      return { ...state, loading: true };
    case HIDE_PAYMENTS_LOADER:
      return { ...state, loading: false };
    case FETCH_PAYMENTS_DATA:
      return { ...state, paymentsData: action.payload };
    case SET_NACHA_DATA:
      return { ...state, nachaData: action.payload };
    case SET_NACHA_MODAL_DATA:
      return { ...state, modalData: action.payload };
    case SET_PAYMENTS_ESTABLISHED_DEBTS:
      return { ...state, establishedDebts: action.payload };
    default:
      return state;
  }
};

import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { accountNumberLastFour } from "lib/helpers/other";
import { ButtonLink } from "components/ui-kit/ButtonLink";
import { formattedMoney } from "lib/helpers/formatters";

export const AuthAgreementPhoneMultiPay = ({
  consumerName,
  accountNumber,
  accountType,
  selectedPaymentAccount,
  setIsShowingModal,
  isChangingPaymentMethod,
  startDate,
  endDate,
  consumerState,
  numberOfPayments,
  payment_frequency,
  start_amount,
  end_amount,
}) => {
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name
  );
  const phoneNumber = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours);

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const dayOfTheWeek = () => {
    if (payment_frequency === "Weekly") {
      return `${moment(startDate).format("dddd")} each week`;
    }
    if (payment_frequency === "Bi-Weekly") {
      return `${moment(startDate).format("dddd")} every other week`;
    }
    if (payment_frequency === "Monthly" || payment_frequency === "One Time") {
      return `${ordinal_suffix_of(
        moment(startDate).format("D")
      )} day of the month`;
    }
  };

  return (
    <div className="nacha-container">
      <div className="nacha-text">
        <p>
          As of today’s date,{" "}
          <span className="bold">{moment(Date()).format("MM/DD/YYYY")}</span>,
          you have given verbal authorization to debit your account. Please
          confirm your understanding with &quot;I agree&quot;.
        </p>
        <p>
          {!isChangingPaymentMethod && (
            <span>
              To confirm the payment details, {numberOfPayments}{" "}
              {numberOfPayments > 1 ? "payments" : "payment"} of{" "}
              <span className="bold">${formattedMoney(start_amount)}</span>{" "}
              scheduled on the {dayOfTheWeek()} beginning on{" "}
              <span className="bold">
                {moment(startDate).format("MM/DD/YYYY")}
              </span>{" "}
              and ending on{" "}
              <span className="bold">
                {moment(endDate).format("MM/DD/YYYY")}
              </span>{" "}
              will be debited for <span className="bold">{consumerName}</span>
              {accountType ? (
                <span>
                  {" "}
                  from the <span className="bold">{accountType}</span> account
                  number ending in{" "}
                  <span className="bold">
                    {accountNumberLastFour(
                      selectedPaymentAccount?.AccountNumber || accountNumber
                    )}
                    .
                  </span>{" "}
                </span>
              ) : (
                <span>. </span>
              )}
              The final payment of{" "}
              <span className="bold">${formattedMoney(end_amount)}</span> will
              be due on{" "}
              <span className="bold">
                {moment(endDate).format("MM/DD/YYYY")}
              </span>
              .
            </span>
          )}
        </p>
        <p>
          You will receive a confirmation notice with the terms of your payments
          and confirming your authorization.
        </p>
        {consumerState === "Rhode Island" ||
        consumerState === "Massachusetts" ? (
          <p>
            You are giving us verbal authorization to establish postdated
            payments on your account. InvestiNet does not require you to set up
            postdated payments and you have the option to use other methods to
            submit payments if you do not wish to schedule postdated payments.
          </p>
        ) : null}
        <p>
          If you have any questions or concerns, or would like to revoke this
          authorization, please give us a call at 1 {phoneNumber} between{" "}
          {hours}. I need to record your authorization to initiate the payment.
        </p>
        <p>
          {consumerName}, do you authorize {companyName} to initiate this
          payment in accordance with the terms I just stated and are you willing
          to authorize the payment over the phone instead of through paper mail?
        </p>
      </div>
      <ButtonLink
        text="Download NACHA Authorization Agreement"
        onClickHandler={() => setIsShowingModal(true)}
      />
    </div>
  );
};

AuthAgreementPhoneMultiPay.propTypes = {
  consumerName: PropTypes.string,
  accountNumber: PropTypes.string,
  accountType: PropTypes.string,
  selectedPaymentAccount: PropTypes.any,
  setIsShowingModal: PropTypes.func,
  isChangingPaymentMethod: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  consumerState: PropTypes.string,
  numberOfPayments: PropTypes.number,
  payment_frequency: PropTypes.string,
  start_amount: PropTypes.number,
  end_amount: PropTypes.number,
};

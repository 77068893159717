import React from "react";

import "../style.scss";

export const TabPanel = ({ children, value, index }) => {
  return (
    <div className="tab-body-container" role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
};
import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";

const MultitenancyLogoPicker = () => {
  const logo = useSelector((state) => state.app.tenantSettings?.logo);
  
  if (!logo) {
    return null; 
  }

  return <img className="logo-img" src={logo} alt="Tenant Logo" />;
};

export default MultitenancyLogoPicker;

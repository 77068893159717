import {
  FETCH_SINGLE_BALANCE
} from "../types";

import {
  getBalance,
} from "../../lib/api/admin";

export function fetchSingleBalance(consumerId) {
  return async(dispatch) => {
    const response = await getBalance({ client_id: consumerId });
    if (response.success === "True") {
      dispatch({ type: FETCH_SINGLE_BALANCE, payload: response})
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
  }
}
import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "./helpers/TabPanel";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import moment from "moment";

import { CustomButton } from "components/ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";
import { formattedMoney } from "lib/helpers/formatters";


import { 
  getShortConsumerDebts_new,
  getEmailTemplate,
  sendEmailTemplate,
  getNewAdminPaymentHistory
} from "lib/api/common";

import "./style.scss";

export const EmailTemplatesPage = () => {
  
  const alert = useAlert();

  const { id } = useParams();
  const [debtList, setDebtList] = useState({});
  const [historyList, setHistoryList] = useState({});
  const [templatesList, setTemplatesList] = useState([]);
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDebtId, setSelectedDebtId] = useState(false);
  const [value, setValue] = useState(0);
  const [tabId, setTabId] = useState(1);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setTabId(newValue + 1);
  };
  const [sendEmailBtnDisabled, setSendEmailBtnDisabled] = useState(false);

  useEffect(() => {
    const fetchAllDebts = async() => {
      setLoading(true);
      const response = await getShortConsumerDebts_new({
        statuses: ["active", "established", "recalled", "paid_off", "delinquent", "on_hold"],
        client_id: id
      });
      if (response.success === "True") {
        setDebtList(response.data);
        setEmail(response.data[0].email);
        // fetch initial template for first debt
        setSelectedDebtId(response.data[0].debt_id);
        fetchPaymentHistory(response.data[0].debt_id);
      }
    }
    fetchAllDebts();
  }, [])

  const fetchPaymentHistory = async(debt_id) => {
    setLoading(true);
    const data = await getNewAdminPaymentHistory({
      debt_id: debt_id,
      client_id: id
    });
    if (data.success === "True") {
      setHistoryList(data.data.page);
      // load email template on ref number changed
      fetchEmailTemplate(debt_id, data.data?.page[0]?.amount, data.data?.page[0]?.payment_date)
    }
  }

  const fetchEmailTemplate = async(debt_id, amount, date) => {
    setLoading(true);
    const response = await getEmailTemplate(debt_id, {
      pay_amount: amount,
      pay_date: moment(date).format("ll")
    })
    if (response.success === "True") {
      setTemplatesList(response.data);
      setLoading(false);
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const changeReferenceNumber = (e) => {
    fetchPaymentHistory(e.target.value);
    setSelectedDebtId(e.target.value);
  }

  const changeHistoryElement = (e) => {
    const selectedObject = JSON.parse(e.target.value);
    fetchEmailTemplate(selectedDebtId, selectedObject.amount, selectedObject.payment_date);
  }

  const goToSingleConsumerPage = () => {
    window.location.replace(`/admin/consumers/consumer/${id}`);
  }

  const markup = (data) => {
    return { __html: data };
  };

  const sendEmail = async() => {
    setLoading(false);
    setSendEmailBtnDisabled(true);

    const request = {
      recepient: email,
      client_id: id,
      debt_id: selectedDebtId,
      template_id: tabId,
    }

    const response = await sendEmailTemplate(request);
    if (response.success === "True") {
      setLoading(false);
      setSendEmailBtnDisabled(false);
      alert.error("Email Message sent successfully");
      goToSingleConsumerPage();
    } else {
      setLoading(false);
      setSendEmailBtnDisabled(false);
      alert.error(response.message);
    }
  }

  return (
    <div className="template-page-container">
      {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
      <div className="template-page-header">
        <div className="left-side">
          <div className="back-btn" onClick={() => goToSingleConsumerPage()}>
            <KeyboardArrowLeft />
            <span>back</span>
          </div>
          <span className="page-title">New Email</span>
        </div>
        <div className="right-side">
          <CustomButton 
            text="Send Email"
            onClickHandler={() => sendEmail()}
            disabled={sendEmailBtnDisabled}
          />
        </div>
      </div>
      <div className="template-page-body">
        <div className="template-list-container">
          <div className="tabs-list">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleTabChange}
              aria-label="Vertical tabs"
              className="consumer-tabs-container"
            >
              {templatesList?.map((item, i) => {
                return (
                  <Tab key={i} label={
                    <>
                      <div>{item.name}</div>
                    </>
                  }>
                  </Tab>
                )
              })}
          </Tabs>
          </div>
        {templatesList?.map((item, i) => {
          return (
            <TabPanel key={i} value={value} index={i}>
              <div className="email-block-container">
                <div className="input-container">
                  <div className="label">To</div>
                  <input
                    className="email-input"
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="or-block-container">
                  <hr></hr> 
                </div>
                <div className="selector-container">
                  <div className="input-container">
                    <div className="label">Reference Number</div>
                    <select
                      className="reference-number-select"
                      onChange={changeReferenceNumber}
                    >
                      {debtList.map((item, i) => {
                        return (
                          <option key={i} value={item.debt_id}>{item.referenceNumber}</option>
                        )
                      })}
                    </select>
                  </div>
                  {historyList.length > 0 && (
                    <div className="input-container history-cont">
                      <div className="label">Payments</div>
                      <select
                        className="reference-number-select"
                        onChange={changeHistoryElement}
                      >
                        {historyList.map((item, i) => {
                          return (
                            <option key={i} value={JSON.stringify(item)}>${formattedMoney(item.amount)} | {moment(item.payment_date).format("ll")} | {item.payment_frequency}</option>
                          )
                        })}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className="template-preview-container">
                <div className="template-preview">
                  <div dangerouslySetInnerHTML={markup(item.body)}></div>
                </div>
              </div>
            </TabPanel>
          )
        })}
        </div>
      </div>
    </div>
  )

}
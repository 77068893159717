import {
  FETCH_ACTIVE_DEBTS,
  FETCH_ESTABLISHED_DEBTS,
  FETCH_PAIDOFF_DEBTS,
  FETCH_ONHOLD_DEBTS,
  FETCH_PAYMENT_DEBTS,
  FETCH_RECALLED_DEBTS,
  FETCH_DELINQUENT_DEBTS,
} from "../types";

import {
  getConsumerDebts,
} from "../../lib/api/common";

export function fetchActiveDebts(balance, consumerId) {
  return async (dispatch) => {
    const response = await getConsumerDebts("active", {
      client_id: consumerId,
    });

    let debts = [];

    if (response.success === "True") {
      if (consumerId) {
        debts = [...response.data];
      } else {
        response.data.map((item) =>
          balance.map((data) => {
            if (data.id === item.debt_id) {
              debts.push({
                ...item,
                currentBalance: data.current_balance,
              });
            }
            return null;
          })
        );
      }

      dispatch({ type: FETCH_ACTIVE_DEBTS, payload: debts });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
  };
}

export function fetchRecalledDebts(consumerId) {
  return async (dispatch) => {
    const response = await getConsumerDebts("recalled", {
      client_id: consumerId,
    });
    dispatch({ type: FETCH_RECALLED_DEBTS, payload: response.data });
  };
}

export function fetchDelinquentDebts(consumerId) {
  return async (dispatch) => {
    const response = await getConsumerDebts("delinquent", {
      client_id: consumerId,
    });
    dispatch({ type: FETCH_DELINQUENT_DEBTS, payload: response.data });
  };
}

export function fetchPaymentDebts() {
  return async (dispatch) => {
    const response = await getConsumerDebts("payment");
    dispatch({ type: FETCH_PAYMENT_DEBTS, payload: response.data });
  };
}

export function fetchPaidoffDebts(consumerId) {
  return async (dispatch) => {
    const response = await getConsumerDebts("paid_off", {
      client_id: consumerId,
    });

    let updatedPaidOffDebts = [];
    if (!consumerId) {
      response.data.map((item) =>
        updatedPaidOffDebts.push({ ...item, currentBalance: 0 })
      );
    } else {
      updatedPaidOffDebts = response.data;
    }
    dispatch({ type: FETCH_PAIDOFF_DEBTS, payload: updatedPaidOffDebts });
  };
}

export function fetchOnHoldDebts(consumerId) {
  return async (dispatch) => {
    const response = await getConsumerDebts("on_hold", {
      client_id: consumerId,
    });

    let updatedOnHoldDebts = [];
    if (!consumerId) {
      response.data.map((item) =>
      updatedOnHoldDebts.push({ ...item, currentBalance: 0 })
      );
    } else {
      updatedOnHoldDebts = response.data;
    }
    dispatch({ type: FETCH_ONHOLD_DEBTS, payload: updatedOnHoldDebts });
  };
}

export function fetchEstablishedDebts(balance, consumerId) {
  return async (dispatch) => {
    const response = await getConsumerDebts("established", {
      client_id: consumerId,
    });
    let debts = [];
    if (response.success === "True") {
      if (consumerId) {
        debts = [...response.data];
      } else {
        response.data.map((item) =>
          balance.map((data) => {
            if (data.id === item.debt_id) {
              debts.push({ ...item, currentBalance: data.current_balance });
            }
            return null;
          })
        );
      }

      dispatch({ type: FETCH_ESTABLISHED_DEBTS, payload: debts });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
  };
}
import {
  FETCH_SAVED_PAYPAL,
  FETCH_SAVED_DEBIT_CARDS,
  FETCH_SAVED_BANK_ACCOUNT,
} from "../types";

import { getSavedPayPal, getSavedCards, getSavedBankAccounts } from "../../lib/api/common";
import { showLoader, hideLoader } from "./app";

export function fetchSavedPayPal(id, data_id) {
  return async(dispatch) => {
    dispatch(showLoader());
    let response;
    if (id) {
      response = await getSavedPayPal({client_id: id, data_id: data_id});
    } else {
      response = await getSavedPayPal({data_id: data_id});
    }

    if (response.success === "True") {
      dispatch({type: FETCH_SAVED_PAYPAL, payload: response.data })
      dispatch(hideLoader());
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
      dispatch(hideLoader());
    }
  }
}

export function fetchSavedDebitCards(id, data_id) {
  return async(dispatch) => {
    dispatch(showLoader());
    let response;
    if (id) {
      response = await getSavedCards({client_id: id, data_id: data_id});
    } else {
      response = await getSavedCards({data_id: data_id});
    }

    if (response.success === "True") {
      dispatch({type: FETCH_SAVED_DEBIT_CARDS, payload: response.data })
      dispatch(hideLoader());
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
      dispatch(hideLoader());
    }
  }
}

export function fetchSavedBankAccounts(id, data_id) {
  return async(dispatch) => {
    dispatch(showLoader());
    let response;
    if (id) {
      response = await getSavedBankAccounts({client_id: id, data_id: data_id});
    } else {
      response = await getSavedBankAccounts({data_id: data_id});
    }
    if (response.success === "True") {
      dispatch({type: FETCH_SAVED_BANK_ACCOUNT, payload: response.data});
      dispatch(hideLoader());
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
      dispatch(hideLoader());
    }
  }
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { formattedMoney } from "../../lib/helpers/formatters";
import { InfoTooltip } from "../InfoTooltip";

import { getCommCount } from "lib/api/common";

export const SingleDebt = ({ data, debtStatus, successSum }) => {
  const standardClient = data.display_id === 1 ? true : false;
  const [isShowingMore, setIsShowingMore] = useState(false);

  const [commCount, setCommCount] = useState();

  const { id } = useParams();

  const handleShowMoreClick = () => {
    isShowingMore ? setIsShowingMore(false) : setIsShowingMore(true);
  };

  useEffect(() => {
    getCommValue();
  }, []);

  const getCommValue = async() => {
    const response = await getCommCount(data.referenceNumber);
    if (response.success === "True") {
      setCommCount(response.comm_count);
    }
  }

  const formattedDate = (date) => {
    if (!date) return;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateArray = date.split("-").reverse();
    return `${months[Number(dateArray[1]) - 1]} ${dateArray[0]}, ${
      dateArray[2]
    }`;
  };

  const formattedNumber = (number) => {
    if (!number && number !== 0) return 0;
    return parseFloat(number).toFixed(2);
  };

  const returnStatusName = (name) => {
    if (name === "paid_off") {
      return "paid off";
    } else if (name === "on_hold") {
      return "on hold";
    } else {
      return name;
    }
  }
  
  return (
    <div
      className={
        isShowingMore
          ? "debt-custom-card show"
          : "debt-custom-card debt-custom-card--single-debt"
      }
    >
      <div className="debt-custom-card__header">
        <div>
          <h5>{id ? "SOR Balance" : "Current Balance"}</h5>
          <p>
            ${" "}
            {(debtStatus === "recalled" && !id && "0") ||
              (successSum && formattedMoney(successSum)) ||
              (data.currentBalance && formattedMoney(data.currentBalance))}
          </p>
        </div>
        {debtStatus && id && (
          <div>
            <h5>Status</h5>
            <p className="status">
              <span className={`icon ${debtStatus}`} />
              {/* {debtStatus === "paid-off" ? "paid-off" : debtStatus} */}
              {returnStatusName(debtStatus)}
            </p>
          </div>
        )}
      </div>
      {data.merchant ? (
        <div className="debt-custom-card__block">
          <div className="debt-custom-card__column">
            <h5>
              Merchant{" "}
              <InfoTooltip title="This most commonly references the retailer or store who issued your account. Retailers partner with financial institutions, also referred to as the original creditor, to finance your account." />
            </h5>
            <p>{data.merchant}</p>
          </div>
        </div>
      ) : null}
      
        <div className="debt-custom-card__block">
          {data.account_type ? (
            <div className="debt-custom-card__column">
              <h5>
                Account Type{" "}
                {/* <InfoTooltip title="This most commonly references the retailer or store who issued your account. Retailers partner with financial institutions, also referred to as the original creditor, to finance your account." /> */}
              </h5>
              <p>{data.account_type}</p>
            </div>
          ) : null}
          {data.settlement_limit ? (
            <div className="debt-custom-card__column">
              <h5>
                Settlement Limit{" "}
              </h5>
              <p>{data.settlement_limit}%</p>
            </div>
          ) : null}
          <div className="debt-custom-card__column">
            <h5>
              Comm Count{" "}
            </h5>
            <p>{commCount ? commCount : "-"}</p>
          </div>
        </div>
      <div className="debt-custom-card__block">
        <div className="debt-custom-card__column">
          <h5>
            {standardClient ? "Original Creditor" : "Referring Company"}{" "}
            <InfoTooltip
              title={
                standardClient
                  ? "This is the company you originally owed money to."
                  : "This is the brand that owns your account"
              }
            />
          </h5>
          <p>{data.originalCreditor}</p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            {standardClient ? "Current Creditor" : "Branch"}{" "}
            <InfoTooltip
              title={
                standardClient
                  ? "This is the company that now owns your debt. For more information about how debt is bought and sold, see our Rights and Responsibilities page."
                  : "This is the local branch you are familiar with, and to whom you owe money"
              }
            />
          </h5>
          <p>{data.currentCreditor}</p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            Reference Number{" "}
            <InfoTooltip title="This is the number we use in our system to track your account." />
          </h5>
          <p>{data.referenceNumber}</p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            Account Number{" "}
            <InfoTooltip title="This is the number on the account you originally opened." />
          </h5>
          <p>
            &#8226;&#8226;&#8226;&#8226;{" "}
            {data.originalAccountNumber && data.originalAccountNumber.slice(-4)}
          </p>
        </div>
      </div>
      <div className="debt-custom-card__block">
        <div className="debt-custom-card__column">
          <h5>
            Name on Account{" "}
            <InfoTooltip title="This is the name associated with the original account. If this isn't you, please let us know." />
          </h5>
          <p>{data.nameOnAccount}</p>
        </div>
        {standardClient ? (
          <div className="debt-custom-card__column">
            <h5>
              Current Interest Rate{" "}
              <InfoTooltip title="This is the legal rate at which your account is currently accruing interest." />
            </h5>
            <p>{formattedNumber(data.currentInterestRate)} %</p>
          </div>
        ) : null}
        <div className="debt-custom-card__column">
          <h5>
            Charge Off Date{" "}
            <InfoTooltip title="This is the date your account was written off by the original creditor." />
          </h5>
          <p>{formattedDate(data.chargeOffDate)}</p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            Charge Off Balance{" "}
            <InfoTooltip title="This was the balance on your account when it was written off." />
          </h5>
          <p>
            ${" "}
            {standardClient
              ? data.chargeOffBalance && formattedMoney(data.chargeOffBalance)
              : data.currentBalance}
          </p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            Last Payment Amount{" "}
            <InfoTooltip title="This is the amount of your last posted payment." />
          </h5>
          <p>
            ${" "}
            {(data.lastPaymentAmount &&
              formattedMoney(data.lastPaymentAmount)) ||
              ""}
          </p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            Last Payment Date{" "}
            <InfoTooltip title="This is the date that your most recent payment posted." />
          </h5>
          <p>{formattedDate(data.lastPaymentDate) || ""}</p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            Account Open Date{" "}
            <InfoTooltip title="This is the date you originally opened this account." />
          </h5>
          <p>{formattedDate(data.accountOpenDate) || ""}</p>
        </div>
        <div className="debt-custom-card__column">
          <h5>
            Placement Date{" "}
            <InfoTooltip title="This is the date the company that owns this account contracted us to work with you to collect on the debt." />
          </h5>
          <p>{formattedDate(data.placementDate) || ""}</p>
        </div>
      </div>
      {standardClient ? (
        <div className="debt-custom-card__block">
          <div className="debt-custom-card__column">
            <h5>
              Judgment Case{" "}
              <InfoTooltip title="This is the case number of the judgment saying you need to repay this money." />
            </h5>
            <p>{data.judgementCase || ""}</p>
          </div>
          <div className="debt-custom-card__column">
            <h5>
              Judgment Date{" "}
              <InfoTooltip title="This is the date the court rendered the judgment saying you need to repay this money." />
            </h5>
            <p>{formattedDate(data.judgementDate) || ""}</p>
          </div>
        </div>
      ) : null}
      <div className="debt-custom-card__show-more">
        <button onClick={() => handleShowMoreClick()}>
          Show{" "}
          {isShowingMore ? (
            <span>
              Less <KeyboardArrowUp />
            </span>
          ) : (
            <span>
              More <KeyboardArrowDown />
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

SingleDebt.propTypes = {
  data: PropTypes.object,
  debtStatus: PropTypes.string,
  successSum: PropTypes.any,
};

import React from "react";

export const OtherMasterCardIcon = () => {
  return (
    <svg
      className="master-card"
      width="40"
      height="24"
      viewBox="0 0 40 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5154 18.2889H16.4851V5.70996H23.5155L23.5154 18.2889Z"
        fill="#FF5F00"
      />
      <path
        d="M16.9308 11.9999C16.9308 9.44829 18.1308 7.17528 19.9994 5.71046C18.5849 4.60003 16.8355 3.99736 15.0342 4.00001C10.5969 4.00001 7 7.58164 7 11.9999C7 16.4182 10.5969 19.9998 15.0342 19.9998C16.8355 20.0025 18.585 19.3999 19.9995 18.2894C18.131 16.8248 16.9308 14.5517 16.9308 11.9999Z"
        fill="#EB001B"
      />
      <path
        d="M33.0002 11.9999C33.0002 16.4182 29.4033 19.9998 24.966 19.9998C23.1645 20.0025 21.4148 19.3998 20 18.2894C21.8692 16.8246 23.0692 14.5517 23.0692 11.9999C23.0692 9.44816 21.8692 7.17528 20 5.71046C21.4147 4.60006 23.1644 3.99741 24.9659 4.00001C29.4032 4.00001 33.0001 7.58164 33.0001 11.9999"
        fill="#F79E1B"
      />
      <path
        d="M4 1H36V-1H4V1ZM39 4V20H41V4H39ZM36 23H4V25H36V23ZM1 20V4H-1V20H1ZM4 23C2.34315 23 1 21.6569 1 20H-1C-1 22.7614 1.23858 25 4 25V23ZM39 20C39 21.6569 37.6569 23 36 23V25C38.7614 25 41 22.7614 41 20H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
        fill="#DADFE6"
      />
    </svg>
  );
};

export const OtherVisaIcon = () => {
  return (
    <svg
      width="40"
      height="24"
      viewBox="0 0 40 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V20C40 22.2091 38.2091 24 36 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="white"
      />
      <path
        d="M16.8723 16.8333H14.2612L15.8931 7.17262H18.5045L16.8723 16.8333ZM12.0645 7.17262L9.57518 13.8173L9.28062 12.3865L9.28089 12.387L8.40228 8.04246C8.40228 8.04246 8.29604 7.17262 7.16365 7.17262H3.04829L3 7.3362C3 7.3362 4.25848 7.58842 5.73132 8.44042L7.99987 16.8336H10.7205L14.8747 7.17262H12.0645ZM32.6024 16.8333H35L32.9096 7.17236H30.8106C29.8413 7.17236 29.6052 7.89232 29.6052 7.89232L25.7109 16.8333H28.4329L28.9772 15.3983H32.2966L32.6024 16.8333ZM29.7292 13.416L31.1011 9.80073L31.873 13.416H29.7292ZM25.9151 9.4958L26.2877 7.42122C26.2877 7.42122 25.1379 7 23.9392 7C22.6435 7 19.5663 7.54552 19.5663 10.1982C19.5663 12.694 23.1779 12.725 23.1779 14.0359C23.1779 15.3469 19.9384 15.112 18.8693 14.2853L18.4812 16.4545C18.4812 16.4545 19.6471 17 21.4284 17C23.2103 17 25.8985 16.1113 25.8985 13.6925C25.8985 11.1807 22.2545 10.9468 22.2545 9.85474C22.2547 8.76241 24.7977 8.90273 25.9151 9.4958Z"
        fill="#2566AF"
      />
      <path
        d="M9.28087 12.3868L8.40227 8.04225C8.40227 8.04225 8.29603 7.17242 7.16364 7.17242H3.04829L3 7.336C3 7.336 4.978 7.73086 6.87524 9.2103C8.68932 10.6244 9.28087 12.3868 9.28087 12.3868Z"
        fill="#E6A540"
      />
      <path
        d="M4 1H36V-1H4V1ZM39 4V20H41V4H39ZM36 23H4V25H36V23ZM1 20V4H-1V20H1ZM4 23C2.34315 23 1 21.6569 1 20H-1C-1 22.7614 1.23858 25 4 25V23ZM39 20C39 21.6569 37.6569 23 36 23V25C38.7614 25 41 22.7614 41 20H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
        fill="#DADFE6"
      />
    </svg>
  );
};

export const OtherAvatarPlaceholderIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#DADFE6" />
      <path
        d="M3.40842 25.8729C5.68084 21.2116 10.4654 18 16 18C21.5346 18 26.3191 21.2116 28.5916 25.8729C25.6624 29.6035 21.111 32 16 32C10.8889 32 6.33763 29.6035 3.40842 25.8729Z"
        fill="#F6F8FB"
      />
      <circle cx="16" cy="10" r="6" fill="#F6F8FB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#DADFE6"
      />
    </svg>
  );
};

import React from "react";

import {
  NavigationDebtsIcon,
  NavigationHistoryIcon,
  NavigationNotesIcon,
  NavigationPaymentIcon,
  NavigationProfileIcon,
} from "../icons/NavigationIcons";

export const adminTabs = [
  { title: "Debts", tabId: "1", icon: <NavigationDebtsIcon /> },
  { title: "Profile", tabId: "2", icon: <NavigationProfileIcon /> },
  { title: "Payment Methods", tabId: "3", icon: <NavigationPaymentIcon /> },
  { title: "Notes", tabId: "4", icon: <NavigationNotesIcon /> },
  { title: "History", tabId: "5", icon: <NavigationHistoryIcon /> },
];

import React from "react";

import PropTypes from "prop-types";

export const ProfileFormInput = ({
  value,
  name,
  handleChange,
  isInvalid,
  inputClass,
  disabled,
  validator,
  prevValue,
}) => {
  const inputClassName = () => {
    if (isInvalid) {
      return "input input-medium input--invalid";
    } else if (inputClass) {
      return `input input-medium ${inputClass}`;
    } else {
      return "input input-medium";
    }
  };

  const handleInputChange = (e) => {
    if (name === "email") {
      handleChange({ [e.target.name]: e.target.value.toLowerCase() });
      return;
    }
    if (prevValue) {
      // if (e.target.value.length > 12) return;

      if (validator) {
        handleChange({ [e.target.name]: validator(e.target.value) });
      }

      return;
    }

    if (validator && e.target.value !== "") {
      if (!validator(e.target.value)) return;
      handleChange({ [e.target.name]: validator(e.target.value) });
    } else {
      handleChange({ [e.target.name]: e.target.value });
    }
  };

  return (
    <input
      type="text"
      className={inputClassName()}
      onChange={handleInputChange}
      name={name}
      value={value || ""}
      disabled={disabled}
    />
  );
};

ProfileFormInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  inputClass: PropTypes.string,
  disabled: PropTypes.any,
  validator: PropTypes.func,
  prevValue: PropTypes.string,
};

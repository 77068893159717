import React, { useState } from "react";

import { useSelector } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import { Helmet } from "react-helmet";

import { PageHeader } from "../../../../components/PageHeader";
import { ConsumersNavigation } from "../../../../components/ConsumersNavigation";
import { ConsumerInfo } from "../ConsumerInfo";
// import { ConsumerDebts } from "../ConsumerDebts";
import { ConsumerDebtsContainer } from "NewAdmin/ConsumerDebtsContainer";
import { ProfileBody } from "../../../../components/ProfileBody";
// import { PaymentBody } from "../../../../components/PaymentBody";
import { NotesBody } from "../../../../components/NotesBody";
import { HistoryBody } from "../../../../components/HistoryBody";
import { PaymentMethod } from "ConsumerPage/ProfileInfoPage/PaymentMethod";


import "./style.scss";

export const ConsumerWrapper = () => {
  const profileData = useSelector((state) => state.profile.profileData);
  const [activeTab, setActiveTab] = useState(setInitialActiveTab());
  // const [consumerSavedCards] = useState([]);
  // const [consumerSavedBankAccounts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(setInitialActiveTab());
  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  // const [loading] = useState(true);

  function setInitialActiveTab() {
    if (window.location.hash === "#accounts") {
      return "1";
    } else if (window.location.hash === "#profile") {
      return "2";
    } else if (window.location.hash === "#payment%20methods") {
      return "3";
    } else if (window.location.hash === "#notes") {
      return "4";
    } else if (window.location.hash === "#history") {
      return "5";
    }

    return "1";
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setSelectedTab(tab);
  };

  const helmetTitle = profileData?.profile?.full_name + ` – ${multitenancyCompanyShortName}`;

  return (
    <div className="consumer-wrapper">
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <PageHeader
        title={profileData.full_name}
        backURL="/admin/consumers"
        backTitle="back"
        consumersNavigation={
          <ConsumersNavigation activeTab={activeTab} toggle={toggle} isAdmin />
        }
        consumerInfo={<ConsumerInfo />}
      />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {/* <ConsumerDebts /> */}
          <ConsumerDebtsContainer selectedTab={selectedTab}/>
        </TabPane>
        <TabPane tabId="2">
          <div className="consumer-wrapper__profile">
            <ProfileBody
              profileData={profileData.profile}
              profileType="consumer"
            />
            <ProfileBody
              profileData={profileData.employer}
              profileType="employer"
            />
          </div>
        </TabPane>
        <TabPane tabId="3">
          {/* <PaymentBody
            // loading={loading}
            // cards={consumerSavedCards}
            // accounts={consumerSavedBankAccounts}
            selectedTab={selectedTab}
          /> */}
        {selectedTab == 3 && (
          <div className="new-payment-container">
            <PaymentMethod/>
          </div>
        )}
        </TabPane>
        <TabPane tabId="4">
          <NotesBody />
        </TabPane>
        <TabPane tabId="5">
          <HistoryBody selectedTab={selectedTab}/>
        </TabPane>
      </TabContent>
    </div>
  );
};

import React from "react";

import PropTypes from "prop-types";

export const ProfileMediumTextInput = ({
  disabled,
  className,
  value,
  onChange,
  name,
  placeholder,
  defaultValue,
}) => {
  return (
    <input
      className={className || "input input-medium"}
      type={name === "zip" ? "number" : "text"}
      disabled={disabled}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

ProfileMediumTextInput.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

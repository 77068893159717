import React from "react";

import PropTypes from "prop-types";
import { Button, ButtonGroup } from "reactstrap";

export const TableFilters = ({
  filterItems,
  filterStatus,
  handleFilterChange,
}) => {
  return (
    <ButtonGroup>
      {filterItems.map((item, i) => (
        <Button
          key={i}
          className={
            item.value.toLowerCase() === filterStatus ? "active" : null
          }
          onClick={() => handleFilterChange(item.value.toLowerCase())}
        >
          {item.title}
        </Button>
      ))}
    </ButtonGroup>
  );
};

TableFilters.propTypes = {
  filterItems: PropTypes.array,
  filterStatus: PropTypes.string,
  handleFilterChange: PropTypes.func,
};

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const multitenancyTerms = (termsLink) => {
  const { t } = useTranslation();

  return (
    <a
      target="_blank"
      className="heading heading-caption-bold"
      href={termsLink}
      rel="noopener noreferrer"
    >
      {t("Terms and Conditions")}
    </a>
  );
};

export const multitenancyPrivacy = (privacyLink) => {
  const { t } = useTranslation();

  return (
    <a
      target="_blank"
      className="heading heading-caption-bold"
      href={privacyLink}
      rel="noopener noreferrer"
    >
      {t("Privacy Policy")}
    </a>
  );
};

export const multitenancyEsign = (esignLink) => {
  const { t } = useTranslation();

  return (
    <a href={esignLink} rel="noopener noreferrer" target="_blank">
      {t("E-Sign Disclosure")}
    </a>
  );
};

export const multitenancyPhone = () => {
  const phone_number = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number
  );

  return phone_number;
};

export const multitenancyHours = () => {
  const hours = useSelector((state) => state.app.tenantSettings?.hours);

  return hours;
};

export const multitenancyCompanyName = () => {
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name
  );

  return companyName;
};

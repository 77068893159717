import React from "react";

import PropTypes from "prop-types";
import { Search } from "@material-ui/icons";

export const SearchContainer = ({
  searchText,
  handleSearchChange,
  handleEnterPressed,
}) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleEnterPressed();
    }
  };
  return (
    <div className="search-container">
      <input
        type="search"
        placeholder="Search..."
        className="form-control"
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        value={searchText}
      />
      <Search />
    </div>
  );
};

SearchContainer.propTypes = {
  searchText: PropTypes.string,
  handleSearchChange: PropTypes.func,
  handleEnterPressed: PropTypes.func,
};

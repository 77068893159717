export const formatedAgentsData = (data) => {
  const formattedArray = [];
  data.map((item) =>
    formattedArray.push({
      agentId: item.id,
      agentFullName: `${item.full_name}`.trim(),
      email: item.email,
      role:item.role,
    })
  );

  return formattedArray;
};

import {
  FETCH_ACTIVE_ACCOUNTS,
  FETCH_ARCHIVED_ACCOUNTS,
  FETCH_ONBOARDING_ACCOUNTS,
  FETCH_All_ACCOUNTS,
} from "../types";

import {
  getConsumerDebts_new
} from "../../lib/api/common";

import { showLoader, hideLoader } from "./app";

export function fetchActiveAccounts(consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getConsumerDebts_new({
      statuses: ["active", "established", "on_hold", "external"],
      client_id: consumerId,
    });

    let debts = [];

    if (response.success === "True") {
      debts = [...response.data];
      dispatch({ type: FETCH_ACTIVE_ACCOUNTS, payload: debts });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(hideLoader());
  }
}

export function fetchOnboardingAccounts(consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getConsumerDebts_new({
      statuses: ["active", "on_hold"],
      client_id: consumerId,
    });

    let debts = [];

    if (response.success === "True") {
      debts = [...response.data];
      dispatch({ type: FETCH_ONBOARDING_ACCOUNTS, payload: debts });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(hideLoader());
  }
}

export function fetchArchivedAccounts(consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getConsumerDebts_new({
      statuses: ["recalled", "paid_off", "delinquent"],
      client_id: consumerId,
    });
    dispatch({ type: FETCH_ARCHIVED_ACCOUNTS, payload: response.data });
    dispatch(hideLoader());
  };
}

export function fetchAllAccounts(consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getConsumerDebts_new({
      statuses: [],
      client_id: consumerId,
    });
    dispatch({ type: FETCH_All_ACCOUNTS, payload: response.data });
    dispatch(hideLoader());
  };
}
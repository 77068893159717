export const statesList = [
  { state: "Alabama", abb: "AL" },
  { state: "Alaska", abb: "AK" },
  { state: "Arizona", abb: "AZ" },
  { state: "Arkansas", abb: "AR" },
  { state: "California", abb: "CA" },
  { state: "Colorado", abb: "CO" },
  { state: "Connecticut", abb: "CT" },
  { state: "Delaware", abb: "DE" },
  { state: "Florida", abb: "FL" },
  { state: "Georgia", abb: "GA" },
  { state: "Hawaii", abb: "HI" },
  { state: "Idaho", abb: "ID" },
  { state: "Illinois", abb: "IL" },
  { state: "Indiana", abb: "IN" },
  { state: "Iowa", abb: "IA" },
  { state: "Kansas", abb: "KS" },
  { state: "Kentucky", abb: "KY" },
  { state: "Louisiana", abb: "LA" },
  { state: "Maine", abb: "ME" },
  { state: "Maryland", abb: "MD" },
  { state: "Massachusetts", abb: "MA" },
  { state: "Michigan", abb: "MI" },
  { state: "Minnesota", abb: "MN" },
  { state: "Mississippi", abb: "MS" },
  { state: "Missouri", abb: "MO" },
  { state: "Montana", abb: "MT" },
  { state: "Nebraska", abb: "NE" },
  { state: "Nevada", abb: "NV" },
  { state: "New Hampshire", abb: "NH" },
  { state: "New Jersey", abb: "NJ" },
  { state: "New Mexico", abb: "NM" },
  { state: "New York", abb: "NY" },
  { state: "North Carolina", abb: "NC" },
  { state: "North Dakota", abb: "ND" },
  { state: "Ohio", abb: "OH" },
  { state: "Oklahoma", abb: "OK" },
  { state: "Oregon", abb: "OR" },
  { state: "Pennsylvania", abb: "PA" },
  { state: "Rhode Island", abb: "RI" },
  { state: "South Carolina", abb: "SC" },
  { state: "South Dakota", abb: "SD" },
  { state: "Tennessee", abb: "TN" },
  { state: "Texas", abb: "TX" },
  { state: "Utah", abb: "UT" },
  { state: "Vermont", abb: "VT" },
  { state: "Virginia", abb: "VA" },
  { state: "Washington", abb: "WA" },
  { state: "West Virginia", abb: "WV" },
  { state: "Wisconsin", abb: "WI" },
  { state: "Wyoming", abb: "WY" },
  { state: "Washington D.C.", abb: "DC" },
];

import { formattingPhoneNumber } from "../../../lib/helpers/formatters";
import { validateEmail, isZipCodeValid } from "../../../lib/helpers/validations";

export const updateFormData = (key, value, setFormData) => {
  setFormData((oldFormData) => {
    return {
      ...oldFormData,
      [key]: value,
    };
  });
};

export const validateNewAgent = (newAgentData) => {
  const errors = [];

  if (!newAgentData.full_name || !newAgentData.full_name.length) {
    errors.push("Full name can not be empty.");
  }

  if (!newAgentData.email || !newAgentData.email.length) {
    errors.push("Email should not be empty.");
  }

  if (!validateEmail(newAgentData.email)) {
    errors.push("Email should be valid.");
  }

  // if (
  //   newAgentData.cell_phone &&
  //   formatUSNumber(newAgentData.cell_phone).length < 12
  // ) {
  //   errors.push("Cell phone should be valid.");
  // }

  // if (
  //   newAgentData.home_phone &&
  //   formatUSNumber(newAgentData.home_phone).length < 12
  // ) {
  //   errors.push("Home phone should be valid.");
  // }

  if (!newAgentData.state) {
    errors.push("Please choose a state.");
  }

  if (!newAgentData.city) {
    errors.push("City should not be empty.");
  }

  if (!newAgentData.street) {
    errors.push("Street should not be empty.");
  }

  if (!newAgentData.zip) {
    errors.push("Zip code should be valid.");
  }

  if (!newAgentData.temporary_password) {
    errors.push("Temporary Password should not be empty.");
  }

  if (
    newAgentData.temporary_password &&
    newAgentData.temporary_password.length < 4
  ) {
    errors.push("Temporary Password should be at least 4 characters long.");
  }

  return errors;
};

export const validateThirdParty = (consumerData) => {
  const errors = [];
  if (
    consumerData.email &&
    !validateEmail(consumerData.email)
  ) {
    errors.push("Email should be valid.");
  }

  if (
    consumerData.phone_number &&
    formattingPhoneNumber(consumerData.phone_number).length < 14
  ) {
    errors.push("Phone should be valid.");
  }

  if (
    consumerData.zip &&
    !isZipCodeValid(consumerData.zip)
  ) {
    errors.push("Zip Code should be valid.");
  }

  return errors;
}

export const validateConsumer = (consumerData) => {
  const errors = [];
  if (
    consumerData.email &&
    consumerData.email !== "" &&
    !validateEmail(consumerData.email)
  ) {
    errors.push("Email should be valid.");
  }

  if (!consumerData.full_name || !consumerData.full_name.length) {
    errors.push("Full name can not be empty.");
  }

  if (
    !!consumerData.cell_phone &&
    formattingPhoneNumber(consumerData.cell_phone).length < 14
  ) {
    errors.push("Cell Phone should be valid.");
  }

  if (
    !!consumerData.home_phone &&
    formattingPhoneNumber(consumerData.home_phone).length < 14
  ) {
    errors.push("Home Phone should be valid.");
  }

  if (
    consumerData.state === "" ||
    consumerData.state === "NA" 
  ) {
    errors.push("Please choose State.")
  }

  return errors;
};

export const validateEmployer = (employerData) => {
  const errors = [];
  if (employerData.employer_full_name === "") {
    errors.push("Employer name can not be empty.");
  } else if (
    employerData.employ_type === "choose" ||
    employerData.employ_type === ""
  ) {
    errors.push("Please choose Type.");
  } else if (
    employerData.employer_state === "" ||
    employerData.employer_state === "NA" 
  ) {
    errors.push("Please choose State.")
  }
  return errors;
};

export const validateAdmin = (adminData) => {
  const errors = [];

  if (!validateEmail(adminData.email)) {
    errors.push("Email should be valid.");
  }

  return errors;
};

export const isEditButtonDisabled = (
  profileType,
  isEditingEmployer,
  isEditingProfile
) => {
  return (
    ((profileType === "own-consumer" || profileType === "consumer") &&
      isEditingEmployer) ||
    ((profileType === "own-employer" || profileType === "employer") &&
      isEditingProfile)
  );
};

export const isEditingForm = (
  isEditingProfile,
  isEditingEmployer,
  profileType
) => {
  return (
    (isEditingProfile &&
      (profileType === "own-consumer" ||
        profileType === "consumer" ||
        profileType === "admin" ||
        profileType === "agent")) ||
    (isEditingEmployer &&
      (profileType === "own-employer" || profileType === "employer")) ||
    profileType === "new-agent"
  );
};

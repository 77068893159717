import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { useAlert } from "react-alert";
import { Close } from "@material-ui/icons";
import { LoadingSpinner } from "components/LoadingSpinner";

import { 
  submitPayment,
  submitOneTimePayment
} from "lib/api/common";

import { FinalDetailsModal } from '../FinalDetailsModal';
import { ChangePaymentSuccess } from "ConsumerPage/PaymentsHistoryContainer/ExtraPaymentFlow/SelectPaymentMethodModal/ChangePaymentSuccess";

import { PayPalUrlGeneration } from "multitenancy/multitenancyPayPal";

import "./style.scss";

let count = 0;

export const NewPayPalModal = ({modal, toggle, parentModal, parentToggle, debt, dateValue, selectedPlan, possiblePaymentSchedule, type, paymentAmount, isChangePaymentMethod}) => {

  const alert = useAlert();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [authMethod, setAuthMethod] = useState("web");
  // Need ref to set last useState for event listener
  const authMethodStateRef = useRef(authMethod);

  const [isFinalDetailsModalOpen, setIsFinalDetailsModalOpen] = useState(false);
  const toggleFinalDetailsModal = () => setIsFinalDetailsModalOpen(!isFinalDetailsModalOpen);

  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});

  const [changePaymentSuccessModal, setChangePaymentSuccessModal] = useState(false);
  const toggleChangePaymentSuccessModal = () => setChangePaymentSuccessModal(!changePaymentSuccessModal);

  useEffect(() => {
    window.addEventListener("message", receiveMessageNewDebitCard, false);
  }, [modal]);
  // catch iframe authorize click

  async function receiveMessageNewDebitCard(event) {
    if (count > 0) return;

    if (event.data === "PAYMENT_SUCCESS") { 
      count++;
      addPaymentCardToConsumer();
    }
  }

  const addPaymentCardToConsumer = async () => {
    setLoading(false);
    if (isChangePaymentMethod) {
      toggleChangePaymentSuccessModal();
      return;
    }
    if (type === "OTP") {
      makeOTPPayment();
    } else if (type === "PLAN") {
      makeSubmitPayment();
    }
  }

  const makeOTPPayment = async (cardData) => {
    await new Promise((r) => setTimeout(r, 4000));
    let data;

    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      card_id: cardData?.card_id,
      debt_id: debt.debt_id,
      payment_method: "paypal",
    };

    data = await submitOneTimePayment(request);

    if (data.success === "True") {
      setSelectedPaymentObject(data.paypal_details);
      toggleFinalDetailsModal();
    } else {

      alert.error(data.message);
    }
    
  }

  const makeSubmitPayment = async (cardData) => {
    await new Promise((r) => setTimeout(r, 4000));
    let data;
    data = await submitPayment({
      client_id: id,
      payment_method: "paypal",
      card_id: cardData?.cardDetails?.card_id,
      debt_id: debt.debt_id,
      amount: debt.plan_balance,
      plan_id: selectedPlan.id,
      start_day: dateValue,
      start_date: possiblePaymentSchedule?.possible_payment_info.start_date,
      end_date: possiblePaymentSchedule?.possible_payment_info.end_date,
      agreement_timestamp: new Date(),
    });

    if (data.success === "True") {

      // for new TKL
      // if (cardData.card_details === null) {
        setSelectedPaymentObject(data.paypal_details);
      // }
      toggleFinalDetailsModal();
    } else {

      alert.error(data.message);
    }
  }

  const setAuthMethodFunc = (e) => {
    setAuthMethod(e.target.value);

    authMethodStateRef.current = e.target.value;
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const toggleModal = () => {
    toggle();
    parentToggle();
  }

  return (
    <>
      <Modal
        isOpen={modal}
        // toggle={toggle}
        onOpened={closeParentModal}
        scrollable={true}
        className="new-payment-modal"
      >
        <ModalBody>
          <div className="mod-header">
            <span>Payment Details</span>
            <button className="close-modal-btn" onClick={() => toggleModal()}>
              <Close />
            </button>
          </div>
          {!loading ? (
            <div className="authorization-selector">
              <label
                className="heading-caption-bold"
                htmlFor="account_type"
              >
                Authorization Method
              </label>
              <Select
                labelId="authorization"
                id="authorization"
                name="authorization"
                onChange={setAuthMethodFunc}
                input={<InputBase value={authMethod} className="input input-medium" />}
              >
                <MenuItem value={"web"}>Web</MenuItem>
                <MenuItem value={"verbal"}>Verbal</MenuItem>
                <MenuItem value={"written"}>Written</MenuItem>
              </Select>
            </div>
          ) : null}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <iframe id="iframe-modal" src={PayPalUrlGeneration()} title="Add Card" frameBorder="0"/>
          )}

        </ModalBody>
      </Modal>
      {isChangePaymentMethod && (
        <ChangePaymentSuccess
          modal={changePaymentSuccessModal}
          parentModal={modal}
          parentToggle={toggle}
        />
      )}

      {isFinalDetailsModalOpen && (
        <FinalDetailsModal
          isModalOpen={isFinalDetailsModalOpen}
          parentModal={modal}
          parentToggle={toggle}
          startDate={possiblePaymentSchedule?.possible_payment_info.start_date}
          endDate={possiblePaymentSchedule?.possible_payment_info.end_date}
          selectedPlan={selectedPlan}
          selectedPaymentObject={selectedPaymentObject}
          paymentAmount={paymentAmount}
          debt={debt}
        />
      )}
    </>
  )

}
import {
  FETCH_DEFAULT_PAYMENT_METHOD
} from "../types";

import { getDefaultPaymentMethod } from "lib/api/common"; 

export function fetchDefaultPaymentMethod(consumerId, data_id) {
  return async(dispatch) => {
    const response = await getDefaultPaymentMethod({ client_id: consumerId, data_id: data_id });
    if (response.success === "True") {
      dispatch({ type: FETCH_DEFAULT_PAYMENT_METHOD, payload: response})
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
  }
}
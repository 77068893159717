import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { ConsumerFooter } from "../../ConsumerFooter";
import { getShortConsumerDebts_new } from "lib/api/common";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  multitenancyPrivacy,
  multitenancyEsign,
} from "multitenancy/multitenancyContactInfo";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { fetchConsumerProfile } from "redux/actions/profile";

import { createDispute } from "lib/api/common";

import { SuccessHelpModal } from "../SuccessHelpModal";

import "../style.scss";
import { wtError } from "lib/helpers/dataLayers";

export const DisputesPage = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [formedSelectFilter, setFormSelectFilter] = useState([]);
  const [selectedSearchDataId, setSelectedSearchDataId] = useState();
  const [selectedRefNumber, setSelectedRefNumber] = useState();
  const [customerName, setCustomerName] = useState("");
  const [loading, setLoading] = useState(true);
  const [newNoteText, setNewNoteText] = useState("");
  const [acceptTermsCond, setAcceptTermsCond] = useState(false);
  const [sendTimeout, setSendTimeout] = useState(true);

  const [notMyDebt, setNotMyDebt] = useState(false);
  const [wrongAmount, setWrongAmount] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const [origCredit, setOrigCredit] = useState(false);
  const [victim, setVictim] = useState(false);
  const [other, setOther] = useState(false);
  const [otherValue, setOtherValue] = useState("");

  const [successHelpModal, setSuccessHelpModal] = useState(false);
  const toggleSuccessHelpModal = () => setSuccessHelpModal(!successHelpModal);

  const consumersData = useSelector((state) => state.profile.profileData);
  const tenantSettings = useSelector((state) => state.app.tenantSettings);

  const selectCheckboxArray = [
    {
      state: notMyDebt,
      name: "not-my-debt",
      title: "This is not my debt.",
    },
    {
      state: wrongAmount,
      name: "wrong-amount",
      title: "The amount is wrong.",
    },
    {
      state: alreadyPaid,
      name: "already-paid",
      title: "I’ve already paid this balance.",
    },
    {
      state: victim,
      name: "victim",
      title: "I am a victim of fraud/identity theft.",
    },
    {
      state: origCredit,
      name: "orig-credit",
      title:
        "I want you to send me the name and address of the original creditor.",
    },
    {
      state: other,
      name: "other",
      title: "Other.",
    },
  ];

  const { t } = useTranslation();

  useEffect(() => {
    fetchDebtInfo();
    if (_.isEmpty(consumersData)) {
      dispatch(fetchConsumerProfile());
    }
    setCustomerName(consumersData?.profile?.full_name);
  }, [consumersData]);

  const fetchDebtInfo = async () => {
    const response = await getShortConsumerDebts_new({
      statuses: [
        "active",
        "established",
        "recalled",
        "paid_off",
        "delinquent",
        "on_hold",
      ],
    });
    if (response.success === "True") {
      formingSelectFilter(response.data);
    }
  };

  const formingSelectFilter = (data) => {
    const formedArray = data.map((item) => ({
      label: item.referenceNumber,
      value: item.referenceNumber,
    }));
    setFormSelectFilter(formedArray);
    setSelectedSearchDataId(formedArray[0]);
    setSelectedRefNumber(formedArray[0].value);
    setLoading(false);
  };

  const filterByDataId = (e) => {
    setSelectedSearchDataId(e);
    setSelectedRefNumber(e.value);
  };

  const changeName = (e) => {
    setCustomerName(e.target.value);
  };

  const toggleTerms = (e) => {
    setAcceptTermsCond(e.target.checked);
  };

  const toggleDispArray = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case "not-my-debt":
        setNotMyDebt(checked);
        break;
      case "wrong-amount":
        setWrongAmount(checked);
        break;
      case "already-paid":
        setAlreadyPaid(checked);
        break;
      case "victim":
        setVictim(checked);
        break;
      case "orig-credit":
        setOrigCredit(checked);
        break;
      case "other":
        setOther(checked);
        break;
      default:
        break;
    }
  };

  const sendRequest = async () => {
    setSendTimeout(false);
    setTimeout(() => setSendTimeout(true), 3000);

    let reasonString =
      selectCheckboxArray
        .filter((item) => item.state === true && item.name !== "other")
        .map((item) => item.title)
        .join(" ") + otherValue;

    const request = {
      data_id: selectedRefNumber,
      dispute_type: "dispute",
      name: customerName,
      explanation: newNoteText,
      reasons: reasonString,
      expected_outcome: "",
    };

    setLoading(true);
    const data = await createDispute(request);
    if (data.success === "True") {
      setLoading(false);
      toggleSuccessHelpModal();
    } else {
      setLoading(false);
      wtError(data);
      alert.error(data.explanation[0]);
    }
  };

  const changeOther = (e) => {
    setOtherValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.which === 9) {
      e.preventDefault();
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      e.target.value =
        e.target.value.substring(0, start) +
        "\t" +
        e.target.value.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("Disputes")}</title>
      </Helmet>
      <div className="help-center-body">
        <div className="title">{t("Disputes")}</div>
        <div className="subtitle">
          <p>
            {t(
              "You have the right to dispute this debt. If you believe there is an error regarding your debt or need more information, we are here to assist you through this process."
            )}
          </p>
          <p>
            {t(
              "Disputes can arise for several reasons. We have provided additional information on various types of disputes below to assist you."
            )}
          </p>
          <p>
            <span>{t("This is not my debt: ")}</span>
            {t(
              "You do not recall this debt or believe you do not owe this debt and would like more information."
            )}
          </p>
          <p>
            <span>{t("The amount is wrong: ")}</span>
            {t("You acknowledge owing a debt but dispute the amount owed.")}
          </p>
          <p>
            <span>{t("I’ve already paid this balance: ")}</span>
            {t(
              "You believe this debt has been paid off and should not have an outstanding balance."
            )}
          </p>
          <p>
            <span>{t("Name and address of the original creditor: ")}</span>
            {t(
              "You would like to request the name and address of the original creditor to help you identify the source of the debt."
            )}
          </p>
          <p>
            <span>{t("I am a victim of fraud/identity theft: ")}</span>
            {t(
              "You know or suspect that you may be a victim of fraud or identity theft."
            )}
          </p>
          <p>
            <span>{t("Other: ")}</span>
            {t(
              "You would like to provide details on a unique situation not covered by the options above."
            )}
          </p>
          <p>
            {t(
              "After selecting this option, please provide a detailed explanation of your dispute to aid us in our investigation."
            )}
          </p>
          <p>
            {t("By accepting our")}{" "}
            {multitenancyPrivacy(tenantSettings?.privacy)} {t("and")}{" "}
            {multitenancyEsign(tenantSettings?.esign_document)}{" "}
            {t(
              "policy, you understand how we handle your personal data during the dispute process, and you affirm the information you have provided is true. Once we have received your request, we will place your account on hold and discontinue collections until we investigate the dispute and send you a response."
            )}
          </p>
          <p>
            {t("Thank you for allowing us to assist you through this process.")}
          </p>
        </div>
        {loading ? (
          <LoadingSpinner middleFixed={true} />
        ) : (
          <div className="input-containers">
            <div className="header-container">
              <div className="name-container">
                <span>{t("Name")}</span>
                <input
                  className="name-input"
                  type="text"
                  placeholder={t("Name")}
                  onChange={changeName}
                  value={customerName || ""}
                />
              </div>
              <div className="name-container">
                <span>{t("Reference Number")}</span>
                <Select
                  className="select-container"
                  id="search-dropdown"
                  placeholder={t("Reference number")}
                  defaultValue={selectedSearchDataId}
                  options={formedSelectFilter}
                  onChange={filterByDataId}
                />
              </div>
            </div>
            <div className="select-checkbox-container">
              <span>{t("Select all that apply to your dispute")}</span>
              {selectCheckboxArray.map((item, i) => (
                <div className="checkbox-row" key={i}>
                  <Checkbox
                    className="checkbox-value"
                    checked={item.state}
                    onChange={toggleDispArray}
                    name={item.name}
                    size="small"
                  />
                  <span>{t(item.title)}</span>
                </div>
              ))}
              {other && (
                <textarea
                  onChange={changeOther}
                  onKeyDown={handleKeyDown}
                  value={otherValue}
                  className="other-input"
                  name="comment"
                  id="comment"
                  placeholder={t("Type text here")}
                ></textarea>
              )}
            </div>
            <div className="notes-container">
              <span>
                {t(
                  "With as much detail as possible, provide an explanation of your dispute"
                )}
              </span>
              <textarea
                onChange={(e) => setNewNoteText(e.target.value)}
                onKeyDown={handleKeyDown}
                value={newNoteText}
                className="notes-input"
                name="comment"
                id="comment"
                placeholder={t("Type text here")}
              ></textarea>
            </div>
          </div>
        )}
        <div className="terms-container">
          <Checkbox
            className="checkbox-value"
            checked={acceptTermsCond}
            onChange={toggleTerms}
            name="terms"
            size="small"
          />
          <span className="text-cont">
            {t("I accept the terms of the")}{" "}
            {multitenancyPrivacy(tenantSettings?.privacy)} {t("and")}{" "}
            {multitenancyEsign(tenantSettings?.esign_document)}
          </span>
        </div>
        <CustomButton
          text={t("Send")}
          onClickHandler={() => sendRequest()}
          optionalClass="send-btn"
          disabled={!acceptTermsCond || !sendTimeout || newNoteText.length < 1}
        />
      </div>
      <ConsumerFooter />
      {successHelpModal && (
        <SuccessHelpModal modal={successHelpModal} type="disputes" />
      )}
    </>
  );
};

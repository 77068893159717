import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  formattingPhoneNumber,
} from "../../../../lib/helpers/formatters";
import { ProfileFormInput } from "../../ProfileFormInput";
import _ from "lodash";

export const PhoneNumbersBlock = ({
  formPhoneNumbersItems,
  profileForm,
  adminData,
  isEditingProfile,
  handleChange,
  profileType,
  isValidField,
}) => {
  return (
    <Fragment>
      {formPhoneNumbersItems.map((item) => {
        const [itemValue] = Object.values(
          _.pickBy(adminData.profile, function (value, key) {
            return _.startsWith(key, item.name);
          })
        );

        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={
                profileForm && formattingPhoneNumber(profileForm[item.name])
              }
              name={item.name}
              handleChange={handleChange}
              validator={formattingPhoneNumber}
              prevValue={itemValue}
              disabled={!isEditingProfile || item.disabled}
              isInvalid={
                profileType !== "new-agent" &&
                !isValidField(item.title, profileForm[item.name])
              }
            />
          </div>
        );
      })}
    </Fragment>
  );
};

PhoneNumbersBlock.propTypes = {
  formPhoneNumbersItems: PropTypes.array,
  profileForm: PropTypes.object,
  adminData: PropTypes.object,
  isEditingProfile: PropTypes.bool,
  handleChange: PropTypes.func,
  profileType: PropTypes.string,
  isValidField: PropTypes.func,
};

export const adminFormPrimaryItems = [
  { title: "Full Name", name: "full_name", disabled: true },
  { title: "Email", name: "email", disabled: false },
  { title: "Start Date", name: "dob", disabled: false },
];
export const adminFormPhoneNumbersItems = [
  { title: "Cell Phone", name: "cell_phone", disabled: false },
  { title: "Home Phone", name: "home_phone", disabled: false },
];
export const adminFormAddressItems = [
  { title: "State", name: "state", disabled: false },
  { title: "City", name: "city", disabled: false },
  { title: "Street", name: "street", disabled: false },
  { title: "ZIP", name: "zip", disabled: false },
];

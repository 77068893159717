import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { Close } from "@material-ui/icons";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { setRequest } from "../../../../redux/actions/setupPlan";
import { addBankAccount } from "../../../../lib/api/consumer";
import { submitExtraPayment, generateNACHA } from "../../../../lib/api/common";
import { fetchConsumerProfile } from "../../../../redux/actions/profile";
import { CustomButton } from "../../../../components/ui-kit/CustomButtom";
import { AuthAgreementPhoneSinglePay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementPhoneSinglePay";

import { SuccessPaymentModal } from "../SuccessPaymentModal";

import { AuthAgreementWebOnePay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementWebOnePay";
import { accountNumberLastFour } from "lib/helpers/other";
import { ReceiptModal } from "components/ReceiptModal";
import { NACHAWebSingleModalData } from "lib/common/NACHAModalData";
import { NACHAPhoneSingleModalData } from "lib/common/NACHAModalData";
import { NACHAJavitchModalData } from "lib/common/NACHAModalData";
import { ChangePaymentSuccess } from "../SelectPaymentMethodModal/ChangePaymentSuccess";

import { useTranslation } from "react-i18next";

import "./style.scss";
import { wtError } from "lib/helpers/dataLayers";

export const AddExtraPaymNewBankAccountModal = ({
  modal,
  parentModal,
  parentToggle,
  toggle,
  debt,
  paymentAmount,
  isChangePaymentMethod,
  setIsPaymentSelected,
  nearestScheduleDate,
}) => {
  const { t } = useTranslation();

  const alert = useAlert();
  const dispatch = useDispatch();
  const [accountType, setType] = useState("1");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);

  const [successPaymentModal, setSuccessPaymentMethod] = useState(false);
  const toggleSuccessPayment = () =>
    setSuccessPaymentMethod(!successPaymentModal);

  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});

  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const isNextButtonDisabled = useSelector(
    (state) => state.setupPlan.isNextButtonDisabled
  );
  const consumersData = useSelector((state) => state.profile.profileData);
  const profileInfo = useSelector((state) => state.profile.profileData.profile);
  const tenantName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [changePaymentSuccessModal, setChangePaymentSuccessModal] =
    useState(false);
  const toggleChangePaymentSuccessModal = () =>
    setChangePaymentSuccessModal(!changePaymentSuccessModal);
  const phone = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number || ""
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours || "");
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name || ""
  );
  useEffect(() => {
    _.isEmpty(consumersData) && dispatch(fetchConsumerProfile(id));
    dispatch(
      setRequest({
        ...accountPaymentRequest,
        name_on_check: consumersData.profile?.full_name,
        email: consumersData.profile?.email,
        authorization: "web",
        data_id: debt.referenceNumber,
      })
    );
    // eslint-disable-next-line
  }, [consumersData]);

  const openSuccessModal = () => {
    toggleSuccessPayment();
  };

  const handleAddNewACH = async () => {
    const newBankAccountRequest = {
      ...accountPaymentRequest,
    };

    setLoading(true);
    setIsConfirmDisabled(true);
    let accountData;
    if (id) {
      accountData = await addBankAccount({
        client_id: id,
        ...newBankAccountRequest,
      });
    } else {
      accountData = await addBankAccount(newBankAccountRequest);
    }

    if (accountData.success === "True") {
      setSelectedPaymentObject(newBankAccountRequest);
      if (isChangePaymentMethod) {
        await generateNACHA({
          account_number: accountPaymentRequest.account_number,
          account_type: accountPaymentRequest.account_type,
          bank_name: accountPaymentRequest.bank_name,
          agreement_timestamp: new Date(),
          email: profileInfo.email,
          client_id: id,
          routing_number: accountPaymentRequest.routing_number,
          name_on_check: accountPaymentRequest.name_on_check,
          payment_frequency: "Regular",
          data_id: debt.referenceNumber,
        });
        toggleChangePaymentSuccessModal();
      } else {
        makeExtraPaymentForAccountNumber(newBankAccountRequest, accountData);
      }
    } else {
      setLoading(false);
      setIsConfirmDisabled(false);
      wtError(accountData);
      alert.error(accountData.message);
    }
  };

  const makeExtraPaymentForAccountNumber = async (
    newBankAccountData,
    accountData
  ) => {
    const request = {
      client_id: id,
      account_number: newBankAccountData?.account_number,
      account_type: "1",
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      bank_name: newBankAccountData.bank_name,
      debt_id: debt.debt_id,
      card_id: accountData.account_id,
      // email: profileInfo.email,
      name_on_check: profileInfo.full_name,
      payment_method: "ach",
      routing_number: newBankAccountData.routing_number,
    };

    let data;

    data = await submitExtraPayment(request);

    if (data.success === "True") {
      setLoading(false);
      setIsConfirmDisabled(false);
      openSuccessModal();
    } else {
      setLoading(false);
      setIsConfirmDisabled(false);
      wtError(data);
      alert.error(t(data.message));
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    handleSetAccountPaymentRequest(e);
  };

  const handleSetAccountPaymentRequest = (e) => {
    e.persist();

    dispatch(
      setRequest({
        ...accountPaymentRequest,
        debt_id: debt.debt_id,
        account_type: accountType,
        institution_number: 803,
        [e.target.name]: e.target.value.trim(),
      })
    );
  };

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
      setIsPaymentSelected(false);
    }
  };

  const backBtn = () => {
    toggle();
    parentToggle();
  };

  const renderNACHABlock = () => {
    if (!id) {
      return (
        <div className="ach-nach-con">
          <AuthAgreementWebOnePay
            accountType={accountType}
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            paymentAmount={paymentAmount}
            nearestScheduleDate={nearestScheduleDate}
          />
        </div>
      );
    } else {
      return (
        <div className="ach-nach-con">
          <AuthAgreementPhoneSinglePay
            accountType={accountType === "1" ? "Checking" : "Savings"}
            accountPaymentRequest={accountPaymentRequest}
            accountNumber={accountNumberLastFour(
              accountPaymentRequest.account_number
            )}
            authorizationSignature={consumersData.profile?.full_name}
            paymentAmount={paymentAmount}
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            consumerState={consumersData.profile?.state}
          />
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        scrollable={true}
        onOpened={closeParentModal}
        className="add-new-payment-modal"
      >
        <ModalBody>
          <div className="mod-header">
            <span>{t("Payment Details")}</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          <form className="mod-body">
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="account_type"
              >
                {t("Account Type")}
              </label>
              <Select
                labelId="type"
                id="account_type"
                name="account_type"
                onChange={handleTypeChange}
                input={
                  <InputBase
                    value={accountType}
                    className="input input-medium"
                  />
                }
              >
                <MenuItem value={"1"}>{t("Checking")}</MenuItem>
                <MenuItem value={"2"}>{t("Savings")}</MenuItem>
              </Select>
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="name_on_check"
              >
                {t("Name On Check")}
              </label>
              <input
                type="text"
                className="input input-medium"
                name="name_on_check"
                id="name_on_check"
                onChange={handleSetAccountPaymentRequest}
                defaultValue={accountPaymentRequest.name_on_check}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="routing_number"
              >
                {t("Routing Number")}
              </label>
              <input
                type="number"
                className="input input-medium"
                id="routing_number"
                name="routing_number"
                onChange={handleSetAccountPaymentRequest}
                value={accountPaymentRequest.routing_number}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="account_number"
              >
                {t("Account Number")}
              </label>
              <input
                type="number"
                className="input input-medium"
                name="account_number"
                id="account_number"
                onChange={handleSetAccountPaymentRequest}
                value={accountPaymentRequest.account_number}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="bank_name"
              >
                {t("Bank Name")}
              </label>
              <input
                type="text"
                className="input input-medium"
                id="bank_name"
                name="bank_name"
                onChange={handleSetAccountPaymentRequest}
                value={accountPaymentRequest.bank_name}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="bank_name"
              >
                {t("Email")}
              </label>
              <input
                type="text"
                className="input input-medium"
                id="email"
                name="email"
                defaultValue={accountPaymentRequest.email}
                onChange={handleSetAccountPaymentRequest}
              />
            </div>
          </form>
          <div className="mod-footer">
            <CustomButton
              text={t("Cancel")}
              onClickHandler={() => backBtn()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text={t("Confirm Payment")}
              onClickHandler={() => handleAddNewACH()}
              disabled={isNextButtonDisabled || isConfirmDisabled}
              optionalClass="confirm-btn"
            />
          </div>
          {renderNACHABlock()}
        </ModalBody>
        {loading ? <LoadingSpinner middleFixed={true} /> : null}
      </Modal>
      {isShowingModal
        ? (!id && tenantName === "JB LLC" && (
            <ReceiptModal
              data={NACHAJavitchModalData()}
              setShowModal={setIsShowingModal}
            />
          )) ||
          (!id && (
            <ReceiptModal
              data={NACHAWebSingleModalData(
                companyName,
                phone,
                hours,
                accountType,
                accountPaymentRequest.bank_name,
                accountPaymentRequest.name_on_check,
                accountPaymentRequest.routing_number,
                accountNumberLastFour(accountPaymentRequest.account_number),
                paymentAmount,
                nearestScheduleDate
              )}
              setShowModal={setIsShowingModal}
            />
          )) ||
          (id && (
            <ReceiptModal
              data={NACHAPhoneSingleModalData(
                companyName,
                phone,
                hours,
                accountPaymentRequest.name_on_check,
                accountType,
                accountNumberLastFour(accountPaymentRequest.account_number),
                paymentAmount,
                consumersData.profile?.state
              )}
              setShowModal={setIsShowingModal}
            />
          ))
        : null}
      {!isChangePaymentMethod ? (
        <SuccessPaymentModal
          modal={successPaymentModal}
          selectedPaymentObject={selectedPaymentObject}
          parentModal={modal}
          parentToggle={toggle}
          paymentAmount={paymentAmount}
          debt={debt}
        />
      ) : null}
      {isChangePaymentMethod && (
        <ChangePaymentSuccess
          modal={changePaymentSuccessModal}
          parentModal={modal}
          parentToggle={toggle}
        />
      )}
    </>
  );
};

AddExtraPaymNewBankAccountModal.propTypes = {
  parentModal: PropTypes.bool,
  parentToggle: PropTypes.func,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  debt: PropTypes.object,
  paymentAmount: PropTypes.string,
  isChangePaymentMethod: PropTypes.bool,
  setIsPaymentSelected: PropTypes.any,
};

import {
  FETCH_PROFILE,
  HIDE_LOADER,
  SHOW_LOADER,
  EDIT_PROFILE,
  SAVE_PROFILE,
  CANCEL_EDITING,
  UPDATE_PROFILE_FORM,
  UPDATE_EMPLOYER_FORM,
  FETCH_CONSUMER_STRATEGY,
  FETCH_CONSUMER_COMM_COUNT,
  SET_ERRORS,
  SET_THIRD,
  SET_EMP,
  SET_CONSUMER_PROFILE_COMPLETE,
  SET_PROFILE_SAVED,
  SET_OLD_USER_EMAIL,
  EDIT_EMPLOYER_PROFILE,
} from "../types";

const initialState = {
  loading: true,
  profileData: {},
  profileForm: {},
  employerForm: {},
  formErrors: [],
  thirdErrors: [],
  empErrors: [],
  adminForm: {},
  isEditingProfile: false,
  isEditingEmployer: false,
  isConsumerProfileComplete: true,
  isProfileSaved: false,
  oldUserEmail: "",
  strategy: "",
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case EDIT_PROFILE:
      return { ...state, isEditingProfile: true };
    case EDIT_EMPLOYER_PROFILE:
      return { ...state, isEditingEmployer: true };
    case SAVE_PROFILE:
      return { ...state, isEditingProfile: false };
    case CANCEL_EDITING:
      return { ...state, isEditingProfile: false, isEditingEmployer: false };
    case FETCH_PROFILE:
      return { ...state, profileData: action.payload };
    case FETCH_CONSUMER_STRATEGY:
      return { ...state, strategy: action.payload };
    case FETCH_CONSUMER_COMM_COUNT:
      return { ...state, comm_count: action.payload };
    case UPDATE_PROFILE_FORM:
      return { ...state, profileForm: action.payload };
    case UPDATE_EMPLOYER_FORM:
      return { ...state, employerForm: action.payload };
    case SET_ERRORS:
      return { ...state, formErrors: action.payload };
    case SET_THIRD:
      return { ...state, thirdErrors: action.payload };
    case SET_EMP:
      return { ...state, empErrors: action.payload };
    case SET_CONSUMER_PROFILE_COMPLETE:
      return { ...state, isConsumerProfileComplete: action.payload };
    case SET_PROFILE_SAVED:
      return { ...state, isProfileSaved: action.payload };
    case SET_OLD_USER_EMAIL:
      return { ...state, oldUserEmail: action.payload };
    default:
      return state;
  }
};

import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useAlert } from "react-alert";
import { PageHeader } from "../../components/PageHeader";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { changePassword } from "../../lib/api/admin";
import { ProfileFormTitle } from "../../components/ProfileBody/ProfileFormTitle";
import { CustomButton } from "../../components/ui-kit/CustomButtom";


export const ChangePasswordPage = () => {
  const alert = useAlert();

  const changePasswordForm = useSelector(
    (state) => state.changePassword.changePasswordForm
  );
  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );

  const [saveBtnState, setSaveBtnState] = useState(true);

  const userName = useSelector((state) => state.topNav.userName);

  const handleSave = async () => {
    const data = await changePassword(changePasswordForm);
    if (data.success === "True") {
      window.location.replace("/admin/profile");
    } else {
      alert.error(data.message);
    }
  };

  const backToProfile = () => {
    window.location.replace("/admin/profile")
  }

  const profileFormChildren = () => {
    return (
      <div className="profile-form-title__buttons">
        <Helmet>
          <title>Update Password – {multitenancyCompanyShortName}</title>
        </Helmet>
        <CustomButton
          text="Cancel"
          onClickHandler={() => backToProfile()}
          type="secondary"
        />
        <CustomButton
          text="Save"
          onClickHandler={handleSave}
          disabled={saveBtnState}
        />
      </div>
    );
  };

  return (
    <div className="profile-page-container">
      <PageHeader title="Profile" />
      <div className="tab-container">
        <div className="tab-container__column">
          <div className="avatar-wrapper">
            <h2 className="heading heading-2">
              {userName}
            </h2>
            <h6 className="heading heading-caption-bold">From Feb 28, 2020</h6>
          </div>
        </div>
        <div className="profile-body tab-container__column tab-container__column--medium">
          <ProfileFormTitle title="Change Password">
            {profileFormChildren()}
          </ProfileFormTitle>
          <ChangePasswordForm
            setSaveBtnState={setSaveBtnState}
          />
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Switch } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";

import { consumerEnableMFA, getConsumerEnableMFA } from "../../../../lib/api/auth";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const TwoFactorAuth = () => {
  const { id } = useParams();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const getTwoFaStatus = async () => {
      const data = await getConsumerEnableMFA({id});
      
      if (data.success === "True") {
        setChecked(data.mfa_enabled);
      }
    }
    getTwoFaStatus();
  }, []);

  const handleChange = () => {
    setChecked(!checked);
    enableDisable2FA();
  }

  const enableDisable2FA = async () => {
    let prepareReqBody;
    if (checked) {
      prepareReqBody = {
        enable_mfa: "False"
      }
    } else {
      prepareReqBody = {
        enable_mfa: "True"
      }
    }
    setLoading(true);
    const data = await consumerEnableMFA(prepareReqBody, {id});

    if (data.success === "True") {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
      <div className="profile-two-factor-auth">
        <div className="two-factor-title">{t("Two-Factor Authentication")}</div>
        <div className="two-factor-block">
          <div className="toggle-row">
            <Switch 
              checked={checked}
              onChange={handleChange}
            />
            <div className="toggle-text">{t("Enable Two-Factor Authentication")}</div>
          </div>
        </div>
      </div>
    </>
  )
}
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useAlert } from "react-alert";

import "./style.scss";
import { SystemCheckCircledIcon24 } from "../../../../components/icons/SystemIcons";
import { DebtSuccessPayment } from "../../../../components/DebtCustomCard/DebtSuccessPayment";
import { DebtPlan } from "../../../../components/DebtCustomCard/DebtPlan";
import {
  getConsumerDebtHistory,
  getConsumerDebts,
  getReceiptTemplate,
  removePaymentMethod,
} from "../../../../lib/api/common";
import { NavigationPaymentIcon } from "../../../../components/icons/NavigationIcons";
import { ReceiptModal } from "../../../../components/ReceiptModal";
import { SingleDebt } from "../../../../components/DebtCustomCard/SingleDebt";
import moment from "moment";
import { Fragment } from "react";
import { CustomButton } from "../../../../components/ui-kit/CustomButtom";

export const Success = ({
  debtInfo,
  planInfo,
  card,
  account,
  paymentType,
  isPlanEstablished,
  isPhysicalPayment,
  standardClient,
}) => {
  const alert = useAlert();
  const { id } = useParams();
  const sum = localStorage.getItem("paymentSum");

  const paymentDates = useSelector((state) => state.setupPlan.paymentDates);
  const tenantSettings = useSelector((state) => state.app.tenantSettings);
  const logoUrl = tenantSettings?.logo;
  const phoneNumber = tenantSettings?.tenant.phone_number;
  const companyShortName = tenantSettings?.company_short_name;

  const [isSaveCardChecked, setIsSavedCardChecked] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalDate, setModalDate] = useState("");

  const handleGoToDebts = async () => {
    if (!isSaveCardChecked) {
      if (id) {
        const data = await removePaymentMethod({
          client_id: id,
          card_id:
            (card && card.card_details.card_id) || (account && account.card_id),
        });

        if (data.success !== "True") {
          alert.error(`${data.message}`);
        }
      } else {
        const data = await removePaymentMethod({
          card_id:
            (card && card.card_details.card_id) || (account && account.card_id),
        });
        if (data.success !== "True") {
          alert.error(`${data.message}`);
        }
      }
    }

    if (sum) localStorage.removeItem("paymentSum");

    if (id) {
      window.location.replace(`/admin/consumers/consumer/${id}`);
    } else {
      const data = await getConsumerDebts("active");
      if (data.data.length) {
        window.location.replace(`/cabinet-active`);
      } else {
        window.location.replace(`/cabinet#established`);
      }
    }
  };

  const typeOfPayment = (sum) => {
    if ((isPlanEstablished && !!sum) || (!isPlanEstablished && !sum)) {
      return "payment_plan";
    } else {
      return "otp";
    }
  };

  const getLatestTransactionDate = (otpHistory, paymentHistory) => {
    const fullPaymentHistory = otpHistory.concat(paymentHistory);

    const filteredSuccessfulPayments = _.filter(
      fullPaymentHistory,
      function (item) {
        return item.success === true;
      }
    );

    return filteredSuccessfulPayments.reduce((a, b) =>
      a.payment_date > b.payment_date ? a : b
    ).payment_date;
  };

  // Form receipt on api
  const handleGetReceipt = async (amount, date, type) => {
    window.scrollTo(0, 0);

    // Fetch consumer payment history to get last transaction date
    const paymentHistory = await getConsumerDebtHistory({
      client_id: id,
      debt_id: debtInfo.debt_id,
    });

    const latestTransactionDate = getLatestTransactionDate(
      paymentHistory.debts_payment_history[0].otp_payment_history,
      paymentHistory.debts_payment_history[0].payment_history
    );

    // Date should match the date in payment history for current transaction
    const response = await getReceiptTemplate({
      client_id: id,
      debt_id: debtInfo.debt_id,
      pay_amount: parseFloat(amount).toFixed(2),
      pay_date: latestTransactionDate,
      transaction_type: type,
    });

    if (response.success === "True") {
      setModalDate(latestTransactionDate);
      setModalData(convertReceiptData(response.receipt_template, logoUrl, phoneNumber));
      document.body.style.overflow = "hidden";
      setShowModal(true);
    }
  };

  const convertReceiptData = (data, logoUrl, phoneNumber) => {
    const splitData = data.split("'template__images'>");
    const joinedData = `${splitData[0]}'template__images'><img src='${logoUrl}' /><p>${phoneNumber}</p>${splitData[1]}`;
    return joinedData;
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsSavedCardChecked(true);
    } else {
      setIsSavedCardChecked(false);
    }
  };

  const text = () => {
    if (sum) {
      return `${isPlanEstablished ? "extra" : "one time"} payment`;
    } else {
      return `${paymentType.toLowerCase()} payment plan`;
    }
  };

  const isShowingPrintButton = () => !!sum;

  const paymentProcessingDate = () =>
    !card
      ? moment(Date()).add(5, "days").format("ll")
      : moment(Date()).add(2, "days").format("ll");

  return (
    <div className="success success-container">
      <Helmet>
        <title>Payment Successful – {companyShortName}</title>
      </Helmet>
      <div className="success__header">
        <h2 className="heading heading-2">
          <SystemCheckCircledIcon24 /> Success
        </h2>
        <div className="success__sub-header">
          <div>
            <p className="heading heading-body-medium">
              Thank you for setting up your {text()}. Here are the details of
              your account.
            </p>
            <hr />
            {/* Only for OTP */}
            {_.isEmpty(planInfo) ? (
              <Fragment>
                <p className="heading heading-body-medium">
                  Payment will be reflected on your account in{" "}
                  {!card ? "5" : "1-2"} days, we ask that you monitor your
                  account in the meantime to prevent any potential issues.
                </p>
                <p className="heading heading-body-medium">
                  Customer support can be reached at {phoneNumber} if you have
                  any questions or concerns.
                </p>
              </Fragment>
            ) : null}
          </div>
          <div>
            <CustomButton
              text="Go to Accounts"
              onClickHandler={handleGoToDebts}
            />
            {isShowingPrintButton() && (
              <CustomButton
                type="secondary"
                text="Print"
                size="medium"
                onClickHandler={() => {
                  handleGetReceipt(sum, new Date(), typeOfPayment(sum));
                }}
                icon={<NavigationPaymentIcon />}
              />
            )}
          </div>
        </div>
      </div>
      <div className="success__body">
        <div className="success__column">
          <h3 className="heading heading-body-bold">Account Information</h3>
          <SingleDebt
            data={debtInfo}
            successSum={!id && planInfo.total_amount}
          />
        </div>
        <div className="success__column">
          <h3 className="heading heading-body-bold">
            {sum ? "Payment" : "Selected Plan"}
          </h3>
          {sum ? (
            <DebtSuccessPayment
              account={account}
              nameOnCard={card && card.card_details.card_description}
              cardType={
                card &&
                card.card_details.card_name.slice(0, -4).trim() === "VISA"
                  ? "visa"
                  : "mc"
              }
              cardNumber={card && card.card_details.card_name.slice(-4)}
              expirationDate={`${card && card.card_details.card_expiry.Month}/${
                card && card.card_details.card_expiry.Year.toString().slice(2)
              }`}
            />
          ) : (
            <DebtPlan
              isPlanSelected
              data={planInfo}
              planPaymentMethod={
                (card && card?.card_details?.card_name) ||
                (account && account?.account_number)
              }
              isPhysicalPayment={isPhysicalPayment}
              startDate={paymentDates.start_date}
              finishDate={paymentDates.finish_date}
              standardClient={standardClient}
            />
          )}
          {/* Only for OTP */}
          {_.isEmpty(planInfo) ? (
            <p className="heading heading-body-medium">
              Payment will be processed by {paymentProcessingDate()}
            </p>
          ) : null}
          {!isPhysicalPayment && (
            <label htmlFor="checkbox" className="other-checkbox">
              <input
                type="checkbox"
                id="checkbox"
                checked={isSaveCardChecked}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <span className="checkmark"></span>
              <span className="heading heading-body-medium">
                Save this payment method to my account
              </span>
            </label>
          )}
        </div>
      </div>
      {showModal && modalData && (
        <ReceiptModal
          data={modalData}
          setShowModal={setShowModal}
          modalDate={modalDate}
        />
      )}
    </div>
  );
};

Success.propTypes = {
  debtInfo: PropTypes.object,
  planInfo: PropTypes.object,
  card: PropTypes.string,
  account: PropTypes.object,
  paymentType: PropTypes.any,
  isPlanEstablished: PropTypes.bool,
  isPhysicalPayment: PropTypes.bool,
  standardClient: PropTypes.number,
};

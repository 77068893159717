import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { SystemInfoIcon16 } from "../icons/SystemIcons";

import "./style.scss";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: { color: "white" },
}))(Tooltip);

export const InfoTooltip = ({ title }) => {
  return (
    <LightTooltip title={title} arrow className="info-tooltip">
      <Button>
        <SystemInfoIcon16 />
      </Button>
    </LightTooltip>
  );
};

InfoTooltip.propTypes = {
  title: PropTypes.string,
};

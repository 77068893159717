import React from "react";

import PropTypes from "prop-types";

export const ConfirmPassword = ({
  newPassword,
  newPasswordConfirmation,
  handlePasswordChange,
}) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="new-password" className="heading heading-caption-bold">
          New Password
        </label>
        <input
          className="input input-large"
          type="text"
          name="new-password"
          value={newPassword}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor="confirm-password"
          className="heading heading-caption-bold"
        >
          Confirm Password
        </label>
        <input
          className="input input-large"
          type="text"
          name="confirm-password"
          value={newPasswordConfirmation}
          onChange={handlePasswordChange}
        />
      </div>
    </>
  );
};

ConfirmPassword.propTypes = {
  newPassword: PropTypes.string,
  newPasswordConfirmation: PropTypes.string,
  handlePasswordChange: PropTypes.func,
};

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import { getQuickSightUrl } from "lib/api/admin";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useSelector } from "react-redux";

const EmbeddedDashboard = ({ url }) => {
  const tenantSettings = useSelector((state) => state.app.tenantSettings);
  const { primary_color } = tenantSettings;

  useEffect(() => {
    const embedDashboard = async () => {
      const embeddingContext = await createEmbeddingContext();
      await embeddingContext.embedDashboard({
        url: url,
        container: "#qs-dashboard-container",
        height: "100%",
        width: "100%",
        theme: {
          dataColorPalette: {
            colors: [primary_color],
            minMaxGradient: ["#FFFFFF", primary_color],
            emptyFillColor: "#FFFFFF",
          },
          uiColorPalette: {
            accent: primary_color,
          },
        },
      });
    };

    embedDashboard();
  }, [url]);

  return (
    <div
      id="qs-dashboard-container"
      style={{ height: "100%", width: "100%" }}
    ></div>
  );
};

const QuickSightPage = () => {
  const alert = useAlert();
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardUrl = async () => {
      try {
        const response = await getQuickSightUrl();
        if (response?.success === "True") {
          setDashboardUrl(response?.data?.EmbedUrl);
        } else {
          alert.error("Failed to fetch QuickSight URL:", response);
        }
      } catch (error) {
        alert.error("Error fetching QuickSight URL:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardUrl();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw", marginTop: "72px" }}>
      {loading ? (
        <LoadingSpinner middleFixed={true} />
      ) : (
        dashboardUrl && <EmbeddedDashboard url={dashboardUrl} />
      )}
    </div>
  );
};

export default QuickSightPage;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getContentBlock } from "lib/api/consumer";

export const SuccessTitle = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState();
  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    const response = await getContentBlock("success-title");
    if (response.success === "True") {
      const contentBody = response.data?.body;
      setContent(contentBody);
    }
  };

  return (
    <span>
      {content
        ? t(content)
        : t("You are one step closer to leaving debt in your past")}
    </span>
  );
};

import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import { ConsumerFooter } from "../ConsumerFooter";
import { CustomButton } from "components/ui-kit/CustomButtom";

import "./style.scss";

export const UnsubscribePage = ({page}) => {
  const { t } = useTranslation();

  const linkTo = () => {
    if (page) {
      window.location.replace("/")
    } else {
      window.location.replace("/cabinet-active")
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("Unsubscription Successful")}</title>
      </Helmet>
      <div className="unsubscribe-body">
        <div className="title">{t("You Have Successfully Been Unsubscribed")}</div>
        <div className="subtitle">{t("Go to your account to manage your contact preferences.")}</div>
        <CustomButton
          text={page ? t("Login") : t("Go to Cabinet")}
          onClickHandler={() => linkTo()}
          optionalClass="action-btn"
        />
      </div>
      <ConsumerFooter page={page}/>
    </>
  )
}
export const navItems = [
  {
    title: "F.A.Q.",
    link: "faq",
  },
  {
    title: "About Us",
    link: "about-us",
  },
  {
    title: "Contact Us",
    link: "contacts",
  },
];

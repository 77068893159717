import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { useSelector } from "react-redux";

import { CancelPlanModal } from "./CancelPlanModal";
import { EditPaymentModal } from "NewAdmin/EditPaymentContainer/EditPaymentModal";

import { PaymentDetailsModal } from "../../PaymentDetailsModal";

import "./style.scss";

export const EditPaymentPlanModal = ({isModalOpen, toggle, loanId, debt}) => {

  const [selectedStep, setSelectedStep] = useState(null);

  const [isCancelPlanModalOpen, setCancelPlanModalOpen] = useState();
  const toggleCancelPlanModal = () => setCancelPlanModalOpen(!isCancelPlanModalOpen);

  const [isEditPaymentModalOpen, setEditPaymentModalOpen] = useState();
  const toggleEditPaymentModal = () => setEditPaymentModalOpen(!isEditPaymentModalOpen);

  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] = useState(false);
  const togglePaymentDetails = () => setIsPaymentDetailsModalOpen(!isPaymentDetailsModalOpen);

  useEffect(() => {
    setSelectedStep(null);
  },[isModalOpen])

  const handleGoNext = () => {
    if (selectedStep === "editPaymentSchedule") {
      toggleEditPaymentModal();
    }
    if (selectedStep === "changePaymentMethod") {
      togglePaymentDetails();
    }
  }

  const handleCancelPlan = () => {
    setSelectedStep('cancelPlan')
    toggleCancelPlanModal();
  }

  const handleChangeSelectedStep = (e) => {
    setSelectedStep(e.target.id);
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={toggle} 
        centered
        className="edit-payment-plan-modal"
      >
        <ModalBody>
          <div className="mod-header">
            <span>Edit Payment Plan</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            <div className={`edit-payment-step-container ${selectedStep === "changePaymentMethod" ? "active" : "inactive"}`}>
              <label className="verification-radio">
                <input
                  type="radio"
                  id="changePaymentMethod"
                  name="verificationRadio"
                  onClick={(e) => handleChangeSelectedStep(e)}
                />
                <div className="label">Change Payment Method</div>
                <span className="checkmark"></span>
              </label>
            </div>
            {profileInfo?.new_payment_system && (
              <div className={`edit-payment-step-container ${selectedStep === "editPaymentSchedule" ? "active" : "inactive"}`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id="editPaymentSchedule"
                    name="verificationRadio"
                    onClick={(e) => handleChangeSelectedStep(e)}
                  />
                  <div className="label">Edit Payment Schedule</div>
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
          </div>
            <div className="mod-footer">
              <CustomButton
                text="Cancel"
                onClickHandler={() => toggle()}
                optionalClass="cancel-btn"
              />
              <div className="confirm-container">
                {profileInfo?.new_payment_system && (
                  <CustomButton
                    text="Cancel Plan"
                    onClickHandler={() => handleCancelPlan()}
                    optionalClass="remove-plan-btn"
                  />
                )}
                <CustomButton
                  text="Next"
                  onClickHandler={() => handleGoNext()}
                  optionalClass="confirm-btn"
                  disabled={!selectedStep}
                />
              </div>
            </div>
        </ModalBody>
      </Modal>

      <CancelPlanModal
        isModalOpen={isCancelPlanModalOpen}
        toggle={toggleCancelPlanModal}
        parentModal={isModalOpen}
        parentToggle={toggle}
        loanId={loanId}
      />

      <EditPaymentModal
        modal={isEditPaymentModalOpen}
        toggle={toggleEditPaymentModal}
        parentModal={isModalOpen}
        parentToggle={toggle}
        loanId={loanId}
      />

      <PaymentDetailsModal
        isModalOpen={isPaymentDetailsModalOpen}
        toggle={togglePaymentDetails}
        // selectedPlan={selectedPlan}
        debt={debt}
        isChangePaymentMethod={true}
        parentModal={isModalOpen}
        parentToggle={toggle}
      />
    </>
  )
}
import React from "react";

import PropTypes from "prop-types";

import { OtherMasterCardIcon, OtherVisaIcon } from "../icons/OtherIcons";

import "./style.scss";

export const DebtSuccessPayment = ({
  nameOnCard,
  cardType,
  cardNumber,
  expirationDate,
  account,
}) => {
  return (
    <div className="debt-custom-card debt-success-payment">
      <div className="debt-success-payment__header">
        <h3 className="heading heading-body-bold">
          {account ? account.bank_name : nameOnCard}
        </h3>
        {account ? (
          <h3 className="heading heading-body-bold">ACH</h3>
        ) : (
          <div>
            {cardType === "mc" ? <OtherMasterCardIcon /> : <OtherVisaIcon />}
          </div>
        )}
      </div>
      <div className="debt-success-payment__footer">
        <div>
          &#8226;&#8226;&#8226;&#8226;{" "}
          {account && Object.keys(account).length !== 0
            ? account.account_number && account.account_number.slice(-4)
            : cardNumber}
        </div>
        {!account && <div>{expirationDate}</div>}
      </div>
    </div>
  );
};

DebtSuccessPayment.propTypes = {
  nameOnCard: PropTypes.string,
  cardType: PropTypes.string,
  cardNumber: PropTypes.string,
  expirationDate: PropTypes.string,
  account: PropTypes.object,
};

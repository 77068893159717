import {
  FETCH_ACTIVE_ACCOUNTS,
  FETCH_ARCHIVED_ACCOUNTS,
  FETCH_All_ACCOUNTS,
  FETCH_ONBOARDING_ACCOUNTS,
  HIDE_LOADER,
  SHOW_LOADER,
} from "../types";

const initialState = {
  loading: false,
  activeAccountDebts: null,
  archivedAccountsDebts: null,
  onboardingAccountsDebts: null,
  allAccountsDebts: null,
};

export const newConsumerDebtsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case FETCH_ACTIVE_ACCOUNTS:
      return { ...state, activeAccountDebts: action.payload };
    case FETCH_ARCHIVED_ACCOUNTS:
      return { ...state, archivedAccountsDebts: action.payload };
    case FETCH_All_ACCOUNTS:
      return { ...state, allAccountsDebts: action.payload };
    case FETCH_ONBOARDING_ACCOUNTS:
      return { ...state, onboardingAccountsDebts: action.payload };
    default:
      return state;
  }
}
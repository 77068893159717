import { FETCH_SAVED_PAYPAL, FETCH_SAVED_DEBIT_CARDS, FETCH_SAVED_BANK_ACCOUNT, SHOW_LOADER, HIDE_LOADER } from "../types";

const initialState = {
  loading: false,
  savedPayPalAccounts: null,
  savedDebitCards: null,
  savedBankAccount: null,
};

export const consumerSavedPaymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case FETCH_SAVED_PAYPAL:
      return { ...state, savedPayPalAccounts: action.payload };
    case FETCH_SAVED_DEBIT_CARDS:
      return { ...state, savedDebitCards: action.payload };
    case FETCH_SAVED_BANK_ACCOUNT:
      return { ...state, savedBankAccount: action.payload };
    default:
      return state;
  }
}

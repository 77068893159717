import {
  SET_REQUEST,
  SET_IS_NEXT_BUTTON_DISABLED,
  SET_DATES,
  SET_PLAN_ID,
} from "../types";

import { validateEmail, validRoutingNumber, validAccountNumber } from "../../lib/helpers/validations";

export function setRequest(request) {
  return (dispatch) => {
    dispatch({ type: SET_REQUEST, payload: request });
    if (
      !validRoutingNumber(request.routing_number) ||
      !request.bank_name ||
      !validAccountNumber(request.account_number) ||
      !request.name_on_check ||
      (request.email !== "" && !validateEmail(request.email))
    ) {
      dispatch(setIsNextButtonDisabled(true));
    } else {
      dispatch(setIsNextButtonDisabled(false));
    }
  };
}

export function setIsNextButtonDisabled(isDisabled) {
  return { type: SET_IS_NEXT_BUTTON_DISABLED, payload: isDisabled };
}

export function setPaymentDates(dates) {
  return { type: SET_DATES, payload: dates };
}

export function setPlanId(planId) {
  return { type: SET_PLAN_ID, payload: planId };
}
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { PaymentMethod } from "../SetupPlan/components/PaymentMethod";
import { CardPayment } from "../SetupPlan/components/CardPayment";
import { BankAccountPayment } from "../SetupPlan/components/BankAccountPayment";
// import { ACHIframe } from "pages/SetupPlan/components/ACHIframe";
import { Success } from "./components/Success";
import { addBankAccount } from "../../lib/api/consumer";
import { setIsNextButtonDisabled, setRequest } from "../../redux/actions/setupPlan";
import { getConsumerDebts, generateNACHA, setDefaultPaymentMethod } from "../../lib/api/common";

import { fetchConsumerProfile } from "redux/actions/profile";

import {
  fetchSavedCards,
  fetchSavedBankAccounts,
} from "../SetupPlan/helpers";

import "./style.scss";
import { CustomButton } from "../../components/ui-kit/CustomButtom";

export const ChangePaymentMethod = () => {
  const alert = useAlert();
  const { debtId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const [planInfo] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("account");
  const dispatch = useDispatch();
  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const isNextButtonDisabled = useSelector(
    (state) => state.setupPlan.isNextButtonDisabled
  );
  const [savedCards, setSavedCards] = useState([]);
  const [savedBankAccounts, setSavedBankAccounts] = useState([]);

  const [selectedPaymentCard, setSelectedPaymentCard] = useState("new");
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState("new");

  const consumersData = useSelector((state) => state.profile.profileData);

  useEffect(() => {
    _.isEmpty(consumersData) && dispatch(fetchConsumerProfile(id));
    fetchSavedCards(id, setSavedCards);
    fetchSavedBankAccounts(id, setSavedBankAccounts);
    // eslint-disable-next-line
  }, []);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PaymentMethod
            handleSelectPaymentMethod={handleSelectPaymentMethod}
            isChangingPaymentMethod
            savedCards={savedCards}
            savedBankAccounts={savedBankAccounts}
            setSelectedPaymentCard={setSelectedPaymentCard}
            setSelectedPaymentAccount={setSelectedPaymentAccount}
          />
        );

      case 1:
        if (selectedPaymentMethod === "account") {
          // if (consumersData?.profile?.new_payment_system) {
          //   return (
          //     <ACHIframe 
          //       handleBack={handleBack}
          //       handleShowSuccess={handleShowSuccess}
          //       changePaymentMethod
          //     />
          //   )
          // } else {
            return (
              <BankAccountPayment
                accountPaymentType={id ? "multi-call" : "multi-web"}
                handleSetAccountPaymentRequest={handleSetAccountPaymentRequest}
                planInfo={planInfo}
                selectedPaymentAccount={selectedPaymentAccount}
                isChangingPaymentMethod
              />
            );
          // }
        } else if (selectedPaymentMethod === "card") {
          if (selectedPaymentCard === 'new') {
            return (
              <CardPayment
                handleBack={handleBack}
                handleShowSuccess={handleShowSuccess}
                changePaymentMethod
              />
            );
          }
        } 
        break;
      default:
        return "Unknown step";
    }
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (selectedPaymentMethod === "physical") {
      setLoading(true);
      const setDefaultPaymentMethodResponse = await setDefaultPaymentMethod({
        client_id: id,
        card_id: "None",
      }); 
      if (setDefaultPaymentMethodResponse.success === "True") {
        setLoading(false);
        handleShowSuccess();
      } else {
        setLoading(false);
        alert.error(selectedPaymentMethod.message);
        return;
      }
    }

    if (activeStep === 1 && selectedPaymentMethod === "account" && selectedPaymentAccount !== "new") {
      setLoading(true);
      const setDefaultPaymentMethodResponse = await setDefaultPaymentMethod({
        client_id: id,
        card_id: selectedPaymentAccount.Id,
      }); 
      if (setDefaultPaymentMethodResponse.success === "True") {
        setLoading(false);
        handleShowSuccess();
      } else {
        setLoading(false);
        alert.error(setDefaultPaymentMethodResponse.message);
        return;
      }
    }

    if (activeStep === 0 && selectedPaymentMethod === "card" && selectedPaymentCard !== "new") {
      setLoading(true);
      const setDefaultPaymentMethodResponse = await setDefaultPaymentMethod({
        client_id: id,
        card_id: selectedPaymentCard,
      }); 
      if (setDefaultPaymentMethodResponse.success === "True") {
        setLoading(false);
        handleShowSuccess();
      } else {
        setLoading(false);
        alert.error(setDefaultPaymentMethodResponse.message);
        return;
      }
    }

    if (activeStep === 1 && selectedPaymentMethod === "account" && selectedPaymentAccount === "new") {
      setLoading(true);
      if (!accountPaymentRequest.account_type) {
        accountPaymentRequest.account_type = "1";
      }
      if (!accountPaymentRequest.authorization) {
        accountPaymentRequest.authorization = "web";
      }
      let accountData;
      accountData = await addBankAccount({
        ...accountPaymentRequest,
        client_id: id,
      });

      const debtsEstablished = await getConsumerDebts("established", {
        client_id: id,
      });

      if (debtsEstablished.success === "True") {
        const nachaData = await generateNACHA({
          client_id: id,
          account_number: accountPaymentRequest.account_number,
          account_type: accountPaymentRequest.account_type,
          agreement_timestamp: new Date(),
          email: accountPaymentRequest.email,
          loan_id: debtsEstablished.data[0].loan_id,
          name_on_check: accountPaymentRequest.name_on_check,
          routing_number: accountPaymentRequest.routing_number,
          bank_name: accountPaymentRequest.bank_name,
        });

        if (nachaData.success !== "True") {
          alert.error("Something went wrong.");
          return;
        }
      }

      if (accountData.success === "True") {
        handleShowSuccess();
      } else {
        alert.error(accountData.message);
      }

      setLoading(false);

      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    dispatch(
      setRequest({
        ...accountPaymentRequest,
        debt_id: +debtId,
        institution_number: 803,
        account_type: "1",
        account_number: "",
        bank_name: "",
        routing_number: "",
      })
    );
    setSelectedPaymentMethod("account");
    dispatch(setIsNextButtonDisabled(true));
    if (activeStep === 0) {
      if (id) {
        window.location.replace(`/admin/consumers/consumer/${id}`);
      } else {
        window.location.replace("/");
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedPaymentMethod("account");
    }
  };

  const handleSelectPaymentMethod = (selected) => {
    setSelectedPaymentMethod(selected);
  };

  const handleSetAccountPaymentRequest = (e) => {
    e.persist();

    dispatch(
      setRequest({
        ...accountPaymentRequest,
        debt_id: +debtId,
        institution_number: 803,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleShowSuccess = () => {
    setIsSuccess(true);
  };
  return (
    <div className="default-container change-payment-method">
      <div
        className={
          activeStep === 1 && selectedPaymentMethod === "card"
            ? "change-payment-method__body--repay"
            : "change-payment-method__body"
        }
      >
        {isSuccess ? <Success /> : <div>{getStepContent(activeStep)}</div>}
      </div>
      {!isSuccess && ( 
        activeStep === 1 && selectedPaymentMethod === "card" ? (null) : (
          <div className="change-payment-method__footer">
            <CustomButton
              text="Back"
              type="secondary"
              onClickHandler={handleBack}
              disabled={loading}
            />
            <CustomButton
              text="Next"
              onClickHandler={handleNext}
              disabled={
                loading ||
                (activeStep === 1 && selectedPaymentMethod === "card") ||
                (activeStep === 1 && isNextButtonDisabled && selectedPaymentAccount === "new")
              }
            />
          </div>
        )
      )}
    </div>
  );
}

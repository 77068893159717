import React, { useState } from "react";

import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { SystemEditIcon16 } from "../../icons/SystemIcons";

import { archiveTag, activateTag } from "../../../lib/api/admin";

export const SingleTag = ({
  name,
  selected,
  clickHandler,
  category,
  fetchActiveTags,
  type,
  fetchExternalTags,
  fetchActiveArchivedTags,
  fetchExternalArchivedTags,
  setOldTagName,
  setModalType,
  setOpen,
  setTagToRename,
  activeTags,
  externalTags,
  archivedTags,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActivateTag = async (name, category) => {
    await activateTag({name: name, category: category});
    if (type === "external") {
      await fetchExternalTags();
      await fetchExternalArchivedTags();
    } else {
      await fetchActiveTags();
      await fetchActiveArchivedTags();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRename = (name) => {
    setModalType("rename");
    setOpen(true);
    setAnchorEl(null);
    setTagToRename(name);
    setOldTagName(name);
  };

  const handleArchive = async (name, category) => {
    setAnchorEl(null);
    await archiveTag({name: name, category: category});
    if (type === "external") {
      await fetchExternalTags();
      await fetchExternalArchivedTags();
    } else {
      await fetchActiveTags();
      await fetchActiveArchivedTags();
    }
  };

  const handleSelect = (name) => {
    if (!activeTags || !archivedTags) {
      clickHandler(name);
    } else {
      return;
    }
  };

  return (
    <div
      className={selected ? "other-tag other-tag--selected" : "other-tag"}
      onClick={() => handleSelect({name: name, category: category})}
    >
      {name}
      {(activeTags || externalTags) && (
        <button
          className="edit"
          aria-controls="edit-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <SystemEditIcon16 />
          Edit
        </button>
      )}
      {archivedTags && (
        <button
          className="edit"
          aria-controls="edit-menu"
          aria-haspopup="true"
          onClick={() => handleActivateTag(name, category)}
        >
          Activate
        </button>
      )}

      <Menu
        id="edit-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleRename(name)}>Rename</MenuItem>
        <MenuItem onClick={() => handleArchive(name, category)}>Archive</MenuItem>
      </Menu>
    </div>
  );
};

SingleTag.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.bool,
  clickHandler: PropTypes.func,
  fetchActiveTags: PropTypes.func,
  fetchArchivedTags: PropTypes.func,
  setOldTagName: PropTypes.func,
  setModalType: PropTypes.func,
  setOpen: PropTypes.func,
  setTagToRename: PropTypes.func,
  activeTags: PropTypes.object,
  archivedTags: PropTypes.object,
};

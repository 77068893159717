import {
  FETCH_SUBSCRIPTION_STATUSES,
  HIDE_SUBSCRIPTION_LOADING,
  SHOW_SUBSCRIPTION_LOADING,
} from "../types";

import {
  getSubscriptionsStatus,
  setSubscriptionsStatus
} from "../../lib/api/admin";

export function fetchSubscriptionStatus(consumerId) {
  return async (dispatch) => {
    dispatch(showSubscriptionLoading());
    const subscriptionStatus = await getSubscriptionsStatus(consumerId);

    if (subscriptionStatus.success === "True") {
      dispatch({
        type: FETCH_SUBSCRIPTION_STATUSES,
        payload: subscriptionStatus.data,
      });
      dispatch(hideSubscriptionLoading());
    } else {
      // eslint-disable-next-line
      console.log("Error: ", subscriptionStatus);
    }
  };
}

export function showSubscriptionLoading() {
  return { type: SHOW_SUBSCRIPTION_LOADING };
}

export function hideSubscriptionLoading() {
  return { type: HIDE_SUBSCRIPTION_LOADING };
}

export function enablePhoneSubscription(id, emailSubscriptionStatus) {
  return async (dispatch) => {
    dispatch(showSubscriptionLoading());
    const data = await setSubscriptionsStatus(
      {
        phone_subscription: true,
        email_subscription: emailSubscriptionStatus,
      },
      id
    );
    if (data.success === "True") {
      dispatch(fetchSubscriptionStatus(id));
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
  };
}

export function disablePhoneSubscription(id, emailSubscriptionStatus) {
  return async (dispatch) => {
    dispatch(showSubscriptionLoading());
    const data = await setSubscriptionsStatus(
      {
        phone_subscription: false,
        email_subscription: emailSubscriptionStatus,
      },
      id
    );
    if (data.success === "True") {
      dispatch(fetchSubscriptionStatus(id));
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
  };
}

export function enableEmailSubscription(id, phoneSubscriptionStatus) {
  return async (dispatch) => {
    dispatch(showSubscriptionLoading());
    const data = await setSubscriptionsStatus(
      {
        phone_subscription: phoneSubscriptionStatus,
        email_subscription: true,
      },
      id
    );
    if (data.success === "True") {
      dispatch(fetchSubscriptionStatus(id));
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
  };
}

export function disableEmailSubscription(id, phoneSubscriptionStatus) {
  return async (dispatch) => {
    dispatch(showSubscriptionLoading());
    const data = await setSubscriptionsStatus(
      {
        phone_subscription: phoneSubscriptionStatus,
        email_subscription: false,
      },
      id
    );
    if (data.success === "True") {
      dispatch(fetchSubscriptionStatus(id));
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
  };
}

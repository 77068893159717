import React from "react";

import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import { statesList } from "../statesList";

export const ProfileFormStates = ({ handleStateChange, value, disabled }) => {
  return (
    <Select
      labelId="state"
      id="state"
      onChange={handleStateChange}
      input={
        <InputBase
          value={value || ""}
          disabled={disabled}
          className="input input-medium"
        />
      }
    >
      {statesList.length !== 0 &&
        statesList.map((state, i) => (
          <MenuItem key={i} value={state.state} name={state.state}>
            {state.state}
          </MenuItem>
        ))}
    </Select>
  );
};

ProfileFormStates.propTypes = {
  data: PropTypes.object,
  handleRemove: PropTypes.func,
  handleSetDefault: PropTypes.func,
  isHidden: PropTypes.bool,
  isRemovable: PropTypes.bool,
  handleStateChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

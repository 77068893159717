import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { addCustomPlan } from "lib/api/admin";
import { useAlert } from "react-alert";

import { CustomButton } from "components/ui-kit/CustomButtom";

import "./style.scss";

export const PiggyCodeModal = ({toggle, isModalOpen, parentModal, parentToggle, request}) => {
  
  const alert = useAlert();
  const { id } = useParams();
  const [piggyCode, setPiggyCode] = useState();
  const [isAddPlanDisabled, setIsAddPlanDisabled] = useState(true);

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const toggleModal = () => {
    toggle();
    parentToggle();
  }

  const handleAddOpenPlan = async() => {

    setIsAddPlanDisabled(true);

    const formRequest = {
      plan_type: request.plan_type,
      data_id: request.data_id,
      number_of_payments: request.number_of_payments,
      tot_amt_to_pay: request.tot_amt_to_pay,
      tot_mo_payment: request.tot_mo_payment,
      discount: 0,
      is_open_ended: request.is_open_ended,
      piggy_code: piggyCode
    }

    const data = await addCustomPlan(formRequest, id);

    if (data.success === "True") {
      window.location.reload(false);
    } else {
      alert.error(data.message);
    }
    setIsAddPlanDisabled(false);
  }

  const handleChangePiggyCode = (e) => {
    if (e.target.value.length < 5) {
      setPiggyCode(e.target.value);
    } 
    if (e.target.value.length >= 4) {
      setIsAddPlanDisabled(false); 
    } else {
      setIsAddPlanDisabled(true);
    }
  }

  return (
    <Modal 
      isOpen={isModalOpen} 
      // toggle={toggle}
      onOpened={closeParentModal}
      centered 
      className="piggy-code-modal"
    >
      <ModalBody>
        <button className="close-modal-btn" onClick={() => toggleModal()}>
          <Close />
        </button>
        <div>
          <div className="mod-body">
            <div className="title">Security Step</div>
            <div className="subtitle">Please Enter Piggy Code</div>
            <input
              onChange={handleChangePiggyCode}
              type="password"
              className="form-control"
              name="total-amount"
              value={piggyCode || ""}
            />
          </div>
          <div className="mod-footer">
            <CustomButton
              text="Back"
              onClickHandler={() => toggleModal()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text="Add Open Plan"
              onClickHandler={handleAddOpenPlan}
              disabled={isAddPlanDisabled}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";

import { LoadingSpinner } from "components/LoadingSpinner";
import { PayPalUrlGeneration } from "multitenancy/multitenancyPayPal";

import "./style.scss";

let count = 0;

export const AddPayPalModal = ({modal, toggle}) => {

  const [loading, setLoading] = useState(false);
  const [authMethod, setAuthMethod] = useState("web");

  const { id } = useParams();


  useEffect(() => {
    if (modal) {
      window.addEventListener("message", AddNewDebitReceiveMessage, false);
    }
  },[modal]);

  async function AddNewDebitReceiveMessage(event) {
    if (count > 0) return;

    if (event.data === "PAYMENT_SUCCESS") { 
      count++;
      addPaymentCardToConsumer();
    }
  }

  const closeModal = () => {
    window.location.reload(false);
  }

  const addPaymentCardToConsumer = async () => {

    setLoading(false)
    closeModal();

  };

  const setAuthMethodFunc = (e) => {
    setAuthMethod(e.target.value);
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-payment-modal"
      >
        <ModalBody>
          <div className="mod-header">
            <span>Add PayPal</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          {!loading && id ? (
          <div className="authorization-selector">
            <label
              className="heading-caption-bold"
              htmlFor="account_type"
            >
              Authorization Method
            </label>
            <Select
              labelId="authorization"
              id="authorization"
              name="authorization"
              onChange={setAuthMethodFunc}
              input={<InputBase value={authMethod} className="input input-medium" />}
            >
              <MenuItem value={"web"}>Web</MenuItem>
              <MenuItem value={"verbal"}>Verbal</MenuItem>
              <MenuItem value={"written"}>Written</MenuItem>
            </Select>
          </div>
          ) : null}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <iframe id="iframe-modal" src={PayPalUrlGeneration()} title="Add Card" frameBorder="0"/>
          )}
        </ModalBody>

      </Modal>
    </>
  )

}
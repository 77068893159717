import React from "react";

import PropTypes from "prop-types";

export const ProfileFormTitle = ({ title, children }) => {
  return (
    <div className="profile-form-title">
      <h2 className="heading heading-2">{title}</h2>
      {children}
    </div>
  );
};

ProfileFormTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
};

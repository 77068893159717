import React from "react";

import PropTypes from "prop-types";

import "./style.scss";

export const LoadingSpinner = ({ overlayType, middleFixed }) => {

  if (middleFixed) {
    return(
      <div
        className="middle-fixed"
      >
        <div className="donut"></div>
      </div>
    )
  } else {
    return (
      <div
        className={
          overlayType ? `loading-spinner ${overlayType}` : "loading-spinner"
        }
      >
        <div className="donut"></div>
      </div>
    );
  }
}

LoadingSpinner.propTypes = {
  overlayType: PropTypes.string,
  middleFixed: PropTypes.bool,
};

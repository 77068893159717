import { useSelector } from "react-redux";

let hostname = process.env.REACT_APP_BACKEND_HOST || window.location.hostname.toLowerCase();

export const PayPalUrlGeneration = () => {
  const profileData = useSelector((state) => state.profile.profileData);
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return `https://pathway.investinet.waythru-test1.com/paypal?id=${profileData?.profile?.client_id}`;
    case process.env.REACT_APP_DEMO_STAGE:
      return `https://pathway.investinet.waythru-test1.com/paypal?id=${profileData?.profile?.client_id}`;
    case process.env.REACT_APP_INVESTINET_PROD:
      return `https://pathway.investinet.com/paypal?id=${profileData?.profile?.client_id}`;
  }
}

export const showDependOnTenant = (profileInfo) => {

  if (profileInfo?.new_payment_system) {
    switch (hostname) {
      case process.env.REACT_APP_INVESTINET_STAGE:
        return false;
      case process.env.REACT_APP_DEMO_STAGE:
        return false;
      case process.env.REACT_APP_INVESTINET_PROD:
        return false;
      default:
        return false;
    }
  } else {
    return false;
  }
}

export const showReffBlockDependOnTenant = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return false;
    case process.env.REACT_APP_DEMO_STAGE:
      return false;
    case process.env.REACT_APP_INVESTINET_PROD:
      return false;
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return true;
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return true;
    case process.env.REACT_APP_TROMBERG_STAGE:
      return true;
    case process.env.REACT_APP_TROMBERG_PROD:
      return true;
    default:
      return false;
  }
}
import React, { useState, useRef } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { DateRange, ua } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import useOutsideAlerter from "./hooks/useOutsideAlerter";

import "./style.scss";
import {
  hideDatepicker,
  setNotesDatesRange,
  showDatepicker,
} from "../../../../redux/actions/consumerNotes";

export default function OutsideAlerter({
  handleClick,
  isDatePickerOpen,
  isChanged,
  children,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleClick, isDatePickerOpen, isChanged);

  return <div ref={wrapperRef}>{children}</div>;
}

export const DatePicker = ({ handleDateChange }) => {
  const dispatch = useDispatch();

  const notesData = useSelector((state) => state.consumerNotes);
  const [state, setState] = useState([
    {
      startDate: notesData.datesRange.date_from,
      endDate: notesData.datesRange.date_to,
      key: "selection",
    },
  ]);
  const [isChanged, setIsChanged] = useState(false);
  const isDatePickerOpen = useSelector(
    (state) => state.consumerNotes.isDatePickerOpen
  );

  const handleDatepickerDateChange = (item) => {
    const clickedStartDate = item.selection.startDate.toString();
    const clickedEndDate = item.selection.endDate.toString();
    const stateStartDate = state[0].startDate.toString();
    const stateEndDate = state[0].endDate.toString();
    const changed =
      (((clickedStartDate === stateStartDate &&
        clickedEndDate !== stateEndDate) ||
        (clickedEndDate === stateEndDate &&
          clickedStartDate !== stateStartDate)) &&
        clickedStartDate !== clickedEndDate) ||
      (clickedStartDate === stateStartDate &&
        clickedEndDate !== stateEndDate &&
        clickedStartDate === clickedEndDate &&
        stateStartDate === stateEndDate);

    if (changed) {
      setIsChanged(true);
      dispatch(hideDatepicker());
      dispatch(
        setNotesDatesRange({
          date_from: item.selection.startDate,
          date_to: item.selection.endDate,
        })
      );
      handleDateChange({
        date_from: item.selection.startDate,
        date_to: item.selection.endDate,
      });
    } else {
      setIsChanged(false);
    }
    setState([item.selection]);
  };

  const hide = () => {
    dispatch(hideDatepicker());
  };

  const show = () => {
    dispatch(showDatepicker());
  };

  return (
    <OutsideAlerter
      handleClick={hide}
      isDatePickerOpen={isDatePickerOpen}
      isChanged={isChanged}
    >
      <div style={{ position: "relative" }}>
        <div
          className={
            isDatePickerOpen ? "datepicker" : "datepicker datepicker--hidden"
          }
          onClick={show}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => handleDatepickerDateChange(item)}
            moveRangeOnFirstSelection={false}
            ranges={state}
            locale={ua}
          />
        </div>
      </div>
    </OutsideAlerter>
  );
};

DatePicker.propTypes = {
  handleDateChange: PropTypes.func,
};

OutsideAlerter.propTypes = {
  handleClick: PropTypes.func,
  isDatePickerOpen: PropTypes.bool,
  isChanged: PropTypes.bool,
  children: PropTypes.object,
};

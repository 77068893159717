import React from "react";

import "../style.scss";

export const JudgmentBlock = ({externalDebtInfo}) => {

  return (
    <>
      <div className="customer-service-container">
        <div className="header-container">
          <div className="header">Judgment</div>
        </div>
        <div className="body">
          <div className="header">Case Details</div>
          <div className="body-container">
            <div className="block-container">
              <span className="label">Case</span>
              <span className="value">{externalDebtInfo?.suit_case_number ? externalDebtInfo.suit_case_number : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Court</span>
              <span className="value">{externalDebtInfo?.judgment_court ? externalDebtInfo.judgment_court : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">State</span>
              <span className="value">{externalDebtInfo?.pri_state ? externalDebtInfo.pri_state : "-"}</span>
            </div>
          </div>

          <div className="header">Status Details</div>
          <div className="body-container">
            <div className="block-container">
              <span className="label">Disposition</span>
              <span className="value">{externalDebtInfo?.judgment_status ? externalDebtInfo.judgment_status : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Active</span>
              <span className="value">{externalDebtInfo?.judgment_active ? externalDebtInfo.judgment_active : "-"}</span>
            </div>
            <div className="block-container"></div>
          </div>

          <div className="header">Verdict Details</div>
          <div className="body-container">
            <div className="block-container">
              <span className="label">Date</span>
              <span className="value">{externalDebtInfo?.judgment_date ? externalDebtInfo.judgment_date : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Amount</span>
              <span className="value">{externalDebtInfo?.judgment_amount_principal ? externalDebtInfo.judgment_amount_principal : "-"}</span>
            </div>
            <div className="block-container">
              <span className="label">Interest Rate</span>
              <span className="value">{externalDebtInfo?.judgment_amount_interest ? externalDebtInfo.judgment_amount_interest : "-"}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
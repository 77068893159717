import { logOutClient, logOutUser } from "../../../lib/api/common";

export const goToURL = (url) => {
  window.location.replace(url);
};

export const handleLogOut = async (userRole) => {
  const clearStorage = () => {
    localStorage.clear("token");
    localStorage.clear("userRole");
    localStorage.clear("userId");
    localStorage.setItem("isAdminAuthenticated", "false");
  };
  if (userRole === "admin" || userRole === "agent") {
    const logoutData = await logOutUser();
    if (logoutData.success !== "True") {
      // eslint-disable-next-line
      console.log("Something went wrong.");
    }
    clearStorage();
    window.location.replace("/admin");
  } else {
    const logoutData = await logOutClient();
    if (logoutData.success !== "True") {
      // eslint-disable-next-line
      console.log("Something went wrong.");
    }
    clearStorage();
    window.location.replace("/");
  }
};

import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { hasLeadingZero } from "../../lib/helpers/validations";
import { countPayments, countOpenPlan } from "../../pages/Consumers/components/AddOptionModal/helpers";

export const DebtCustomPlan = ({
  debtData,
  discountPercent,
  setDiscountPercent,
  payments,
  setPayments,
  setIsAddPlanDisabled,
  isPaymentsDisabled,
  setIsPaymentsDisabled,
  request,
  updateRequestData,
  isOpenPlan,
}) => {
  useEffect(() => {
    const countedPayments = countPayments(request);
    const countedOpenPlanPayments = countOpenPlan(request);

    if (isOpenPlan === false) {
      setPayments(countedPayments);

      updateRequestData("number_of_payments", countedPayments[0].count);
      updateRequestData("tot_mo_payment", countedPayments[0].amount);

    } else {
      setPayments(countedOpenPlanPayments);

      updateRequestData("number_of_payments", countedOpenPlanPayments[0].count);
      updateRequestData("tot_mo_payment", countedOpenPlanPayments[0].amount);
    }



    // eslint-disable-next-line
  }, [
    request.tot_amt_to_pay,
    request.discount,
    discountPercent,
    request.plan_type,
    isOpenPlan
  ]);

  const handleChange = (e) => {
    if (e.target.name === "discount") {
      if (e.target.value === "") {
        updateRequestData("discount", "");
        setIsPaymentsDisabled(true);
        setIsAddPlanDisabled(true);
      } else {
        if (
          isNaN(+e.target.value) ||
          hasLeadingZero(e.target.value) ||
          (e.target.value.split(".")[1] &&
            e.target.value.split(".")[1].length > 2)
        ) {
          return;
        } else {
          setIsPaymentsDisabled(false);
          setIsAddPlanDisabled(false);
          if (+e.target.value > debtData) return;
          updateRequestData("discount", e.target.value);
          setDiscountPercent(((+e.target.value / debtData) * 100).toFixed(2));
          updateRequestData(
            "tot_amt_to_pay",
            (debtData - +e.target.value).toFixed(2)
          );
        }
      }
    } else if (e.target.name === "discount-percent") {
      if (e.target.value === "") {
        setDiscountPercent("");
        setIsPaymentsDisabled(true);
        setIsAddPlanDisabled(true);
      } else {
        if (
          isNaN(+e.target.value) ||
          +e.target.value > 100 ||
          hasLeadingZero(e.target.value) ||
          (e.target.value.split(".")[1] &&
            e.target.value.split(".")[1].length > 2)
        ) {
          return;
        } else {
          const value = e.target.value;
          if (value.split(".")[1] && value.split(".")[1].length > 2) {
            const begin = value.split(".")[0];
            const end = value.split(".")[1].slice(0, 2);
            setDiscountPercent(`${begin}.${end}`);
            updateRequestData(
              "discount",
              ((debtData / 100) * +`${begin}.${end}`).toFixed(2)
            );

            updateRequestData(
              "tot_amt_to_pay",
              (debtData - (debtData / 100) * +`${begin}.${end}`).toFixed(2)
            );
          } else {
            setDiscountPercent(value);
            updateRequestData(
              "discount",
              ((debtData / 100) * +value).toFixed(2)
            );

            updateRequestData(
              "tot_amt_to_pay",
              (debtData - (debtData / 100) * +value).toFixed(2)
            );
          }
          setIsPaymentsDisabled(false);
          setIsAddPlanDisabled(false);
        }
      }
    } else if (e.target.name === "total-amount") {
      if (e.target.value === "") {
        updateRequestData("tot_amt_to_pay", "");
        setIsPaymentsDisabled(true);
        setIsAddPlanDisabled(true);
      } else {
        if (
          isNaN(+e.target.value) ||
          +e.target.value > debtData ||
          isOpenPlan && +e.target.value >= debtData ||
          hasLeadingZero(e.target.value) ||
          (e.target.value.split(".")[1] &&
            e.target.value.split(".")[1].length > 2)
        ) {
          return;
        } else {
          const value = e.target.value;
          if (value.split(".")[1] && value.split(".")[1].length > 2) {
            const begin = value.split(".")[0];
            const end = value.split(".")[1].slice(0, 2);
            updateRequestData("tot_amt_to_pay", `${begin}.${end}`);
            updateRequestData(
              "discount",
              (debtData - +`${begin}.${end}`).toFixed(2)
            );
            setDiscountPercent(
              (((debtData - +`${begin}.${end}`) / debtData) * 100).toFixed(2)
            );
          } else {
            updateRequestData("tot_amt_to_pay", value);
            updateRequestData("discount", (debtData - +value).toFixed(2));
            setDiscountPercent(
              (((debtData - +value) / debtData) * 100).toFixed(2)
            );
          }

          setIsPaymentsDisabled(false);
          setIsAddPlanDisabled(false);
        }
      }
    }
  };

  const handlePaymentsChange = (e) => {
    const selectedPayment = e.target.value.split("|");
    updateRequestData("number_of_payments", selectedPayment[0]);
    updateRequestData("tot_mo_payment", selectedPayment[1]);
  };

  return (
    <div className="debt-custom-card custom-payment">
      {isOpenPlan === false && (
        <div className="debt-custom-card__block">
          <div className="debt-custom-card__column">
            <h5>Discount ($)</h5>
            <div className="custom-payment__input-wrapper">
              <input
                onChange={handleChange}
                type="text"
                name="discount"
                className="form-control"
                value={request.discount}
              />
              <span>$</span>
            </div>
          </div>
          <div className="debt-custom-card__column">
            <h5>Discount (%)</h5>
            <div className="custom-payment__input-wrapper">
              <input
                onChange={handleChange}
                type="text"
                name="discount-percent"
                className="form-control"
                value={discountPercent}
              />
              <span>%</span>
            </div>
          </div>
        </div>
      )}
      <div className="debt-custom-card__block">
      <div className="debt-custom-card__column">
        <h5>Payments</h5>
        <select
          name="payments"
          className="form-control"
          id="payments"
          onChange={handlePaymentsChange}
          disabled={payments.length === 0 || isPaymentsDisabled}
          value={`${request.number_of_payments}|${request.tot_mo_payment}`}
        >
          {payments.length &&
            payments.map((payment) => (
              <option
                key={payment.count}
                value={`${payment.count}|${payment.amount}`}
              >
                {payment.count} | ${payment.amount}
              </option>
            ))}
        </select>
        {false && (
          <input
            onChange={handleChange}
            type="text"
            name="payments"
            className="form-control"
          />
        )}
      </div>
      <div className="debt-custom-card__column">
        <h5>New Total Amount</h5>
        <div className="custom-payment__input-wrapper">
          <input
            onChange={handleChange}
            type="text"
            className="form-control"
            name="total-amount"
            value={request.tot_amt_to_pay}
          />
          <span>$</span>
        </div>
      </div>
      </div>
    </div>
  );
};

DebtCustomPlan.propTypes = {
  debtData: PropTypes.any,
  discountPercent: PropTypes.any,
  setDiscountPercent: PropTypes.any,
  payments: PropTypes.any,
  setPayments: PropTypes.any,
  setIsAddPlanDisabled: PropTypes.any,
  isPaymentsDisabled: PropTypes.any,
  setIsPaymentsDisabled: PropTypes.any,
  request: PropTypes.any,
  updateRequestData: PropTypes.any,
};

import React, { useState } from "react";
import { useParams } from "react-router-dom"
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";
import { deletePaymentPromise } from "lib/api/common";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

import "./style.scss";

export const CancelPlanModal = ({isModalOpen, toggle, parentModal, parentToggle, loanId}) => {

  const alert = useAlert();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const handleCancelPlan = async () => {
    setLoading(true);
    const deletePromise = await deletePaymentPromise(id, loanId);

    if (deletePromise.success === "True") {
      setLoading(false);
      window.location.reload(false);
    } else {
      setLoading(false);
      alert.error(deletePromise.message);
    }
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const closeAndToggleParent = () => {
    toggle();
    parentToggle();
  }

  return (
    <Modal
      isOpen={isModalOpen}
      // toggle={toggle}
      onOpened={closeParentModal}
      centered
      className="cancel-plan-modal"
    >
      <ModalBody>
        <div className="mod-header">
          <span>Cancel Payment Plan</span>
          <button className="close-modal-btn" onClick={() => toggle()}>
            <Close />
          </button>
        </div>
        <div className="mod-body">
          Are you sure you want to cancel this payment plan for {profileInfo?.full_name}?
        </div>
        <div className="mod-footer">
          <CustomButton
            text="Cancel"
            onClickHandler={() => closeAndToggleParent()}
            optionalClass="cancel-btn"
          />
          <CustomButton
            text="Cancel Plan"
            onClickHandler={() => handleCancelPlan()}
            optionalClass="confirm-btn"
          />
        </div>
      </ModalBody>
      {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
    </Modal>
  )

}
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  fetchConsumerPaymentsData,
} from "../../../../redux/actions/consumerPayments";

// import { fetchBalance } from "../../../../redux/actions/balance";

import { ConsumerWrapper } from "../ConsumerWrapper";
import "./style.scss";

class SingleConsumer extends Component {
  componentDidMount() {
    // const id = this.props.match.params.id;

    // this.props.fetchBalance(id);
    // need to test if we need this
    // this.props.fetchConsumerPaymentsData(id);
  }
  render() {
    return (
      <div className="single-consumer">
        <ConsumerWrapper />
      </div>
    );
  }
}

export default connect(null, { fetchConsumerPaymentsData })(
  withRouter(SingleConsumer)
);

SingleConsumer.propTypes = {
  fetchBalance: PropTypes.func,
  fetchConsumerPaymentsData: PropTypes.func,
  match: PropTypes.object,
};

import React, { useEffect } from "react";

import "./style.scss";

export const SuccessSignedPage = () => {

  useEffect(() => {
    window.parent.postMessage("SIGNED_SUCCESS");
  }, []);

  return (
    <div className="post-message-success-container">
      <div>
        Electronic Signature action finished
      </div>
    </div>
  )
}
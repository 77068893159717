import {
  FETCH_PLAN_INFO
} from "../types";

import {
  getPlanInfo,
} from "../../lib/api/common";

export function fetchPlanInfo(planId, id) {
  return async(dispatch) => {
    let response
    if (id) {
      response = await getPlanInfo({
        plan_id: planId,
        client_id: id,
      });
    } else {
      response = await getPlanInfo({
        plan_id: planId,
      });
    }
    if (response.success === "True") {
      dispatch({type: FETCH_PLAN_INFO, payload: response})
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
  }
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { TableContainer } from "../TableContainer";
import { LoadingSpinner } from "../LoadingSpinner";
import { fetchConsumerHistory } from "../../redux/actions/consumerHistory";
import { headers } from "./headersData";
import "./style.scss";

export const HistoryBody = ({ selectedTab }) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);

  const loading = useSelector((state) => state.consumerHistory.loading);
  const historyData = useSelector((state) => state.consumerHistory.historyData)

  const fetchHistory = async (page) => {
    dispatch(
      fetchConsumerHistory({
        client_id: id,
        current_page: page || 1,
        per_page: 50,
      })
    )
  }

  useEffect(() => {
    if (window.location.hash === "#history") {
      fetchHistory(1);
    }
    // eslint-disable-next-line
  }, [selectedTab]);

  const setCurrentTablePage = async (page) => {
    await setCurrentPage(page);
    await fetchHistory(page);
  };

  return (
    <div className="main-container history-body">
        {loading ? (
          <LoadingSpinner middleFixed={true}/>
        ) : (
          <TableContainer
            tableHeaders={headers}
            data={historyData.page}
            tableType={"wide"}
            currentPage={currentPage}
            setCurrentPage={setCurrentTablePage}
            pagesCount={
              (historyData.pagination &&
                historyData.pagination.page_range) ||
              1
            }
          />
        )}
    </div>
  )
}

HistoryBody.propTypes = {
  selectedTab: PropTypes.any,
};

import {
  DISABLE_BUTTON,
  ENABLE_BUTTON,
  SET_STEP,
  SET_ERROR,
} from "../types";

export function disableButton() {
  return { type: DISABLE_BUTTON };
}

export function enableButton() {
  return { type: ENABLE_BUTTON };
}

export function setStep(step) {
  return { type: SET_STEP, payload: step };
}

export function setError(text) {
  return { type: SET_ERROR, payload: text };
}
import React from "react";

import html2pdf from "html2pdf.js";
import moment from "moment";
import PropTypes from "prop-types";

import { CustomButton } from "../ui-kit/CustomButtom";

import { useTranslation } from 'react-i18next';

import "./style.scss";

const ref = React.createRef();

export const ReceiptModal = ({ data, setShowModal, modalDate, header }) => {

  const { t } = useTranslation();

  const markup = () => {
    return { __html: data };
  };

  const opt = {
    image: { type: 'jpeg', quality: 1 },
    filename: `receipt_${moment(modalDate).format("YYYY-MM-DD")}.pdf`,
    html2canvas: {
      dpi: 192,
      scale:4,
      letterRendering: true,
      useCORS: true
    }
  };

  const downloadPdf = () => {
    html2pdf().from(ref.current).set(opt).save();
  };

  return (
    <div className="receipt-modal">
      <div
        style={{
          height: "1500px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <button
              className="button button-transparent hide-button"
              type="button"
              onClick={() => {
                setShowModal(false);
                document.body.style.overflow = "visible";
              }}
            >
              X
            </button>
          </div>
          <div className="receipt-modal__buttons">
            <CustomButton text={t("Download")} onClickHandler={downloadPdf} />
            {header}
          </div>
        </div>
        <div className="template__wrapper">
          <div ref={ref}>
            <div dangerouslySetInnerHTML={markup()}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReceiptModal.propTypes = {
  data: PropTypes.any,
  setShowModal: PropTypes.any,
  modalDate: PropTypes.any,
  header: PropTypes.any,
};
